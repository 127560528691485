.rdrDefinedRangesWrapper {
  display: none !important;
}
.daterange-picker .rdrCalendarWrapper {
  box-shadow:
    0px 0px 0.5px 0px rgba(66, 71, 76, 0.32),
    0px 4px 8px 0px rgba(66, 71, 76, 0.05),
    0px 4px 40px 0px #eee;
  background-color: #fff;
  border-radius: 12px;
  padding: 10px;
}

.daterange-picker .rdrDateDisplayWrapper {
  display: none;
}
.daterange-picker .rdrMonthAndYearWrapper {
  padding: 0px;
}

.rdrDayPassive {
  span {
    display: none;
  }
}

.rdrStartEdge {
  background-color: #883dcf;
  color: #fff;
}

.rdrDayNumber:hover {
  outline: none;
}

.stepper {
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.stepper .active-step {
  background-color: #883dcf;
}

.stepper .line {
  height: 2px;
  width: 108px;
}

.active-line {
  background-color: #883dcf;
}

.idle-line {
  background-color: grey;
}

.stepper .idle-step {
  background-color: grey;
}
.stepper .indie-step {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.stepper .final-step {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

/* help and support */
.help-and-support {
  padding: 0 24px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: scroll;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 1%;
  margin: 0;
}

.watch-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4px;
  color: #883dcf;
}

.watch-btn span {
  /* background: linear-gradient(96.43deg, #883dcf 0%, #b74ab5 100%); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  /* background-clip: text; */
  /* text-fill-color: transparent; */
  color: #883dcf;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.watch-btn:hover {
  text-decoration: underline;
}
.cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.step-card {
  height: 310px;
  width: 370px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 30px 0px #2e2d740d;
  border-radius: 12px;
}
.image-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #f3ebfc;
  padding-bottom: 24px;
}

.image-div p {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #1d1f2c;
  margin: 0;
}
.step-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5f6b98;
  margin: 0;
  padding-top: 24px;
}

/* tutorial video modal */

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-header h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #1d1f2c;
  margin: 0;
}
.modal-header button {
  border: none;
  background: none;
}
.tutorial-video {
  width: 100%;
  height: 320px;
  background: #f3ebfc;
  padding: 8px;
  border-radius: 8px;
}

/* Toolkit modal */
.modal-header-toolkit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(243, 235, 252, 1);
}
.modal-header-toolkit h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #1d1f2c;
  margin: 0;
}
.modal-header-toolkit button {
  border: none;
  background: none;
}
.modal-sub-header-toolkit {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.modal-sub-header-toolkit h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #1d1f2c;
  margin: 0;
}
.modal-sub-header-toolkit p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(95, 107, 152, 1);
  margin: 0;
}

.modal-btn-toolkit {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.modal-toolkit-next {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #883dcf;
  /* text-decoration: underline; */
  background: none;
  border: none;
  cursor: pointer;
}
.modal-toolkit-next:hover {
  text-decoration: underline;
}
.modal-toolkit-skip {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #5f6b98;
  background: none;
  border: none;
  cursor: pointer;
}
