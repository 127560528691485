@charset "UTF-8";
:root {
  --polaris-version-number: "10.38.0";
}

html,
body {
  font-size: var(--p-font-size-90);
  line-height: var(--p-font-line-height-2);
  font-weight: var(--p-font-weight-regular);
  text-transform: initial;
  letter-spacing: initial;
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  background-color: #f9f9fc;
}

html,
body,
button {
  font-family: var(--p-font-family-sans);
}

html {
  position: relative;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f8f8fc;
}

@media print {
  body {
    background-color: transparent !important;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-size: 1em;
  font-weight: var(--p-font-weight-regular);
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
}

*:focus {
  outline: none;
}

:root {
  color-scheme: light;
  --body-primary-primary-50: #f9f9fc;
  --p-white-color: #fff;
  --Primary: #883dcf;
  --Neutral-Gray-Gray-50: #f0f1f3;
  --Primary-Primary-500: #883dcf;
  --Primary-Primary-50: #f4ecfb;
  --font-grey: #777980;
  --p-breakpoints-xs: 0rem;
  --p-breakpoints-sm: 30.625rem;
  --p-breakpoints-md: 48rem;
  --p-breakpoints-lg: 65rem;
  --p-breakpoints-xl: 90rem;
  --p-color-bg-inverse: rgba(31, 33, 36, 1);
  --p-color-bg-inset-strong: rgba(97, 106, 117, 1);
  --p-color-bg-inverse-hover: rgba(97, 106, 117, 1);
  --p-color-bg-inverse-active: rgba(135, 144, 155, 1);
  --p-color-bg-strong-hover: rgba(202, 206, 211, 1);
  --p-color-bg-strong-active: rgba(202, 206, 211, 1);
  --p-color-bg-strong: rgba(221, 224, 228, 1);
  --p-color-bg-subdued-active: rgba(221, 224, 228, 1);
  --p-color-bg-disabled: rgba(235, 236, 239, 1);
  --p-color-bg-interactive-disabled: rgba(235, 236, 239, 1);
  --p-color-bg-app: rgba(241, 242, 244, 1);
  --p-color-bg-app-active: rgba(221, 224, 228, 1);
  --p-color-bg-app-hover: rgba(235, 236, 239, 1);
  --p-color-bg-app-selected: rgba(235, 236, 239, 1);
  --p-color-bg-active: rgba(235, 236, 239, 1);
  --p-color-bg-subdued-hover: rgba(235, 236, 239, 1);
  --p-color-bg-inset: rgba(241, 242, 244, 1);
  --p-color-bg-hover: rgba(241, 242, 244, 1);
  --p-color-bg-subdued: rgba(249, 250, 251, 1);
  --p-color-bg-input: rgba(255, 255, 255, 1);
  --p-color-bg: rgba(255, 255, 255, 1);
  --p-color-bg-primary-active: rgba(12, 59, 47, 1);
  --p-color-bg-primary-hover: rgba(18, 84, 67, 1);
  --p-color-bg-primary: rgba(0, 122, 92, 1);
  --p-color-bg-success-strong: rgba(22, 166, 121, 1);
  --p-color-bg-success: rgba(161, 237, 208, 1);
  --p-color-bg-primary-subdued-active: rgba(192, 242, 221, 1);
  --p-color-bg-success-subdued-active: rgba(192, 242, 221, 1);
  --p-color-bg-success-subdued: rgba(224, 248, 238, 1);
  --p-color-bg-primary-subdued-hover: rgba(224, 248, 238, 1);
  --p-color-bg-success-subdued-hover: rgba(240, 253, 248, 1);
  --p-color-bg-primary-subdued: rgba(240, 253, 248, 1);
  --p-color-bg-primary-subdued-selected: rgba(240, 253, 248, 1);
  --p-color-bg-critical-strong-active: rgba(115, 24, 7, 1);
  --p-color-bg-critical-strong-hover: rgba(159, 32, 10, 1);
  --p-color-bg-critical-strong: rgba(197, 40, 12, 1);
  --p-color-bg-critical-subdued-active: rgba(251, 197, 188, 1);
  --p-color-bg-critical: rgba(251, 197, 188, 1);
  --p-color-bg-critical-subdued: rgba(253, 226, 221, 1);
  --p-color-bg-critical-subdued-hover: rgba(254, 243, 241, 1);
  --p-color-bg-caution-strong: rgba(216, 155, 13, 1);
  --p-color-bg-caution: rgba(248, 217, 144, 1);
  --p-color-bg-caution-subdued-active: rgba(250, 229, 178, 1);
  --p-color-bg-caution-subdued: rgba(252, 240, 212, 1);
  --p-color-bg-caution-subdued-hover: rgba(254, 248, 236, 1);
  --p-color-bg-info-strong: rgba(42, 172, 187, 1);
  --p-color-bg-info-subdued-active: rgba(184, 233, 239, 1);
  --p-color-bg-info: rgba(184, 233, 239, 1);
  --p-color-bg-info-subdued: rgba(222, 245, 247, 1);
  --p-color-bg-info-subdued-hover: rgba(238, 250, 251, 1);
  --p-color-bg-interactive-active: rgba(14, 53, 108, 1);
  --p-color-bg-interactive-hover: rgba(20, 73, 149, 1);
  --p-color-bg-interactive: rgba(36, 99, 188, 1);
  --p-color-bg-interactive-subdued-active: rgba(187, 212, 247, 1);
  --p-color-bg-interactive-subdued-hover: rgba(232, 240, 253, 1);
  --p-color-bg-interactive-subdued: rgba(240, 245, 253, 1);
  --p-color-bg-interactive-selected: rgba(240, 245, 253, 1);
  --p-color-bg-warning: rgba(250, 201, 168, 1);
  --p-color-bg-magic-strong: rgba(121, 69, 227, 1);
  --p-color-bg-magic-hover: rgba(226, 214, 250, 1);
  --p-color-bg-magic: rgba(236, 227, 253, 1);
  --p-color-bg-magic-subdued-hover: rgba(236, 227, 253, 1);
  --p-color-bg-magic-subdued: rgba(242, 237, 253, 1);
  --p-color-border-input-hover: rgba(97, 106, 117, 1);
  --p-color-border-inverse: rgba(97, 106, 117, 1);
  --p-color-border-strong-hover: rgba(135, 144, 155, 1);
  --p-color-border-input: rgba(171, 177, 186, 1);
  --p-color-border-hover: rgba(171, 177, 186, 1);
  --p-color-border-strong: rgba(171, 177, 186, 1);
  --p-color-border: rgba(202, 206, 211, 1);
  --p-color-border-disabled: rgba(221, 224, 228, 1);
  --p-color-border-subdued: rgba(221, 224, 228, 1);
  --p-color-border-interactive-disabled: rgba(221, 224, 228, 1);
  --p-color-border-primary: rgba(0, 122, 92, 1);
  --p-color-border-success: rgba(22, 166, 121, 1);
  --p-color-border-success-subdued: rgba(80, 220, 169, 1);
  --p-color-border-critical-active: rgba(67, 14, 4, 1);
  --p-color-border-critical-hover: rgba(115, 24, 7, 1);
  --p-color-border-critical: rgba(197, 40, 12, 1);
  --p-color-border-critical-subdued: rgba(251, 197, 188, 1);
  --p-color-border-caution: rgba(216, 155, 13, 1);
  --p-color-border-caution-subdued: rgba(245, 196, 82, 1);
  --p-color-border-info: rgba(59, 195, 211, 1);
  --p-color-border-info-subdued: rgba(109, 211, 222, 1);
  --p-color-border-interactive-active: rgba(14, 53, 108, 1);
  --p-color-border-interactive-hover: rgba(20, 73, 149, 1);
  --p-color-border-interactive: rgba(62, 125, 213, 1);
  --p-color-border-interactive-focus: rgba(62, 125, 213, 1);
  --p-color-border-interactive-subdued: rgba(187, 212, 247, 1);
  --p-color-border-magic-strong: rgba(121, 69, 227, 1);
  --p-color-border-magic: rgba(173, 139, 241, 1);
  --p-color-icon-hover: rgba(31, 33, 36, 1);
  --p-color-icon: rgba(97, 106, 117, 1);
  --p-color-icon-active: rgba(31, 33, 36, 1);
  --p-color-icon-subdued: rgba(135, 144, 155, 1);
  --p-color-icon-disabled: rgba(171, 177, 186, 1);
  --p-color-icon-interactive-disabled: rgba(171, 177, 186, 1);
  --p-color-icon-inverse: rgba(221, 224, 228, 1);
  --p-color-icon-on-color: rgba(255, 255, 255, 1);
  --p-color-icon-primary: rgba(0, 122, 92, 1);
  --p-color-icon-success: rgba(22, 166, 121, 1);
  --p-color-icon-critical: rgba(197, 40, 12, 1);
  --p-color-icon-caution: rgba(183, 126, 11, 1);
  --p-color-icon-info: rgba(22, 166, 121, 1);
  --p-color-icon-warning: rgba(242, 117, 34, 1);
  --p-color-icon-interactive-active: rgba(14, 53, 108, 1);
  --p-color-icon-interactive-hover: rgba(20, 73, 149, 1);
  --p-color-icon-interactive: rgba(36, 99, 188, 1);
  --p-color-icon-interactive-inverse: rgba(102, 153, 225, 1);
  --p-color-icon-magic: rgba(90, 36, 205, 1);
  --p-color-text: rgba(31, 33, 36, 1);
  --p-color-text-subdued: rgba(97, 106, 117, 1);
  --p-color-text-disabled: rgba(135, 144, 155, 1);
  --p-color-text-interactive-disabled: rgba(135, 144, 155, 1);
  --p-color-text-inverse-subdued: rgba(171, 177, 186, 1);
  --p-color-text-inverse: rgba(241, 242, 244, 1);
  --p-color-text-on-color: rgba(255, 255, 255, 1);
  --p-color-text-success-strong: rgba(12, 59, 47, 1);
  --p-color-text-success: rgba(0, 122, 92, 1);
  --p-color-text-primary: rgba(0, 122, 92, 1);
  --p-color-text-primary-hover: rgba(18, 84, 67, 1);
  --p-color-text-critical-strong: rgba(67, 14, 4, 1);
  --p-color-text-critical-active: rgba(115, 24, 7, 1);
  --p-color-text-critical: rgba(197, 40, 12, 1);
  --p-color-text-caution-strong: rgba(77, 46, 5, 1);
  --p-color-text-caution: rgba(135, 92, 8, 1);
  --p-color-text-info-strong: rgba(16, 65, 71, 1);
  --p-color-text-info: rgba(32, 130, 141, 1);
  --p-color-text-warning-strong: rgba(77, 36, 5, 1);
  --p-color-text-interactive-active: rgba(14, 53, 108, 1);
  --p-color-text-interactive-hover: rgba(20, 73, 149, 1);
  --p-color-text-interactive: rgba(36, 99, 188, 1);
  --p-color-text-interactive-inverse: rgba(102, 153, 225, 1);
  --p-color-text-magic-strong: rgba(49, 13, 120, 1);
  --p-color-text-magic: rgba(90, 36, 205, 1);
  --p-background: rgba(246, 246, 247, 1);
  --p-background-hovered: rgba(241, 242, 243, 1);
  --p-background-pressed: rgba(237, 238, 239, 1);
  --p-background-selected: #f4ecfb;
  --p-surface: rgba(255, 255, 255, 1);
  --p-surface-dark: rgba(32, 33, 35, 1);
  --p-surface-neutral: rgba(228, 229, 231, 1);
  --p-surface-neutral-hovered: rgba(219, 221, 223, 1);
  --p-surface-neutral-pressed: rgba(201, 204, 208, 1);
  --p-surface-neutral-disabled: rgba(241, 242, 243, 1);
  --p-surface-neutral-subdued: rgba(246, 246, 247, 1);
  --p-surface-neutral-subdued-dark: rgba(68, 71, 74, 1);
  --p-surface-subdued: rgba(250, 251, 251, 1);
  --p-surface-disabled: rgba(250, 251, 251, 1);
  --p-surface-hovered: rgba(246, 246, 247, 1);
  --p-surface-hovered-dark: rgba(47, 49, 51, 1);
  --p-surface-pressed: rgba(241, 242, 243, 1);
  --p-surface-pressed-dark: rgba(62, 64, 67, 1);
  --p-surface-depressed: rgba(237, 238, 239, 1);
  --p-surface-search-field: rgba(241, 242, 243, 1);
  --p-surface-search-field-dark: rgba(47, 49, 51, 1);
  --p-backdrop: rgba(0, 0, 0, 0.5);
  --p-overlay: rgba(255, 255, 255, 0.5);
  --p-shadow-color-picker: rgba(0, 0, 0, 0.5);
  --p-shadow-color-picker-dragger: rgba(33, 43, 54, 0.32);
  --p-hint-from-direct-light: rgba(0, 0, 0, 0.15);
  --p-border: rgba(140, 145, 150, 1);
  --p-border-on-dark: rgba(80, 83, 86, 1);
  --p-border-neutral-subdued: rgba(186, 191, 195, 1);
  --p-border-hovered: rgba(153, 158, 164, 1);
  --p-border-disabled: rgba(210, 213, 216, 1);
  --p-border-subdued: rgba(201, 204, 207, 1);
  --p-border-depressed: rgba(87, 89, 89, 1);
  --p-border-shadow: rgba(174, 180, 185, 1);
  --p-border-shadow-subdued: rgba(186, 191, 196, 1);
  --p-divider: #eaeaea;
  --p-divider-dark: rgba(69, 71, 73, 1);
  --p-icon: rgb(28, 28, 28);
  --p-icon-on-dark: rgba(166, 172, 178, 1);
  --p-icon-hovered: rgba(26, 28, 29, 1);
  --p-icon-pressed: rgba(68, 71, 74, 1);
  --p-icon-disabled: rgba(186, 190, 195, 1);
  --p-icon-subdued: rgba(140, 145, 150, 1);
  --p-text: rgba(32, 34, 35, 1);
  --p-text-on-dark: rgba(227, 229, 231, 1);
  --p-text-disabled: rgba(140, 145, 150, 1);
  --p-text-subdued: rgba(109, 113, 117, 1);
  --p-text-subdued-on-dark: rgba(153, 159, 164, 1);
  --p-interactive: rgba(44, 110, 203, 1);
  --p-interactive-on-dark: rgba(54, 163, 255, 1);
  --p-interactive-disabled: rgba(189, 193, 204, 1);
  --p-interactive-hovered: rgba(31, 81, 153, 1);
  --p-interactive-pressed: rgba(16, 50, 98, 1);
  --p-interactive-pressed-on-dark: rgba(136, 188, 255, 1);
  --p-focused: rgba(69, 143, 255, 1);
  --p-surface-selected: rgba(242, 247, 254, 1);
  --p-surface-selected-hovered: rgba(237, 244, 254, 1);
  --p-surface-selected-pressed: rgba(229, 239, 253, 1);
  --p-icon-on-interactive: rgba(255, 255, 255, 1);
  --p-text-on-interactive: rgba(255, 255, 255, 1);
  --p-action-secondary: rgba(255, 255, 255, 1);
  --p-action-secondary-disabled: rgba(255, 255, 255, 1);
  --p-action-secondary-hovered: rgba(246, 246, 247, 1);
  --p-action-secondary-hovered-dark: rgba(84, 87, 91, 1);
  --p-action-secondary-pressed: rgba(241, 242, 243, 1);
  --p-action-secondary-pressed-dark: rgba(96, 100, 103, 1);
  --p-action-secondary-depressed: rgba(109, 113, 117, 1);
  --p-action-primary: #883dcf;
  --p-action-primary-disabled: rgba(241, 241, 241, 1);
  --p-action-primary-hovered: rgba(0, 110, 82, 1);
  --p-action-primary-pressed: #7441f6;
  --p-action-primary-depressed: rgba(0, 61, 44, 1);
  --p-icon-on-primary: rgba(255, 255, 255, 1);
  --p-text-on-primary: rgba(255, 255, 255, 1);
  --p-text-primary: #883dcf;
  --p-text-primary-hovered: rgba(0, 108, 80, 1);
  --p-text-primary-pressed: rgba(0, 92, 68, 1);
  --p-surface-primary-selected: rgba(241, 248, 245, 1);
  --p-surface-primary-selected-hovered: rgba(179, 208, 195, 1);
  --p-surface-primary-selected-pressed: rgba(162, 188, 176, 1);
  --p-border-critical: rgba(253, 87, 73, 1);
  --p-border-critical-subdued: rgba(224, 179, 178, 1);
  --p-border-critical-disabled: rgba(255, 167, 163, 1);
  --p-icon-critical: rgba(215, 44, 13, 1);
  --p-surface-critical: rgba(254, 211, 209, 1);
  --p-surface-critical-subdued: rgba(255, 244, 244, 1);
  --p-surface-critical-subdued-hovered: rgba(255, 240, 240, 1);
  --p-surface-critical-subdued-pressed: rgba(255, 233, 232, 1);
  --p-surface-critical-subdued-depressed: rgba(254, 188, 185, 1);
  --p-text-critical: rgba(215, 44, 13, 1);
  --p-action-critical: rgba(216, 44, 13, 1);
  --p-action-critical-disabled: rgba(241, 241, 241, 1);
  --p-action-critical-hovered: rgba(188, 34, 0, 1);
  --p-action-critical-pressed: rgba(162, 27, 0, 1);
  --p-action-critical-depressed: rgba(108, 15, 0, 1);
  --p-icon-on-critical: rgba(255, 255, 255, 1);
  --p-text-on-critical: rgba(255, 255, 255, 1);
  --p-interactive-critical: rgba(216, 44, 13, 1);
  --p-interactive-critical-disabled: rgba(253, 147, 141, 1);
  --p-interactive-critical-hovered: rgba(205, 41, 12, 1);
  --p-interactive-critical-pressed: rgba(103, 15, 3, 1);
  --p-border-warning: rgba(185, 137, 0, 1);
  --p-border-warning-subdued: rgba(225, 184, 120, 1);
  --p-icon-warning: rgba(185, 137, 0, 1);
  --p-surface-warning: rgba(255, 215, 157, 1);
  --p-surface-warning-subdued: rgba(255, 245, 234, 1);
  --p-surface-warning-subdued-hovered: rgba(255, 242, 226, 1);
  --p-surface-warning-subdued-pressed: rgba(255, 235, 211, 1);
  --p-text-warning: rgba(145, 106, 0, 1);
  --p-border-highlight: rgba(68, 157, 167, 1);
  --p-border-highlight-subdued: rgba(152, 198, 205, 1);
  --p-icon-highlight: rgba(0, 160, 172, 1);
  --p-surface-highlight: rgba(164, 232, 242, 1);
  --p-surface-highlight-subdued: rgba(235, 249, 252, 1);
  --p-surface-highlight-subdued-hovered: rgba(228, 247, 250, 1);
  --p-surface-highlight-subdued-pressed: rgba(213, 243, 248, 1);
  --p-text-highlight: rgba(52, 124, 132, 1);
  --p-border-success: rgba(0, 164, 124, 1);
  --p-border-success-subdued: rgba(149, 201, 180, 1);
  --p-icon-success: rgba(0, 127, 95, 1);
  --p-surface-success: rgba(174, 233, 209, 1);
  --p-surface-success-subdued: rgba(241, 248, 245, 1);
  --p-surface-success-subdued-hovered: rgba(236, 246, 241, 1);
  --p-surface-success-subdued-pressed: rgba(226, 241, 234, 1);
  --p-text-success: rgba(0, 128, 96, 1);
  --p-icon-attention: rgba(138, 97, 22, 1);
  --p-surface-attention: rgba(255, 234, 138, 1);
  --p-decorative-one-icon: rgba(126, 87, 0, 1);
  --p-decorative-one-surface: rgba(255, 201, 107, 1);
  --p-decorative-one-text: rgba(61, 40, 0, 1);
  --p-decorative-two-icon: rgba(175, 41, 78, 1);
  --p-decorative-two-surface: rgba(255, 196, 176, 1);
  --p-decorative-two-text: rgba(73, 11, 28, 1);
  --p-decorative-three-icon: rgba(0, 109, 65, 1);
  --p-decorative-three-surface: rgba(146, 230, 181, 1);
  --p-decorative-three-text: rgba(0, 47, 25, 1);
  --p-decorative-four-icon: rgba(0, 106, 104, 1);
  --p-decorative-four-surface: rgba(145, 224, 214, 1);
  --p-decorative-four-text: rgba(0, 45, 45, 1);
  --p-decorative-five-icon: rgba(174, 43, 76, 1);
  --p-decorative-five-surface: rgba(253, 201, 208, 1);
  --p-decorative-five-text: rgba(79, 14, 31, 1);
  --p-shadow-transparent: 0 0 0 0 transparent;
  --p-shadow-faint: 0 0.0625rem 0 0 rgba(22, 29, 37, 0.05);
  --p-shadow-base: 0 0 0 0.0625rem rgba(63, 63, 68, 0.05),
    0 0.0625rem 0.1875rem 0 rgba(63, 63, 68, 0.15);
  --p-shadow-deep: 0 0 0 0.0625rem rgba(6, 44, 82, 0.1),
    0 0.125rem 1rem rgba(33, 43, 54, 0.08);
  --p-shadow-button: 0 0.0625rem 0 rgba(0, 0, 0, 0.05);
  --p-shadow-top-bar: 0 0.125rem 0.125rem -0.0625rem rgba(0, 0, 0, 0.15);
  --p-shadow-card: 0 0 0.3125rem rgba(23, 24, 24, 0.05),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  --p-shadow-popover: 0 0.1875rem 0.375rem -0.1875rem rgba(23, 24, 24, 0.08),
    0 0.5rem 1.25rem -0.25rem rgba(23, 24, 24, 0.12);
  --p-shadow-layer: 0 1.9375rem 2.5625rem 0 rgba(32, 42, 53, 0.2),
    0 0.125rem 1rem 0 rgba(32, 42, 54, 0.08);
  --p-shadow-modal: 0 1.625rem 5rem rgba(0, 0, 0, 0.2),
    0 0 0.0625rem rgba(0, 0, 0, 0.2);
  --p-shadows-inset-button: inset 0 -0.0625rem 0 rgba(0, 0, 0, 0.2);
  --p-shadows-inset-button-pressed: inset 0 0.0625rem 0 rgba(0, 0, 0, 0.15);
  --p-font-family-sans: "Public Sans", sans-serif;
  --p-font-family-mono: ui-monospace, SFMono-Regular, "SF Mono", Consolas,
    "Liberation Mono", Menlo, monospace;
  --p-font-size-75: 0.75rem;
  --p-font-size-100: 20px;
  --p-font-size-200: 1rem;
  --p-font-size-250: 1.125rem;
  --p-font-size-300: 1.25rem;
  --p-font-size-400: 1.5rem;
  --p-font-size-500: 1.75rem;
  --p-font-size-600: 2rem;
  --p-font-size-700: 2.5rem;
  --p-font-weight-regular: 400;
  --p-font-weight-medium: 500;
  --p-font-weight-semibold: 600;
  --p-font-weight-bold: 700;
  --p-font-line-height-1: 1rem;
  --p-font-line-height-2: 1.25rem;
  --p-font-line-height-3: 1.5rem;
  --p-font-line-height-4: 1.75rem;
  --p-font-line-height-5: 2rem;
  --p-font-line-height-6: 2.5rem;
  --p-font-line-height-7: 3rem;
  --p-override-loading-z-index: 514;
  --p-choice-size: 1.25rem;
  --p-icon-size-small: 0.5rem;
  --p-icon-size-medium: 1.25rem;
  --p-choice-margin: 0.0625rem;
  --p-control-border-width: 0.125rem;
  --p-banner-border-default: inset 0 0.0625rem 0 0
      var(--p-border-neutral-subdued),
    inset 0 0 0 0.0625rem var(--p-border-neutral-subdued);
  --p-banner-border-success: inset 0 0.0625rem 0 0
      var(--p-border-success-subdued),
    inset 0 0 0 0.0625rem var(--p-border-success-subdued);
  --p-banner-border-highlight: inset 0 0.0625rem 0 0
      var(--p-border-highlight-subdued),
    inset 0 0 0 0.0625rem var(--p-border-highlight-subdued);
  --p-banner-border-warning: inset 0 0.0625rem 0 0
      var(--p-border-warning-subdued),
    inset 0 0 0 0.0625rem var(--p-border-warning-subdued);
  --p-banner-border-critical: inset 0 0.0625rem 0 0
      var(--p-border-critical-subdued),
    inset 0 0 0 0.0625rem var(--p-border-critical-subdued);
  --p-thin-border-subdued: 0.0625rem solid var(--p-border-subdued);
  --p-text-field-spinner-offset: 0.125rem;
  --p-text-field-focus-ring-offset: -0.25rem;
  --p-button-group-item-spacing: -0.0625rem;
  --p-range-slider-thumb-size-base: 1rem;
  --p-range-slider-thumb-size-active: 1.5rem;
  --p-frame-offset: 0rem;
  --p-duration-0: 0ms;
  --p-duration-50: 50ms;
  --p-duration-100: 100ms;
  --p-duration-150: 150ms;
  --p-duration-200: 200ms;
  --p-duration-250: 250ms;
  --p-duration-300: 300ms;
  --p-duration-350: 350ms;
  --p-duration-400: 400ms;
  --p-duration-450: 450ms;
  --p-duration-500: 500ms;
  --p-duration-5000: 5000ms;
  --p-ease: cubic-bezier(0.25, 0.1, 0.25, 1);
  --p-ease-in: cubic-bezier(0.42, 0, 1, 1);
  --p-ease-out: cubic-bezier(0, 0, 0.58, 1);
  --p-ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
  --p-linear: cubic-bezier(0, 0, 1, 1);
  --p-keyframes-bounce: p-keyframes-bounce;
  --p-keyframes-fade-in: p-keyframes-fade-in;
  --p-keyframes-pulse: p-keyframes-pulse;
  --p-keyframes-spin: p-keyframes-spin;
  --p-keyframes-appear-above: p-keyframes-appear-above;
  --p-keyframes-appear-below: p-keyframes-appear-below;
  --p-shadow-inset-lg: inset 0rem 0rem 0.4375rem 0.125rem rgba(31, 33, 36, 0.18);
  --p-shadow-inset-md: inset 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.32);
  --p-shadow-inset-sm: inset 0rem 0rem 0.1875rem rgba(31, 33, 36, 0.56);
  --p-shadow-none: none;
  --p-shadow-xs: 0rem 0rem 0.125rem rgba(31, 33, 36, 0.24);
  --p-shadow-sm: 0rem 0.0625rem 0.0625rem rgba(31, 33, 36, 0.1);
  --p-shadow-md: 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1),
    0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05);
  --p-shadow-lg: 0rem 0.25rem 0.75rem rgba(31, 33, 36, 0.2),
    0rem 0.125rem 0.375rem rgba(31, 33, 36, 0.05);
  --p-shadow-xl: 0rem 0.25rem 1.125rem -0.125rem rgba(31, 33, 36, 0.08),
    0rem 0.75rem 1.125rem -0.125rem rgba(31, 33, 36, 0.15);
  --p-shadow-2xl: 0rem 2rem 2rem rgba(31, 33, 36, 0.15),
    0rem 2rem 3.5rem -0.125rem rgba(31, 33, 36, 0.16);
  --p-border-radius-05: 0.125rem;
  --p-border-radius-1: 0.25rem;
  --p-border-radius-2: 0.5rem;
  --p-border-radius-3: 0.75rem;
  --p-border-radius-4: 1rem;
  --p-border-radius-5: 1.25rem;
  --p-border-radius-6: 1.875rem;
  --p-border-radius-full: 624.9375rem;
  --p-border-radius-base: 0.1875rem;
  --p-border-radius-large: 0.375rem;
  --p-border-radius-half: 50%;
  --p-border-width-1: 0.0625rem;
  --p-border-width-2: 0.125rem;
  --p-border-width-3: 0.1875rem;
  --p-border-width-4: 0.25rem;
  --p-border-width-5: 0.3125rem;
  --p-border-base: var(--p-border-width-1) solid var(--p-border-subdued);
  --p-border-dark: var(--p-border-width-1) solid var(--p-border);
  --p-border-transparent: var(--p-border-width-1) solid transparent;
  --p-border-divider: var(--p-border-width-1) solid var(--p-divider);
  --p-border-divider-on-dark: var(--p-border-width-1) solid
    var(--p-divider-dark);
  --p-space-0: 0;
  --p-space-025: 0.0625rem;
  --p-space-05: 0.125rem;
  --p-space-1: 0.25rem;
  --p-space-2: 0.5rem;
  --p-space-3: 0.75rem;
  --p-space-4: 1rem;
  --p-space-5: 24px;
  --p-space-6: 25px;
  --p-space-7: 1.25rem;
  --p-space-8: 2rem;
  --p-space-10: 2.5rem;
  --p-space-12: 3rem;
  --p-space-16: 4rem;
  --p-space-18: 4.625rem;
  --p-space-20: 5rem;
  --p-space-24: 6rem;
  --p-space-28: 7rem;
  --p-space-32: 8rem;
  --p-z-index-1: 100;
  --p-z-index-2: 400;
  --p-z-index-3: 510;
  --p-z-index-4: 512;
  --p-z-index-5: 513;
  --p-z-index-6: 514;
  --p-z-index-7: 515;
  --p-z-index-8: 516;
  --p-z-index-9: 517;
  --p-z-index-10: 518;
  --p-z-index-11: 519;
  --p-z-index-12: 520;
  --p-z-1: 100;
  --p-z-2: 400;
  --p-z-3: 510;
  --p-z-4: 512;
  --p-z-5: 513;
  --p-z-6: 514;
  --p-z-7: 515;
  --p-z-8: 516;
  --p-z-9: 517;
  --p-z-10: 518;
  --p-z-11: 519;
  --p-z-12: 520;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes p-keyframes-bounce {
  from,
  65%,
  85% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.85);
  }
  82.5% {
    transform: scale(1.05);
  }
}

@keyframes p-keyframes-fade-in {
  to {
    opacity: 1;
  }
}

@keyframes p-keyframes-pulse {
  from,
  75% {
    transform: scale(0.85);
    opacity: 1;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes p-keyframes-spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes p-keyframes-appear-above {
  from {
    transform: translateY(var(--p-space-1));
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes p-keyframes-appear-below {
  from {
    transform: translateY(calc(var(--p-space-1) * -1));
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.Polaris-Avatar {
  --pc-avatar-extra-small-size: 1.5rem;
  --pc-avatar-small-size: 2rem;
  --pc-avatar-medium-size: 2.5rem;
  --pc-avatar-large-size: 3.75rem;
  position: relative;
  display: block;
  overflow: hidden;
  min-width: var(--pc-avatar-extra-small-size);
  max-width: 100%;
  background: var(--p-surface-neutral);
  color: var(--p-icon-subdued);
  -webkit-user-select: none;
  user-select: none;
}

@media (forced-colors: active) {
  .Polaris-Avatar {
    border: var(--p-border-width-1) solid transparent;
  }
}

.Polaris-Avatar::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.Polaris-Avatar__Text {
  font-size: var(--p-font-size-300);
  font-weight: var(--p-font-weight-regular);
}

.Polaris-Avatar__Text.Polaris-Avatar--long {
  font-size: var(--p-font-size-100);
}

.Polaris-Avatar--shapeRound {
  border-radius: var(--p-border-radius-full);
}

.Polaris-Avatar--shapeSquare {
  border-radius: var(--p-border-radius-05);
}

.Polaris-Avatar--shapeSquare .Polaris-Avatar__Text {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-Avatar--shapeSquare .Polaris-Avatar--long {
  font-size: var(--p-font-size-75);
}

.Polaris-Avatar--hidden {
  visibility: hidden;
}

.Polaris-Avatar--sizeExtraSmall {
  width: var(--pc-avatar-extra-small-size);
}

.Polaris-Avatar--sizeSmall {
  width: var(--pc-avatar-small-size);
}

.Polaris-Avatar--sizeMedium {
  width: var(--pc-avatar-medium-size);
}

.Polaris-Avatar--sizeLarge {
  width: var(--pc-avatar-large-size);
}

.Polaris-Avatar--styleOne {
  color: var(--p-decorative-one-text);
  background: var(--p-decorative-one-surface);
}

.Polaris-Avatar--styleTwo {
  color: var(--p-decorative-two-text);
  background: var(--p-decorative-two-surface);
}

.Polaris-Avatar--styleThree {
  color: var(--p-decorative-three-text);
  background: var(--p-decorative-three-surface);
}

.Polaris-Avatar--styleFour {
  color: var(--p-decorative-four-text);
  background: var(--p-decorative-four-surface);
}

.Polaris-Avatar--styleFive {
  color: var(--p-decorative-five-text);
  background: var(--p-decorative-five-surface);
}

.Polaris-Avatar--imageHasLoaded {
  background: transparent;
}

.Polaris-Avatar__Image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.Polaris-Avatar__Initials {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Polaris-Avatar__Svg {
  width: 100%;
  height: 100%;
}

.Polaris-Text--root {
  margin: 0;
  text-align: inherit;
}

.Polaris-Text--block {
  display: block;
}

.Polaris-Text--truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Polaris-Text--visuallyHidden {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Text--start {
  text-align: start;
}

.Polaris-Text--center {
  text-align: center;
}

.Polaris-Text--end {
  text-align: end;
}

.Polaris-Text--justify {
  text-align: justify;
}

.Polaris-Text--success {
  color: var(--p-text-success);
}

.Polaris-Text--critical {
  color: var(--p-text-critical);
}

.Polaris-Text--warning {
  color: var(--p-text-warning);
}

.Polaris-Text--subdued {
  color: var(--p-text-subdued);
}

.Polaris-Text__text--inverse {
  color: var(--p-text-on-dark);
}

.Polaris-Text--headingXs {
  font-size: var(--p-font-size-75);
  line-height: var(--p-font-line-height-1);
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-Text--headingSm {
  font-size: var(--p-font-size-100);
  line-height: var(--p-font-line-height-2);
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-Text--headingMd {
  font-size: var(--p-font-size-200);
  line-height: var(--p-font-line-height-3);
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-Text--headingLg {
  font-size: var(--p-font-size-200);
  line-height: var(--p-font-line-height-2);
  font-weight: var(--p-font-weight-semibold);
}

@media (min-width: 48em) {
  .Polaris-Text--headingLg {
    font-size: var(--p-font-size-300);
    line-height: var(--p-font-line-height-3);
  }
}

.Polaris-Text--headingXl {
  font-size: var(--p-font-size-300);
  line-height: var(--p-font-line-height-3);
  font-weight: var(--p-font-weight-semibold);
}

@media (min-width: 48em) {
  .Polaris-Text--headingXl {
    font-size: var(--p-font-size-400);
    line-height: var(--p-font-line-height-4);
  }
}

.Polaris-Text--heading2xl {
  font-size: var(--p-font-size-300);
  line-height: var(--p-font-line-height-3);
  font-weight: var(--p-font-weight-semibold);
}

@media (min-width: 48em) {
  .Polaris-Text--heading2xl {
    font-size: var(--p-font-size-500);
    line-height: var(--p-font-line-height-5);
  }
}

.Polaris-Text--heading3xl {
  font-size: var(--p-font-size-400);
  line-height: var(--p-font-line-height-4);
  font-weight: var(--p-font-weight-semibold);
}

@media (min-width: 48em) {
  .Polaris-Text--heading3xl {
    font-size: var(--p-font-size-600);
    line-height: var(--p-font-line-height-6);
  }
}

.Polaris-Text--heading4xl {
  font-size: var(--p-font-size-600);
  line-height: var(--p-font-line-height-6);
  font-weight: var(--p-font-weight-bold);
}

@media (min-width: 48em) {
  .Polaris-Text--heading4xl {
    font-size: var(--p-font-size-700);
    line-height: var(--p-font-line-height-7);
  }
}

.Polaris-Text--bodySm {
  font-size: var(--p-font-size-75);
  line-height: var(--p-font-line-height-1);
}

.Polaris-Text--bodyMd {
  font-size: var(--p-font-size-100);
  line-height: var(--p-font-line-height-2);
}

.Polaris-Text--bodyLg {
  font-size: var(--p-font-size-200);
  line-height: var(--p-font-line-height-2);
}

.Polaris-Text--regular {
  font-weight: var(--p-font-weight-regular);
}

.Polaris-Text--medium {
  font-weight: var(--p-font-weight-medium);
}

.Polaris-Text--semibold {
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-Text--bold {
  font-weight: var(--p-font-weight-bold);
}

.Polaris-Text--break {
  overflow-wrap: break-word;
  width: fit-content;
  margin-inline: auto;
  margin-bottom: 28px;
}

.polaris-inline {
  display: flex;
  gap: 14px;
  margin-inline: auto;
  width: fit-content;
  margin-top: 28px;
}
.Polaris-Text--numeric {
  font-variant-numeric: tabular-nums lining-nums;
}

.Polaris-Icon {
  display: block;
  height: 25px;
  width: 25px;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.Polaris-Icon--applyColor {
  color: var(--p-surface);
}

.Polaris-Icon--hasBackdrop {
  position: relative;
  display: flex;
  align-items: center;
  margin: var(--p-space-1);
}

.Polaris-Icon--hasBackdrop::before {
  content: "";
  position: absolute;
  top: calc(var(--p-space-1) * -1);
  bottom: calc(var(--p-space-1) * -1);
  left: calc(var(--p-space-1) * -1);
  right: calc(var(--p-space-1) * -1);
  border-radius: var(--p-border-radius-full);
}

.Polaris-Icon--colorBase svg {
  fill: var(--p-icon);
}

.Polaris-Icon--colorBase::before {
  background-color: var(--p-surface-neutral);
}

.Polaris-Icon--colorSubdued svg {
  fill: var(--p-icon-subdued);
}

.Polaris-Icon--colorCritical svg {
  fill: var(--p-icon-critical);
}

.Polaris-Icon--colorCritical::before {
  background-color: var(--p-surface-critical);
}

.Polaris-Icon--colorInteractive svg {
  fill: var(--p-interactive);
}

.Polaris-Icon--colorWarning svg {
  fill: var(--p-icon-warning);
}

.Polaris-Icon--colorWarning::before {
  background-color: var(--p-surface-warning);
}

.Polaris-Icon--colorHighlight svg {
  fill: var(--p-icon-highlight);
}

.Polaris-Icon--colorHighlight::before {
  background-color: var(--p-surface-highlight);
}

.Polaris-Icon--colorSuccess svg {
  fill: var(--p-icon-success);
}

.Polaris-Icon--colorSuccess::before {
  background-color: var(--p-surface-success);
}

.Polaris-Icon--colorPrimary svg {
  fill: var(--p-action-primary);
}

.Polaris-Icon--colorMagic svg {
  fill: var(--p-color-icon-magic);
}

.Polaris-Icon__Svg,
.Polaris-Icon__Img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.Polaris-Icon__Placeholder {
  padding-bottom: 100%;
  background: currentColor;
}

.Polaris-Spinner svg {
  animation: var(--p-keyframes-spin) var(--p-duration-500) linear infinite;
  fill: var(--p-border-highlight);
}

.Polaris-Spinner--sizeSmall svg {
  height: 1.25rem;
  width: 1.25rem;
}

.Polaris-Spinner--sizeLarge svg {
  height: 2.75rem;
  width: 2.75rem;
}

.Polaris-Scrollable {
  --pc-scrollable-shadow-size: var(--p-space-5);
  --pc-scrollable-shadow-bottom: inset 0
    calc(var(--pc-scrollable-shadow-size) * -1) var(--pc-scrollable-shadow-size)
    calc(var(--pc-scrollable-shadow-size) * -1) var(--p-hint-from-direct-light);
  --pc-scrollable-shadow-top: inset 0 var(--pc-scrollable-shadow-size)
    var(--pc-scrollable-shadow-size) calc(var(--pc-scrollable-shadow-size) * -1)
    var(--p-hint-from-direct-light);
  --pc-scrollable-max-height: none;
  -webkit-overflow-scrolling: touch;
  position: relative;
  max-height: var(--pc-scrollable-max-height);
  overflow-x: hidden;
  overflow-y: hidden;
}

.Polaris-Scrollable:focus {
  outline: var(--p-border-width-2) solid var(--p-focused);
  outline-offset: var(--p-space-05);
}

.Polaris-Scrollable--horizontal {
  overflow-x: auto;
}

.Polaris-Scrollable--vertical {
  overflow-y: auto;
}

.Polaris-Scrollable--hasTopShadow {
  box-shadow: var(--pc-scrollable-shadow-top);
}

.Polaris-Scrollable--hasBottomShadow {
  box-shadow: var(--pc-scrollable-shadow-bottom);
}

.Polaris-Scrollable--hasTopShadow.Polaris-Scrollable--hasBottomShadow {
  box-shadow: var(--pc-scrollable-shadow-top),
    var(--pc-scrollable-shadow-bottom);
}

.Polaris-Box--listReset {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  outline: none;
  padding-inline-start: 0;
}

.Polaris-Box {
  --pc-box-padding-block-end-xs: initial;
  --pc-box-padding-block-end-sm: initial;
  --pc-box-padding-block-end-md: initial;
  --pc-box-padding-block-end-lg: initial;
  --pc-box-padding-block-end-xl: initial;
  padding-block-end: var(--pc-box-padding-block-end-xs);
  --pc-box-padding-block-start-xs: initial;
  --pc-box-padding-block-start-sm: initial;
  --pc-box-padding-block-start-md: initial;
  --pc-box-padding-block-start-lg: initial;
  --pc-box-padding-block-start-xl: initial;
  padding-block-start: var(--pc-box-padding-block-start-xs);
  --pc-box-padding-inline-start-xs: initial;
  --pc-box-padding-inline-start-sm: initial;
  --pc-box-padding-inline-start-md: initial;
  --pc-box-padding-inline-start-lg: initial;
  --pc-box-padding-inline-start-xl: initial;
  padding-inline-start: var(--pc-box-padding-inline-start-xs);
  --pc-box-padding-inline-end-xs: initial;
  --pc-box-padding-inline-end-sm: initial;
  --pc-box-padding-inline-end-md: initial;
  --pc-box-padding-inline-end-lg: initial;
  --pc-box-padding-inline-end-xl: initial;
  padding-inline-end: var(--pc-box-padding-inline-end-xs);
  --pc-box-shadow: initial;
  --pc-box-background: initial;
  --pc-box-border-radius: initial;
  --pc-box-border-radius-end-start: initial;
  --pc-box-border-radius-end-end: initial;
  --pc-box-border-radius-start-start: initial;
  --pc-box-border-radius-start-end: initial;
  --pc-box-border: initial;
  --pc-box-border-block-end: initial;
  --pc-box-border-inline-start: initial;
  --pc-box-border-inline-end: initial;
  --pc-box-border-block-start: initial;
  --pc-box-color: initial;
  --pc-box-min-height: initial;
  --pc-box-min-width: initial;
  --pc-box-max-width: initial;
  --pc-box-outline: initial;
  --pc-box-overflow-x: initial;
  --pc-box-overflow-y: initial;
  --pc-box-width: initial;
  --pc-box-border-width: var(--p-border-width-1);
  --pc-box-inset-block-start: initial;
  --pc-box-inset-block-end: initial;
  --pc-box-inset-inline-start: initial;
  --pc-box-inset-inline-end: initial;
  inset-block-start: var(--pc-box-inset-block-start);
  inset-block-end: var(--pc-box-inset-block-end);
  inset-inline-start: var(--pc-box-inset-inline-start);
  inset-inline-end: var(--pc-box-inset-inline-end);
  background-color: var(--pc-box-background);
  box-shadow: var(--pc-box-shadow);
  border-radius: var(--pc-box-border-radius);
  border-end-start-radius: var(
    --pc-box-border-radius-end-start,
    var(--pc-box-border-radius)
  );
  border-end-end-radius: var(
    --pc-box-border-radius-end-end,
    var(--pc-box-border-radius)
  );
  border-start-start-radius: var(
    --pc-box-border-radius-start-start,
    var(--pc-box-border-radius)
  );
  border-start-end-radius: var(
    --pc-box-border-radius-start-end,
    var(--pc-box-border-radius)
  );
  border-block-end: var(--pc-box-border-block-end, var(--pc-box-border));
  border-inline-start: var(--pc-box-border-inline-start, var(--pc-box-border));
  border-inline-end: var(--pc-box-border-inline-end, var(--pc-box-border));
  border-block-start: var(--pc-box-border-block-start, var(--pc-box-border));
  border-block-start-width: var(
    --pc-box-border-block-start-width,
    var(--pc-box-border-width)
  );
  border-block-end-width: var(
    --pc-box-border-block-end-width,
    var(--pc-box-border-width)
  );
  border-inline-start-width: var(
    --pc-box-border-inline-start-width,
    var(--pc-box-border-width)
  );
  border-inline-end-width: var(
    --pc-box-border-inline-end-width,
    var(--pc-box-border-width)
  );
  color: var(--pc-box-color);
  min-height: var(--pc-box-min-height);
  min-width: var(--pc-box-min-width);
  max-width: var(--pc-box-max-width);
  outline: var(--pc-box-outline);
  overflow-x: var(--pc-box-overflow-x);
  overflow-y: var(--pc-box-overflow-y);
  width: var(--pc-box-width);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

@media (min-width: 30.625em) {
  .Polaris-Box {
    padding-block-end: var(
      --pc-box-padding-block-end-sm,
      var(--pc-box-padding-block-end-xs)
    );
  }
}

@media (min-width: 48em) {
  .Polaris-Box {
    padding-block-end: var(
      --pc-box-padding-block-end-md,
      var(--pc-box-padding-block-end-sm, var(--pc-box-padding-block-end-xs))
    );
  }
}

@media (min-width: 65em) {
  .Polaris-Box {
    padding-block-end: var(
      --pc-box-padding-block-end-lg,
      var(
        --pc-box-padding-block-end-md,
        var(--pc-box-padding-block-end-sm, var(--pc-box-padding-block-end-xs))
      )
    );
  }
}

@media (min-width: 90em) {
  .Polaris-Box {
    padding-block-end: var(
      --pc-box-padding-block-end-xl,
      var(
        --pc-box-padding-block-end-lg,
        var(
          --pc-box-padding-block-end-md,
          var(--pc-box-padding-block-end-sm, var(--pc-box-padding-block-end-xs))
        )
      )
    );
  }
}

@media (min-width: 30.625em) {
  .Polaris-Box {
    padding-block-start: var(
      --pc-box-padding-block-start-sm,
      var(--pc-box-padding-block-start-xs)
    );
  }
}

@media (min-width: 48em) {
  .Polaris-Box {
    padding-block-start: var(
      --pc-box-padding-block-start-md,
      var(--pc-box-padding-block-start-sm, var(--pc-box-padding-block-start-xs))
    );
  }
}

@media (min-width: 65em) {
  .Polaris-Box {
    padding-block-start: var(
      --pc-box-padding-block-start-lg,
      var(
        --pc-box-padding-block-start-md,
        var(
          --pc-box-padding-block-start-sm,
          var(--pc-box-padding-block-start-xs)
        )
      )
    );
  }
}

@media (min-width: 90em) {
  .Polaris-Box {
    padding-block-start: var(
      --pc-box-padding-block-start-xl,
      var(
        --pc-box-padding-block-start-lg,
        var(
          --pc-box-padding-block-start-md,
          var(
            --pc-box-padding-block-start-sm,
            var(--pc-box-padding-block-start-xs)
          )
        )
      )
    );
  }
}

@media (min-width: 30.625em) {
  .Polaris-Box {
    padding-inline-start: var(
      --pc-box-padding-inline-start-sm,
      var(--pc-box-padding-inline-start-xs)
    );
  }
}

@media (min-width: 48em) {
  .Polaris-Box {
    padding-inline-start: var(
      --pc-box-padding-inline-start-md,
      var(
        --pc-box-padding-inline-start-sm,
        var(--pc-box-padding-inline-start-xs)
      )
    );
  }
}

@media (min-width: 65em) {
  .Polaris-Box {
    padding-inline-start: var(
      --pc-box-padding-inline-start-lg,
      var(
        --pc-box-padding-inline-start-md,
        var(
          --pc-box-padding-inline-start-sm,
          var(--pc-box-padding-inline-start-xs)
        )
      )
    );
  }
}

@media (min-width: 90em) {
  .Polaris-Box {
    padding-inline-start: var(
      --pc-box-padding-inline-start-xl,
      var(
        --pc-box-padding-inline-start-lg,
        var(
          --pc-box-padding-inline-start-md,
          var(
            --pc-box-padding-inline-start-sm,
            var(--pc-box-padding-inline-start-xs)
          )
        )
      )
    );
  }
}

@media (min-width: 30.625em) {
  .Polaris-Box {
    padding-inline-end: var(
      --pc-box-padding-inline-end-sm,
      var(--pc-box-padding-inline-end-xs)
    );
  }
}

@media (min-width: 48em) {
  .Polaris-Box {
    padding-inline-end: var(
      --pc-box-padding-inline-end-md,
      var(--pc-box-padding-inline-end-sm, var(--pc-box-padding-inline-end-xs))
    );
  }
}

@media (min-width: 65em) {
  .Polaris-Box {
    padding-inline-end: var(
      --pc-box-padding-inline-end-lg,
      var(
        --pc-box-padding-inline-end-md,
        var(--pc-box-padding-inline-end-sm, var(--pc-box-padding-inline-end-xs))
      )
    );
  }
}

@media (min-width: 90em) {
  .Polaris-Box {
    padding-inline-end: var(
      --pc-box-padding-inline-end-xl,
      var(
        --pc-box-padding-inline-end-lg,
        var(
          --pc-box-padding-inline-end-md,
          var(
            --pc-box-padding-inline-end-sm,
            var(--pc-box-padding-inline-end-xs)
          )
        )
      )
    );
  }
}

.Polaris-Box--visuallyHidden {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

@media print {
  .Polaris-Box--printHidden {
    display: none !important;
  }
}

.Polaris-Popover {
  max-width: calc(100vw - var(--p-space-8));
  margin: 0.3125rem var(--p-space-2) var(--p-space-4);
  box-shadow: var(--p-shadow-xl);
  border-radius: var(--p-border-radius-2);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: left, top;
}

.Polaris-Popover__PopoverOverlay {
  opacity: 0;
  transition:
    opacity var(--p-duration-100) var(--p-ease),
    transform var(--p-duration-100) var(--p-ease);
  transform: translateY(-0.3125rem);
}

.Polaris-Popover__PopoverOverlay--entering {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-Popover__PopoverOverlay--VD-open {
  opacity: 1;
  transform: none;
}

.Polaris-Popover__PopoverOverlay--exiting {
  opacity: 1;
  transform: translateY(0);
  transition-duration: var(--p-duration-0);
}

.Polaris-Popover--measuring:not(.Polaris-Popover__PopoverOverlay--exiting) {
  opacity: 0;
  transform: translateY(-0.3125rem);
}

.Polaris-Popover--fullWidth {
  margin: 0.3125rem auto 0 auto;
}

.Polaris-Popover--fullWidth .Polaris-Popover__Content {
  max-width: none;
}

.Polaris-Popover--positionedAbove {
  margin: var(--p-space-4) var(--p-space-2) 0.3125rem;
}

.Polaris-Popover--positionedAbove.Polaris-Popover--fullWidth {
  margin: 0 auto 0.3125rem auto;
}

.Polaris-Popover__Content {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--p-border-radius-1);
  max-width: 25rem;
  max-height: 31.25rem;
}

.Polaris-Popover__Content:focus {
  outline: none;
}

.Polaris-Popover__Content--fullHeight {
  max-height: none;
}

.Polaris-Popover__Content--fluidContent {
  max-height: none;
  max-width: none;
}

.Polaris-Popover__Pane {
  flex: 1 1 auto;
  max-width: 100%;
}

.Polaris-Popover__Pane + .Polaris-Popover__Pane {
  border-top: var(--p-border-divider);
}

.Polaris-Popover__Pane:focus {
  outline: none;
}

.Polaris-Popover__Pane--fixed {
  overflow: visible;
  flex: 0 0 auto;
}

.Polaris-Popover__Pane--captureOverscroll {
  overscroll-behavior: contain;
}

.Polaris-Popover__Section + .Polaris-Popover__Section {
  border-top: var(--p-border-divider);
}

.Polaris-Popover__FocusTracker {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

@media print {
  .Polaris-Popover__PopoverOverlay--hideOnPrint {
    display: none !important;
  }
}

.Polaris-PositionedOverlay {
  position: absolute;
  z-index: var(--p-z-index-2);
}

.Polaris-PositionedOverlay--fixed {
  position: fixed;
}

.Polaris-PositionedOverlay--calculating {
  visibility: hidden;
}

.Polaris-PositionedOverlay--preventInteraction {
  pointer-events: none;
}

.Polaris-Badge {
  --pc-badge-horizontal-padding: var(--p-space-2);
  --pc-badge-vertical-padding: var(--p-space-05);
  display: inline-flex;
  align-items: center;
  padding: var(--pc-badge-vertical-padding) var(--pc-badge-horizontal-padding);
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-5);
  color: var(--p-text);
}

@media print {
  .Polaris-Badge {
    border: solid var(--p-border-width-1) var(--p-border);
  }
}

.Polaris-Badge--statusSuccess {
  background-color: var(--p-surface-success);
}

.Polaris-Badge--statusInfo {
  background-color: var(--p-surface-highlight);
}

.Polaris-Badge--statusAttention {
  background-color: var(--p-surface-attention);
}

.Polaris-Badge--statusWarning {
  background-color: var(--p-surface-warning);
}

.Polaris-Badge--statusCritical {
  background-color: var(--p-surface-critical);
}

.Polaris-Badge--statusNew {
  border: none;
}

.Polaris-Badge--withinFilter {
  border-radius: var(--p-border-radius-1);
}

.Polaris-Badge__Icon {
  margin-left: calc((var(--p-space-1)) * -1);
}

.Polaris-Badge__Icon + *:not(.Polaris-Badge__Icon) {
  margin-left: var(--p-space-1);
}

.Polaris-Badge__PipContainer {
  display: grid;
  align-items: center;
  margin-left: calc(var(--p-space-05) * -1);
  margin-right: var(--p-space-1);
}

.Polaris-Badge-Pip {
  --pc-pip-size: var(--p-space-2);
  --pc-pip-color: var(--p-icon);
  display: inline-block;
  color: var(--pc-pip-color);
  height: var(--pc-pip-size);
  width: var(--pc-pip-size);
  border: var(--p-border-width-2) solid var(--pc-pip-color);
  border-radius: var(--p-border-radius-full);
  flex-shrink: 0;
}

.Polaris-Badge-Pip--statusInfo {
  --pc-pip-color: var(--p-icon-highlight);
}

.Polaris-Badge-Pip--statusSuccess {
  --pc-pip-color: var(--p-icon-success);
}

.Polaris-Badge-Pip--statusNew {
  --pc-pip-color: var(--p-icon);
}

.Polaris-Badge-Pip--statusAttention {
  --pc-pip-color: var(--p-icon-attention);
}

.Polaris-Badge-Pip--statusWarning {
  --pc-pip-color: var(--p-icon-warning);
}

.Polaris-Badge-Pip--statusCritical {
  --pc-pip-color: var(--p-icon-critical);
}

.Polaris-Badge-Pip--progressIncomplete {
  background: transparent;
}

.Polaris-Badge-Pip--progressPartiallyComplete {
  background: linear-gradient(
    to top,
    currentColor,
    currentColor 50%,
    transparent 50%,
    transparent
  );
}

@media print {
  .Polaris-Badge-Pip--progressPartiallyComplete {
    background: none; /* stylelint-disable -- 100px is an arbitrarily large number so that you can't see the curvature of the box shadow. y-offset is 2px larger than the spread to make it look like it is half-way down the pip (which is 4px tall) */
    box-shadow: 0 -6.375rem 0 -6.25rem currentColor inset; /* stylelint-enable */
  }
}

.Polaris-Badge-Pip--progressComplete {
  background: currentColor;
}

@media print {
  .Polaris-Badge-Pip--progressComplete {
    background: none; /* stylelint-disable -- 100px is an arbitrarily large number so that you can't see the curvature of the box shadow. */
    box-shadow: 0 0 0 6.25rem currentColor inset; /* stylelint-enable */
  }
}

.Polaris-ActionList__Item {
  --pc-action-list-image-size: 1.25rem;
  --pc-action-list-item-min-height: var(--p-space-10);
  --pc-action-list-item-vertical-padding: calc(
    (var(--pc-action-list-item-min-height) - var(--p-font-line-height-2)) / 2
  );
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  min-height: var(--pc-action-list-item-min-height);
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  padding: var(--pc-action-list-item-vertical-padding) var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  border-top: var(--p-border-width-1) solid transparent;
  color: inherit;
}

.Polaris-ActionList__Item:focus {
  outline: none;
}

.Polaris-ActionList__Item::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

@media (forced-colors: active) {
  .Polaris-ActionList__Item {
    border: var(--p-border-width-1) solid transparent;
  }
}

.Polaris-ActionList__Item:hover {
  background-color: var(--p-surface-hovered);
  text-decoration: none;
  /* outline: var(--p-border-width-3) solid transparent; */
}

.Polaris-ActionList__Item:active {
  /* background-color: var(--p-surface-pressed); */
}

.Polaris-ActionList__Item:active svg {
  fill: var(--p-interactive);
}

.Polaris-ActionList__Item:focus-visible:not(:active) {
  /* outline: var(--p-border-width-3) solid transparent; */
}

.Polaris-ActionList__Item:visited {
  color: inherit;
}

.Polaris-ActionList__Item.Polaris-ActionList--active {
  background-color: var(--p-surface-selected);
}

.Polaris-ActionList__Item.Polaris-ActionList--active svg {
  fill: var(--p-interactive);
}

.Polaris-ActionList__Item.Polaris-ActionList--active::before {
  content: "";
  background-color: var(--p-interactive);
  position: absolute;
  top: 0;
  left: calc(var(--p-space-2) * -1);
  height: 100%;
  display: block;
  width: var(--p-border-width-3);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive:hover {
  background-color: var(--p-surface-critical-subdued-hovered);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive:active,
.Polaris-ActionList__Item.Polaris-ActionList--destructive.Polaris-ActionList--active {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-ActionList__Item.Polaris-ActionList--disabled {
  background-image: none;
  color: var(--p-text-disabled);
}

.Polaris-ActionList__Item.Polaris-ActionList--disabled
  .Polaris-ActionList__Prefix
  svg,
.Polaris-ActionList__Item.Polaris-ActionList--disabled
  .Polaris-ActionList__Suffix
  svg {
  fill: var(--p-icon-disabled);
}

.Polaris-ActionList__Prefix {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: var(--pc-action-list-image-size);
  width: var(--pc-action-list-image-size);
  border-radius: var(--p-border-radius-1);
  margin: calc(var(--pc-action-list-image-size) * -0.5) 0
    calc(var(--pc-action-list-image-size) * -0.5) 0;
  background-size: cover;
  background-position: center center;
}

.Polaris-ActionList__Prefix svg {
  fill: var(--p-icon);
}

.Polaris-ActionList__Suffix svg {
  fill: var(--p-icon);
}

.Polaris-ActionList__Text {
  min-width: 0;
  max-width: 100%;
  flex: 1 1 auto;
}

.Polaris-Inline {
  --pc-inline-gap-xs: initial;
  --pc-inline-gap-sm: initial;
  --pc-inline-gap-md: initial;
  --pc-inline-gap-lg: initial;
  --pc-inline-gap-xl: initial;
  gap: var(--pc-inline-gap-xs);
  display: flex;
  flex-wrap: var(--pc-inline-wrap);
  align-items: var(--pc-inline-block-align);
  justify-content: var(--pc-inline-align);
}

@media (min-width: 30.625em) {
  .Polaris-Inline {
    gap: var(--pc-inline-gap-sm, var(--pc-inline-gap-xs));
  }
}

@media (min-width: 48em) {
  .Polaris-Inline {
    gap: var(
      --pc-inline-gap-md,
      var(--pc-inline-gap-sm, var(--pc-inline-gap-xs))
    );
  }
}

@media (min-width: 65em) {
  .Polaris-Inline {
    gap: var(
      --pc-inline-gap-lg,
      var(--pc-inline-gap-md, var(--pc-inline-gap-sm, var(--pc-inline-gap-xs)))
    );
  }
}

@media (min-width: 90em) {
  .Polaris-Inline {
    gap: var(
      --pc-inline-gap-xl,
      var(
        --pc-inline-gap-lg,
        var(
          --pc-inline-gap-md,
          var(--pc-inline-gap-sm, var(--pc-inline-gap-xs))
        )
      )
    );
  }
}

.Polaris-Button {
  --pc-button-slim-min-height: var(--p-font-line-height-4);
  --pc-button-large-min-heigut: 2.75rem;
  --pc-button-vertical-padding: calc(
    (2.25rem - var(--p-font-line-height-2) - var(--p-space-05)) / 2
  );
  --pc-button-slim-vertical-padding: calc(
    (
        var(--pc-button-slim-min-height) - var(--p-font-line-height-2) -
          var(--p-space-05)
      ) / 2
  );
  --pc-button-large-vertical-padding: calc(
    (
        var(--pc-button-large-min-height) - var(--p-font-line-height-2) -
          var(--p-space-05)
      ) / 2
  );
  --pc-button-spinner-size: 1.25rem;
  --pc-button-segment: 10;
  --pc-button-focused: 20;
  --pc-button-disclosure-icon-offset: -0.375rem;
  position: relative;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  min-width: 2.25rem;
  margin: 0;
  padding: calc((2.25rem - var(--p-font-line-height-2) - var(--p-space-05)) / 2)
    var(--p-space-4);
  background: unset;
  /* box-shadow: var(--p-shadow-sm); */
  border-radius: var(--p-border-radius-2);
  color: var(--p-text);
  border: var(--p-border-width-0) solid var(--p-border-neutral-subdued);
  /* border-top-color: var(--p-border-subdued); */
  /* border-bottom-color: var(--p-border-shadow-subdued); */
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Button--unfilled {
  background: var(--Primary-Primary-50, #f4ecfb);
}

.Polaris-Button--third {
  border: var(--p-border-width-1) solid var(--Primary);
  color: var(--Primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  outline: none;
}
.Polaris-Button--active {
  border: var(--p-border-width-1) solid var(--font-grey);
  color: var(--font-grey);
}
.Polaris-Button--active img {
  filter: brightness(0) saturate(100%) invert(48%) sepia(6%) saturate(350%)
    hue-rotate(189deg) brightness(98%) contrast(93%);
}
.Polaris-Button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  right: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  bottom: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  left: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-1) * -1 + -0.0625rem)
    var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Button svg {
  fill: var(--p-icon);
}

.Polaris-Button:hover {
  opacity: 0.9;
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button:focus-visible {
  box-shadow: var(--p-shadow-sm);
  outline: 0;
}

.Polaris-Button:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-shadow-sm);
}

.Polaris-Button:active::after {
  border: none;
  box-shadow: none;
}

.Polaris-Button.Polaris-Button--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-shadow-inset-md);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Button.Polaris-Button--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Button {
    border: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-Button.Polaris-Button--disabled {
  transition: none;
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
}

.Polaris-Button.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button.Polaris-Button--connectedDisclosure {
  z-index: var(--pc-button-segment);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Button.Polaris-Button--connectedDisclosure::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Button.Polaris-Button--connectedDisclosure:focus {
  z-index: var(--pc-button-focused);
}

.Polaris-Button__Content {
  font-size: 14px;
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-1);
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0.0625rem;
  min-height: 0.0625rem;
}

.Polaris-Button:not(.Polaris-Button--plain) .Polaris-Button__Content {
  width: 100%;
}

.Polaris-Button--textAlignLeft {
  justify-content: flex-start;
  text-align: left;
}

.Polaris-Button--textAlignLeft .Polaris-Button__Content {
  justify-content: flex-start;
}

.Polaris-Button--textAlignStart {
  justify-content: flex-start;
  text-align: start;
}

.Polaris-Button--textAlignStart .Polaris-Button__Content {
  justify-content: flex-start;
}

.Polaris-Button--textAlignCenter {
  justify-content: center;
  text-align: center;
}

.Polaris-Button--textAlignRight {
  justify-content: flex-end;
  text-align: right;
}

.Polaris-Button--textAlignRight .Polaris-Button__Content {
  justify-content: flex-end;
}

.Polaris-Button--textAlignEnd {
  justify-content: flex-end;
  text-align: end;
}

.Polaris-Button--textAlignEnd .Polaris-Button__Content {
  justify-content: flex-end;
}

.Polaris-Button__Icon {
  margin-left: calc((var(--p-space-1)) * -1);
}

.Polaris-Button__Icon:last-child {
  margin-right: var(--pc-button-disclosure-icon-offset);
  margin-left: var(--p-space-1);
}

.Polaris-Button--fullWidth.Polaris-Button--textAlignLeft
  .Polaris-Button__Icon:last-child:not(:only-child) {
  margin-left: auto;
}

.Polaris-Button__Icon + *:not(.Polaris-Button__Icon) {
  margin-left: var(--p-space-1);
}

.Polaris-Button--hidden {
  visibility: hidden;
}

.Polaris-Button__Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: calc((var(--pc-button-spinner-size) / 2) * -1);
  margin-left: calc((var(--pc-button-spinner-size) / 2) * -1);
}

.Polaris-Button--primary,
.Polaris-Button--destructive {
  position: relative;
  background: var(--pc-button-color);
  border-width: 0;
  border-color: transparent;
  box-shadow: var(--p-shadow-sm);
  color: var(--pc-button-text);
}

.Polaris-Button--primary::after,
.Polaris-Button--destructive::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Button--primary:hover,
.Polaris-Button--destructive:hover {
  border-color: transparent;
  color: var(--pc-button-text);
}

.Polaris-Button--primary:focus,
.Polaris-Button--destructive:focus {
  border-color: transparent;
  box-shadow: var(--p-shadow-sm);
}

.Polaris-Button--primary:active,
.Polaris-Button--destructive:active {
  background: var(--pc-button-color-active);
  border-color: transparent;
  box-shadow: var(--p-shadow-sm);
}

.Polaris-Button--primary.Polaris-Button--pressed,
.Polaris-Button--destructive.Polaris-Button--pressed {
  color: var(--pc-button-text);
  background: var(--pc-button-color-depressed);
  border-color: transparent;
  box-shadow: var(--p-shadow-sm);
}

.Polaris-Button--primary.Polaris-Button--pressed:hover,
.Polaris-Button--primary.Polaris-Button--pressed:focus,
.Polaris-Button--destructive.Polaris-Button--pressed:hover,
.Polaris-Button--destructive.Polaris-Button--pressed:focus {
  background: var(--pc-button-color-depressed);
  box-shadow: var(--p-shadow-sm);
}

.Polaris-Button--primary.Polaris-Button--disabled,
.Polaris-Button--destructive.Polaris-Button--disabled {
  color: var(--p-text-disabled);
  box-shadow: none;
  border-color: transparent;
}

.Polaris-Button--primary.Polaris-Button--disabled svg,
.Polaris-Button--destructive.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button--primary {
  --pc-button-color: var(--p-action-primary);
  --pc-button-text: var(--p-text-on-primary);
  --pc-button-color-hover: var(--p-action-primary-hovered);
  --pc-button-color-active: var(--p-action-primary-pressed);
  --pc-button-color-depressed: var(--p-action-primary-depressed);
}

.Polaris-Button--primary svg {
  fill: var(--p-icon-on-primary);
}

.Polaris-Button--primary.Polaris-Button--disabled {
  background: var(--p-action-primary-disabled);
}

.Polaris-Button--destructive {
  --pc-button-color: var(--p-action-critical);
  --pc-button-text: var(--p-text-on-critical);
  --pc-button-color-hover: var(--p-action-critical-hovered);
  --pc-button-color-active: var(--p-action-critical-pressed);
  --pc-button-color-depressed: var(--p-action-critical-depressed);
}

.Polaris-Button--destructive svg {
  fill: var(--p-icon-on-critical);
}

.Polaris-Button--destructive.Polaris-Button--disabled {
  background: var(--p-action-critical-disabled);
}

.Polaris-Button--outline {
  background: transparent;
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  color: var(--p-text);
  position: relative;
}

.Polaris-Button--outline::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Button--outline:hover {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-hovered);
}

.Polaris-Button--outline:focus-visible {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
}

.Polaris-Button--outline:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--outline:active {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-pressed);
}

.Polaris-Button--outline:active::after {
  box-shadow: none;
}

.Polaris-Button--outline.Polaris-Button--pressed {
  background: var(--p-action-secondary-pressed);
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  color: var(--pc-button-text);
}

.Polaris-Button--outline.Polaris-Button--disabled {
  border: var(--p-border-width-1) solid var(--p-border-disabled);
  color: var(--p-text-disabled);
  background: transparent;
  box-shadow: none;
}

.Polaris-Button--outline.Polaris-Button--destructive {
  background: transparent;
  border: var(--p-border-width-1) solid var(--p-border-critical);
  box-shadow: none;
  color: var(--p-interactive-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive:hover {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--outline.Polaris-Button--destructive:focus-visible {
  border: var(--p-border-width-1) solid var(--p-border-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--outline.Polaris-Button--destructive:active {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--disabled {
  border: var(--p-border-width-1) solid var(--p-border-critical-disabled);
  background: transparent;
  color: var(--p-interactive-critical-disabled);
}

.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--pressed {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
  color: var(--p-interactive-critical);
}

.Polaris-Button--disabled {
  cursor: default;
  pointer-events: none;
}

.Polaris-Button--loading {
  position: relative;
}

.Polaris-Button--loading,
.Polaris-Button--loading:hover,
.Polaris-Button--loading.Polaris-Button--disabled {
  color: transparent;
}

.Polaris-Button--loading.Polaris-Button--disabled svg {
  fill: var(--p-icon-subdued);
}

.Polaris-Button--plain {
  margin: calc(var(--pc-button-vertical-padding) * -1)
    calc(var(--p-space-2) * -1);
  padding-left: var(--p-space-2);
  padding-right: var(--p-space-2);
  background: transparent;
  border: 0;
  box-shadow: none;
  color: var(--p-interactive);
}

.Polaris-Button--plain svg {
  fill: var(--p-interactive);
}

.Polaris-Button--plain > .Polaris-Button__Content {
  font-weight: var(--p-font-weight-regular);
  position: relative;
}

.Polaris-Button--plain > .Polaris-Button__Content::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Button--plain:hover,
.Polaris-Button--plain:focus:not(.Polaris-Button--disabled) {
  color: var(--p-interactive-hovered);
  background: transparent;
  box-shadow: none;
}

.Polaris-Button--plain:hover svg,
.Polaris-Button--plain:focus:not(.Polaris-Button--disabled) svg {
  fill: var(--p-interactive-hovered);
}

.Polaris-Button--plain:hover:not(.Polaris-Button--removeUnderline),
.Polaris-Button--plain:focus:not(.Polaris-Button--disabled):not(
    .Polaris-Button--removeUnderline
  ) {
  text-decoration: underline;
}

.Polaris-Button--plain.Polaris-Button--pressed,
.Polaris-Button--plain:active {
  color: var(--p-interactive-pressed);
  background: transparent;
  box-shadow: none;
}

.Polaris-Button--plain.Polaris-Button--pressed svg,
.Polaris-Button--plain:active svg {
  fill: var(--p-interactive-pressed);
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain.Polaris-Button--pressed,
  .Polaris-Button--plain:active {
    outline: none;
  }
}

.Polaris-Button--plain:focus-visible::after {
  content: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain:focus-visible {
    outline: none;
  }
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain:focus-visible > .Polaris-Button__Content {
    outline: var(--p-border-width-2) dotted;
  }
}

.Polaris-Button--plain:focus-visible:not(:active)
  > .Polaris-Button__Content::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--plain:active:not(.Polaris-Button--iconOnly)
  > .Polaris-Button__Content,
.Polaris-Button--plain.Polaris-Button--pressed:not(.Polaris-Button--iconOnly)
  > .Polaris-Button__Content {
  padding: var(--p-space-05) var(--p-space-1);
  margin: calc(var(--p-space-05) * -1) calc(var(--p-space-1) * -1);
  background: transparent;
  border-radius: var(--p-border-radius-1);
}

.Polaris-Button--plain.Polaris-Button--fullWidth {
  margin-left: 0;
  margin-right: 0;
}

.Polaris-Button--plain.Polaris-Button--disabled {
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-Button--plain.Polaris-Button--disabled.Polaris-Button--loading {
  color: transparent;
}

.Polaris-Button--plain.Polaris-Button--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive svg {
  fill: var(--p-interactive-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive:hover {
  color: var(--p-interactive-critical-hovered);
}

.Polaris-Button--plain.Polaris-Button--destructive:active,
.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--pressed {
  color: var(--p-interactive-critical-pressed);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--disabled {
  color: var(--p-interactive-critical-disabled);
}

.Polaris-Button--plain.Polaris-Button--sizeSlim {
  margin-top: calc(var(--pc-button-slim-vertical-padding) * -1);
  margin-bottom: calc(var(--pc-button-slim-vertical-padding) * -1);
}

.Polaris-Button--plain.Polaris-Button--sizeLarge {
  margin: calc(var(--pc-button-large-vertical-padding) * -1)
    calc(var(--p-space-5) * -1);
}

.Polaris-Button--plain.Polaris-Button--iconOnly {
  margin: calc(var(--p-space-2) * -1);
}

.Polaris-Button--plain.Polaris-Button--iconOnly svg {
  fill: var(--p-icon);
}

.Polaris-Button--plain.Polaris-Button--iconOnly:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Button--plain.Polaris-Button--iconOnly:active svg,
.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--pressed svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly
  svg {
  fill: var(--p-action-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly:hover
  svg {
  fill: var(--p-action-critical-hovered);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly:active
  svg,
.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--pressed
  svg {
  fill: var(--p-action-critical-pressed);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--disabled
  svg {
  fill: var(--p-action-critical-disabled);
}

.Polaris-Button--plain .Polaris-Button__Icon {
  margin-left: 0;
  margin-right: 0;
}

.Polaris-Button--fullWidth {
  display: flex;
  width: 100%;
}

.Polaris-Button--iconOnly {
  padding-left: var(--p-space-2);
  padding-right: var(--p-space-2);
}

.Polaris-Button--iconOnly.Polaris-Button--sizeLarge {
  padding-left: var(--p-space-3);
  padding-right: var(--p-space-3);
}

.Polaris-Button--iconOnly.Polaris-Button--sizeSlim {
  padding-left: var(--p-space-1);
  padding-right: var(--p-space-1);
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:first-child {
  margin-left: 0;
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:last-child {
  margin-right: calc(var(--p-space-1) * -1);
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:only-child {
  margin-right: 0;
}

.Polaris-Button--sizeSlim {
  min-height: var(--pc-button-slim-min-height);
  padding: var(--pc-button-slim-vertical-padding) var(--p-space-3);
}

.Polaris-Button--sizeLarge {
  min-height: var(--pc-button-large-min-height);
  min-width: var(--pc-button-large-min-height);
  padding: var(--pc-button-large-vertical-padding) var(--p-space-6);
}

.Polaris-Button--sizeLarge .Polaris-Button__Content {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
}
.Polaris-Button__Text_Neutral {
  color: var(--Primary-Primary-500, var(--Primary, #883dcf));
  font-weight: 600;
}
.Polaris-Button_background {
  color: var(--p-white-color, #fff);
  background: var(--Primary, #883dcf);
}

.Polaris-Button--monochrome.Polaris-Button--outline,
.Polaris-Button--monochrome.Polaris-Button--plain {
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline svg,
.Polaris-Button--monochrome.Polaris-Button--plain svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover,
.Polaris-Button--monochrome.Polaris-Button--outline:focus,
.Polaris-Button--monochrome.Polaris-Button--outline:active,
.Polaris-Button--monochrome.Polaris-Button--plain:hover,
.Polaris-Button--monochrome.Polaris-Button--plain:focus,
.Polaris-Button--monochrome.Polaris-Button--plain:active {
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover svg,
.Polaris-Button--monochrome.Polaris-Button--outline:focus svg,
.Polaris-Button--monochrome.Polaris-Button--outline:active svg,
.Polaris-Button--monochrome.Polaris-Button--plain:hover svg,
.Polaris-Button--monochrome.Polaris-Button--plain:focus svg,
.Polaris-Button--monochrome.Polaris-Button--plain:active svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled {
  color: currentColor;
  opacity: 0.4;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled
  svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--loading
  .Polaris-Button__Text,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--loading
  .Polaris-Button__Text {
  visibility: hidden;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly
  svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:focus
  svg,
.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:active
  svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:focus
  svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:active
  svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly.Polaris-Button--disabled
  svg,
.Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled
  svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--plain
  .Polaris-Button__Text:not(.Polaris-Button--removeUnderline) {
  text-decoration: underline;
}

.Polaris-Button--monochrome.Polaris-Button--outline {
  position: relative;
  border-color: currentColor;
  box-shadow: 0 0 0 var(--p-border-width-1) currentColor;
  position: relative;
}

.Polaris-Button--monochrome.Polaris-Button--outline::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1875rem;
  right: -0.1875rem;
  bottom: -0.1875rem;
  left: -0.1875rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1875rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Button--monochrome.Polaris-Button--outline::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  opacity: 0;
  z-index: 0;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed {
  background: transparent;
  box-shadow: none;
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed::before {
  opacity: 0.2;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover,
.Polaris-Button--monochrome.Polaris-Button--outline:focus,
.Polaris-Button--monochrome.Polaris-Button--outline:active {
  background-color: transparent;
  border-color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover::before,
.Polaris-Button--monochrome.Polaris-Button--outline:focus::before,
.Polaris-Button--monochrome.Polaris-Button--outline:active::before {
  opacity: 0.07;
}

.Polaris-Button--monochrome.Polaris-Button--outline:focus-visible {
  box-shadow: 0 0 0 var(--p-border-width-1) currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--monochrome.Polaris-Button--outline:active::after {
  box-shadow: none;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover::before,
.Polaris-Button--monochrome.Polaris-Button--outline:active::before {
  opacity: 0.05;
}

.Polaris-Button__ConnectedDisclosureWrapper {
  display: flex;
}

.Polaris-Button__ConnectedDisclosure {
  z-index: var(--pc-button-segment);
  margin-left: calc(var(--p-space-025) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
}

.Polaris-Button__ConnectedDisclosure:focus,
.Polaris-Button__ConnectedDisclosure:active {
  z-index: var(--pc-button-focused);
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--primary,
.Polaris-Button__ConnectedDisclosure.Polaris-Button--destructive {
  margin-left: var(--p-space-025);
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--primary.Polaris-Button--outline,
.Polaris-Button__ConnectedDisclosure.Polaris-Button--destructive.Polaris-Button--outline {
  margin-left: 0;
  border-left: 0;
}

.Polaris-Button__ConnectedDisclosure::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[data-buttongroup-segmented="true"] .Polaris-Button,
[data-buttongroup-segmented="true"] .Polaris-Button::after {
  border-radius: 0;
}

[data-buttongroup-segmented="true"] > :first-child .Polaris-Button,
[data-buttongroup-segmented="true"] > :first-child .Polaris-Button::after {
  border-radius: 0;
  border-top-left-radius: var(--p-border-radius-1);
  border-bottom-left-radius: var(--p-border-radius-1);
}

[data-buttongroup-segmented="true"] > :last-child .Polaris-Button,
[data-buttongroup-segmented="true"] > :last-child .Polaris-Button::after {
  border-radius: 0;
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

[data-buttongroup-segmented="true"] > :last-child:first-child .Polaris-Button,
[data-buttongroup-segmented="true"]
  > :last-child:first-child
  .Polaris-Button::after {
  border-radius: var(--p-border-radius-1);
}

[data-buttongroup-connected-top="true"] > :first-child .Polaris-Button,
[data-buttongroup-connected-top="true"] > :first-child .Polaris-Button::after {
  border-top-left-radius: 0;
}

[data-buttongroup-connected-top="true"] > :last-child .Polaris-Button,
[data-buttongroup-connected-top="true"] > :last-child .Polaris-Button::after {
  border-top-right-radius: 0;
}

[data-buttongroup-full-width="true"] .Polaris-Button {
  display: flex;
  width: 100%;
}

.Polaris-SettingAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-4) * -1);
}

.Polaris-SettingAction__Setting,
.Polaris-SettingAction__Action {
  flex: 0 0 auto;
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-4);
  max-width: calc(100% - var(--p-space-4));
  min-width: 0;
}

.Polaris-SettingAction__Setting {
  flex: 1 0 21.875rem;
}

.Polaris-AlphaStack {
  --pc-stack-gap-xs: initial;
  --pc-stack-gap-sm: initial;
  --pc-stack-gap-md: initial;
  --pc-stack-gap-lg: initial;
  --pc-stack-gap-xl: initial;
  gap: var(--pc-stack-gap-xs);
  --pc-stack-align: initial;
  --pc-stack-inline-align: initial;
  --pc-stack-order: initial;
  display: flex;
  flex-direction: var(--pc-stack-order);
  align-items: var(--pc-stack-inline-align);
  justify-content: var(--pc-stack-align);
}

@media (min-width: 30.625em) {
  .Polaris-AlphaStack {
    gap: var(--pc-stack-gap-sm, var(--pc-stack-gap-xs));
  }
}

@media (min-width: 48em) {
  .Polaris-AlphaStack {
    gap: var(--pc-stack-gap-md, var(--pc-stack-gap-sm, var(--pc-stack-gap-xs)));
  }
}

@media (min-width: 65em) {
  .Polaris-AlphaStack {
    gap: var(
      --pc-stack-gap-lg,
      var(--pc-stack-gap-md, var(--pc-stack-gap-sm, var(--pc-stack-gap-xs)))
    );
  }
}

@media (min-width: 90em) {
  .Polaris-AlphaStack {
    gap: var(
      --pc-stack-gap-xl,
      var(
        --pc-stack-gap-lg,
        var(--pc-stack-gap-md, var(--pc-stack-gap-sm, var(--pc-stack-gap-xs)))
      )
    );
  }
}

.Polaris-AlphaStack--listReset {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.Polaris-AlphaStack--fieldsetReset {
  border: none;
  margin: 0;
  padding: 0;
}

.Polaris-Tooltip-TooltipOverlay {
  --pc-tooltip-overlay-offset: var(--p-space-3);
  position: relative;
  margin: var(--pc-tooltip-overlay-offset) var(--p-space-4) var(--p-space-4);
  opacity: 1;
  box-shadow: var(--p-shadow-xl), var(--p-shadow-lg);
  border-radius: var(--pc-tooltip-border-radius);
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity, left, top, transform;
  transform: none;
  transition: none;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Tooltip-TooltipOverlay {
    border: var(--p-border-width-2) solid windowText;
  }
}

.Polaris-Tooltip-TooltipOverlay::after {
  content: "";
  position: absolute;
  top: calc(var(--p-space-4) * -1);
  left: calc(
    var(--pc-tooltip-chevron-x-pos) - var(--p-space-2) - var(--p-space-4)
  );
  height: 0;
  width: 0;
  border-width: var(--p-space-2);
  border-style: solid;
  border-color: transparent transparent var(--p-surface) transparent;
}

.Polaris-Tooltip-TooltipOverlay.Polaris-Tooltip-TooltipOverlay--positionedAbove::after {
  top: auto;
  bottom: calc(var(--p-space-4) * -1);
  border-color: var(--p-surface) transparent transparent transparent;
}

.Polaris-Tooltip-TooltipOverlay--measuring {
  opacity: 0;
}

.Polaris-Tooltip-TooltipOverlay--measured:not(
    .Polaris-Tooltip-TooltipOverlay--instant
  ) {
  animation: var(--p-keyframes-appear-below) var(--p-duration-50)
    var(--p-ease-in) var(--p-duration-100) 1 both;
}

@media (prefers-reduced-motion) {
  .Polaris-Tooltip-TooltipOverlay--measured:not(
      .Polaris-Tooltip-TooltipOverlay--instant
    ) {
    animation: none;
  }
}

.Polaris-Tooltip-TooltipOverlay--measured.Polaris-Tooltip-TooltipOverlay--positionedAbove:not(
    .Polaris-Tooltip-TooltipOverlay--instant
  ) {
  animation: var(--p-keyframes-appear-above) var(--p-duration-50)
    var(--p-ease-in) var(--p-duration-100) 1 both;
}

@media (prefers-reduced-motion) {
  .Polaris-Tooltip-TooltipOverlay--measured.Polaris-Tooltip-TooltipOverlay--positionedAbove:not(
      .Polaris-Tooltip-TooltipOverlay--instant
    ) {
    animation: none;
  }
}

.Polaris-Tooltip-TooltipOverlay--positionedAbove {
  margin: var(--p-space-4) var(--p-space-4) var(--pc-tooltip-overlay-offset);
}

.Polaris-Tooltip-TooltipOverlay__Content {
  position: relative;
  background-color: var(--p-surface);
  color: var(--p-text);
  word-break: break-word;
  border-radius: var(--pc-tooltip-border-radius);
  padding: var(--pc-tooltip-padding);
}

.Polaris-Tooltip-TooltipOverlay--default {
  max-width: 12.5rem;
}

.Polaris-Tooltip-TooltipOverlay--wide {
  max-width: 17.1875rem;
}

[data-polaris-tooltip-activator] {
  outline: 0;
  position: relative;
}

[data-polaris-tooltip-activator]::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

[data-polaris-tooltip-activator]:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tooltip__TooltipContainer {
  display: flex;
}

.Polaris-Tooltip__HasUnderline {
  border-bottom: var(--p-border-width-2) dotted var(--p-border-subdued);
}

.Polaris-ActionMenu-SecondaryAction {
  --pc-secondary-action-button-spacing: var(--p-space-3);
}

.Polaris-ActionMenu-SecondaryAction a,
.Polaris-ActionMenu-SecondaryAction button {
  position: relative;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: var(--p-border-radius-1) !important;
  padding-left: var(--pc-secondary-action-button-spacing);
  padding-right: var(--pc-secondary-action-button-spacing);
}

.Polaris-ActionMenu-SecondaryAction a::after,
.Polaris-ActionMenu-SecondaryAction button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-ActionMenu-SecondaryAction a:hover,
.Polaris-ActionMenu-SecondaryAction button:hover {
  background: var(--p-background-hovered) !important;
}

.Polaris-ActionMenu-SecondaryAction a:active,
.Polaris-ActionMenu-SecondaryAction button:active {
  background: var(--p-background-pressed) !important;
}

@media (min-width: 48em) {
  .Polaris-ActionMenu-SecondaryAction a,
  .Polaris-ActionMenu-SecondaryAction button {
    border: none !important;
    position: relative;
  }
  .Polaris-ActionMenu-SecondaryAction a::after,
  .Polaris-ActionMenu-SecondaryAction button::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -0.0625rem;
    right: -0.0625rem;
    bottom: -0.0625rem;
    left: -0.0625rem;
    display: block;
    pointer-events: none;
    box-shadow: 0 0 0 -0.0625rem var(--p-focused);
    transition: box-shadow var(--p-duration-100) var(--p-ease);
    border-radius: var(--p-border-radius-1);
  }
}

.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive
  a,
.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive
  button {
  color: var(--p-interactive-critical);
}

.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive
  a
  svg,
.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive
  button
  svg {
  fill: var(--p-icon-critical);
}

.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive
  a:hover,
.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive
  button:hover {
  background-color: var(--p-surface-critical-subdued-hovered) !important;
}

.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive
  a:active,
.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive
  button:active {
  background-color: var(--p-surface-critical-subdued-pressed) !important;
}

.Polaris-ActionMenu-MenuGroup__Details {
  margin-top: calc(var(--p-space-4) * -1);
  padding: var(--p-space-4);
}

.Polaris-ButtonGroup {
  --pc-button-group-item: 10;
  --pc-button-group-focused: 20;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: calc(var(--p-space-2) * -1);
  margin-left: calc(var(--p-space-2) * -1);
}

.Polaris-ButtonGroup__Item {
  margin-top: var(--p-space-2);
  margin-left: var(--p-space-2);
}

.Polaris-ButtonGroup__Item--plain:not(:first-child) {
  margin-left: var(--p-space-4);
}

.Polaris-ButtonGroup__Item--plain:not(:last-child) {
  margin-right: var(--p-space-2);
}

.Polaris-ButtonGroup--segmented {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0;
  margin-left: 0;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item {
  position: relative;
  z-index: var(--pc-button-group-item);
  margin-top: 0;
  margin-left: 0;
  line-height: normal;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item:not(:first-child) {
  margin-left: calc(var(--p-space-025) * -1);
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item--focused {
  z-index: var(--pc-button-group-focused);
}

.Polaris-ButtonGroup--fullWidth .Polaris-ButtonGroup__Item {
  flex: 1 1 auto;
}

.Polaris-ButtonGroup--extraTight {
  margin-top: calc(var(--p-space-1) * -1);
  margin-left: calc(var(--p-space-1) * -1);
}

.Polaris-ButtonGroup--extraTight .Polaris-ButtonGroup__Item {
  margin-top: var(--p-space-1);
  margin-left: var(--p-space-1);
}

.Polaris-ButtonGroup--tight {
  margin-top: calc(var(--p-space-2) * -1);
  margin-left: calc(var(--p-space-2) * -1);
}

.Polaris-ButtonGroup--tight .Polaris-ButtonGroup__Item {
  margin-top: var(--p-space-2);
  margin-left: var(--p-space-2);
}

.Polaris-ButtonGroup--loose {
  margin-top: calc(var(--p-space-5) * -1);
  margin-left: calc(var(--p-space-5) * -1);
}

.Polaris-ButtonGroup--loose .Polaris-ButtonGroup__Item {
  margin-top: var(--p-space-5);
  margin-left: var(--p-space-5);
}

.Polaris-ButtonGroup--noWrap {
  display: flex;
  flex-wrap: nowrap;
}

.Polaris-ActionMenu-Actions__ActionsLayout {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
}

.Polaris-ActionMenu-Actions__ActionsLayout > * {
  flex: 0 0 auto;
}

.Polaris-ActionMenu-RollupActions__RollupActivator {
  text-align: right;
}

.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"] {
  margin: 0;
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
}

.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:hover,
.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:active,
.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:focus {
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
}

.Polaris-ActionMenu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media print {
  .Polaris-ActionMenu {
    display: none !important;
  }
}

.Polaris-Combobox__Listbox {
  padding: var(--p-space-2) 0;
  overflow: visible;
}

.Polaris-Label {
  font-size: 14px;
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Label--hidden {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Label__Text {
  display: block;
  flex: 1 1 auto;
  color: currentColor;
  -webkit-tap-highlight-color: transparent;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.Polaris-Label__RequiredIndicator::after {
  content: "*";
  color: var(--p-text-critical);
  margin-left: var(--p-space-1);
}

.Polaris-InlineError {
  display: flex;
  color: var(--p-text-critical);
  fill: var(--p-icon-critical);
}

.Polaris-InlineError__Icon {
  fill: currentColor;
  margin-left: calc(var(--p-space-05) * -1);
  margin-right: calc(var(--p-space-05) + var(--p-space-1));
}

.Polaris-Labelled--hidden > .Polaris-Labelled__LabelWrapper {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Labelled__LabelWrapper {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--p-space-1);
}

.Polaris-Labelled__HelpText {
  margin-top: var(--p-space-1);
}

.Polaris-Labelled__Error {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: var(--p-space-1);
}

.Polaris-Labelled__Action {
  flex: 0 0 auto;
}

.Polaris-Connected {
  --pc-connected-item: 10;
  --pc-connected-primary: 20;
  --pc-connected-focused: 30;
  position: relative;
  display: flex;
}

.Polaris-Connected__Item {
  position: relative;
  z-index: 2;
  flex: 0 0 auto;
}

.Polaris-Connected__Item:not(:first-child) {
  margin-left: var(--p-space-1);
}

.Polaris-Connected__Item--primary {
  z-index: 2;
  flex: 1 1 auto;
}

.Polaris-Connected__Item--focused {
  z-index: var(--pc-connected-focused);
}

.Polaris-TextField {
  --pc-text-field-contents: 20;
  --pc-text-field-backdrop: 10;
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-3);
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  display: flex;
  align-items: center;
  color: var(--p-text);
  cursor: text;
}

@media (min-width: 48em) {
  .Polaris-TextField {
    font-size: var(--p-font-size-100);
  }
}

.Polaris-TextField svg {
  /* fill: var(--p-icon); */
  width: 16px;
  height: 16px;
}

.Polaris-TextField--multiline {
  padding: 0;
  flex-wrap: wrap;
}

.Polaris-TextField--multiline > .Polaris-TextField__Input {
  overflow: auto;
  padding-left: var(--p-space-3);
  padding-right: var(--p-space-3);
  resize: none;
}

.Polaris-TextField--hasValue {
  color: var(--p-text);
}

.Polaris-TextField--focus
  > .Polaris-TextField__Input
  ~ .Polaris-TextField__Backdrop::after,
.Polaris-TextField--focus
  > .Polaris-TextField__VerticalContent
  ~ .Polaris-TextField__Backdrop::after,
.Polaris-TextField__Input:focus-visible ~ .Polaris-TextField__Backdrop::after {
  /* box-shadow: 0 0 0 0.125rem var(--p-focused); */
  /* outline: var(--p-border-width-1) solid transparent; */
}

.Polaris-TextField--error
  > .Polaris-TextField__Input
  ~ .Polaris-TextField__Backdrop {
  background-color: var(--p-surface-critical-subdued);
  border-color: var(--p-border-critical);
}

.Polaris-TextField--error
  > .Polaris-TextField__Input
  ~ .Polaris-TextField__Backdrop::after {
  border-color: var(--p-focused);
}

.Polaris-TextField--readOnly > .Polaris-TextField__Backdrop {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-TextField--disabled {
  color: var(--p-text-disabled);
  cursor: initial;
}

.Polaris-TextField--disabled > .Polaris-TextField__Backdrop {
  background-color: var(--p-surface-disabled);
  border-color: var(--p-border-subdued);
  border-top-color: var(--p-border-disabled);
}

.Polaris-TextField--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-TextField__Input {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  z-index: var(--pc-text-field-contents);
  display: block;
  flex: 1 1;
  width: 100%;
  min-width: 0;
  min-height: 2.25rem;
  margin: 0;
  padding: 8px 12px;
  border: var(--p-border-transparent);
  font-family: var(--p-font-family-sans);
  caret-color: var(--p-text);
  color: var(--p-text);
  outline: none;
  /* appearance: none; */
  background: none;
}

@media (min-width: 48em) {
  .Polaris-TextField__Input {
    font-size: 12px;
    line-height: var(--p-font-line-height-2);
  }
}

.Polaris-TextField__Prefix + .Polaris-TextField__Input {
  padding-left: 0;
}

.Polaris-TextField__Input:disabled {
  opacity: 1;
  background: none;
  color: var(--p-text-disabled);
  -webkit-text-fill-color: var(--p-text-disabled);
}

.Polaris-TextField__Input:invalid {
  box-shadow: none;
}

.Polaris-TextField__Input::placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TextField__Input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.Polaris-TextField__Input[type="number"]::-webkit-outer-spin-button,
.Polaris-TextField__Input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.Polaris-TextField__Input:-webkit-autofill {
  border-radius: var(--p-border-radius-1);
}

.Polaris-TextField__Input.Polaris-TextField--suggestion::selection {
  color: var(--p-text-disabled);
  background: transparent;
}

.Polaris-TextField__Input--hasClearButton[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.Polaris-TextField__Input--suffixed {
  padding-right: 0;
}

.Polaris-TextField__Input--alignRight {
  text-align: right;
}

.Polaris-TextField__Input--alignLeft {
  text-align: left;
}

.Polaris-TextField__Input--alignCenter {
  text-align: center;
}

.Polaris-TextField__Backdrop {
  position: relative;
  position: absolute;
  z-index: var(--pc-text-field-backdrop);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-surface);
  border: var(--p-border-width-1) solid #e0e2e7;
  border-radius: var(--p-border-radius-2);
  pointer-events: none;
  background-color: #f9f9fc;
}

.Polaris-TextField__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-TextField__Prefix,
.Polaris-TextField__Suffix {
  position: relative;
  z-index: var(--pc-text-field-contents);
  flex: 0 0 auto;
  color: var(--p-text-subdued);
  -webkit-user-select: none;
  user-select: none;
}

.Polaris-TextField__Prefix {
  margin-left: var(--p-space-3);
  margin-right: var(--p-space-2);
}

.Polaris-TextField__Suffix {
  margin-left: var(--p-space-1);
  margin-right: var(--p-space-3);
}

.Polaris-TextField__VerticalContent {
  position: relative;
  z-index: var(--pc-text-field-contents);
  color: var(--p-text-subdued);
  padding: var(--p-space-2) var(--p-space-2) 0 var(--p-space-2);
  max-height: 8.75rem;
  overflow: scroll;
  border: var(--p-border-transparent);
  width: 100%;
}

.Polaris-TextField__VerticalContent > .Polaris-TextField__Input {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 30.625em) {
  .Polaris-TextField__VerticalContent {
    max-height: 20.5rem;
  }
}

.Polaris-TextField__CharacterCount {
  color: var(--p-text-subdued);
  z-index: var(--pc-text-field-contents);
  margin: 0 var(--p-space-3) 0 var(--p-space-1);
  pointer-events: none;
  text-align: right;
}

.Polaris-TextField__AlignFieldBottom {
  align-self: flex-end;
  width: 100%;
  padding-bottom: var(--p-space-2);
}

.Polaris-TextField__ClearButton {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  z-index: var(--pc-text-field-contents);
  margin: 0 var(--p-space-3) 0 var(--p-space-1);
}

.Polaris-TextField__ClearButton::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-TextField__ClearButton:focus {
  outline: none;
}

.Polaris-TextField__ClearButton:focus-visible:enabled::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-TextField__ClearButton:disabled {
  cursor: default;
}

.Polaris-TextField__Spinner {
  --pc-text-field-spinner-offset-large: calc(
    0.125rem + var(--p-border-width-1)
  );
  z-index: var(--pc-text-field-contents);
  margin: var(--pc-text-field-spinner-offset-large);
  color: var(--p-icon);
  display: flex;
  visibility: hidden;
  align-self: stretch;
  flex-direction: column;
  width: 1.375rem;
  cursor: pointer;
}

.Polaris-TextField--focus .Polaris-TextField__Spinner,
.Polaris-TextField:hover .Polaris-TextField__Spinner {
  visibility: visible;
}

.Polaris-TextField__SpinnerIcon {
  height: 0.75rem;
  width: 0.75rem;
}

.Polaris-TextField__Resizer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.Polaris-TextField__DummyInput {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-3);
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  padding: calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2)
    var(--p-space-3);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

@media (min-width: 48em) {
  .Polaris-TextField__DummyInput {
    font-size: var(--p-font-size-100);
  }
}

.Polaris-TextField__Segment {
  --pc-text-field-spinner-border-radius: calc(
    var(--p-border-radius-1) - 0.125rem
  );
  background: var(--p-surface-neutral);
  border-radius: var(--pc-text-field-spinner-border-radius);
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.Polaris-TextField__Segment:focus {
  outline: none;
}

.Polaris-TextField__Segment:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-TextField__Segment:first-child {
  border-top-right-radius: var(--pc-text-field-spinner-border-radius);
  margin-bottom: var(--p-space-05);
}

.Polaris-TextField__Segment:last-child {
  border-bottom-right-radius: var(--pc-text-field-spinner-border-radius);
}

.Polaris-TextField__Segment:not(:first-child) {
  margin-top: 0;
}

.Polaris-TextField--monospaced {
  font-family: var(--p-font-family-mono);
}

.Polaris-Listbox-Section__SectionGroup {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: var(--p-border-divider);
}

.Polaris-Listbox-Section--noDivider {
  border-bottom: none;
}

.Polaris-Choice {
  display: flex;
  justify-content: flex-start;
  padding: var(--p-space-1) 0;
  cursor: pointer;
}

@media (min-width: 30.625em) {
  .Polaris-Choice {
    display: inline-flex;
  }
}

.Polaris-Choice--labelHidden {
  padding: 0;
}

.Polaris-Choice--labelHidden > .Polaris-Choice__Label {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Choice--labelHidden .Polaris-Choice__Control {
  margin-top: 0;
  margin-right: 0;
}

.Polaris-Choice--disabled {
  cursor: default;
}

.Polaris-Choice--disabled > .Polaris-Choice__Label {
  color: var(--p-text-disabled);
}

.Polaris-Choice--disabled > .Polaris-Choice__Label:hover {
  cursor: default;
}

@media (-ms-high-contrast: active) {
  .Polaris-Choice--disabled > .Polaris-Choice__Label {
    color: grayText;
  }
}

.Polaris-Choice__Control {
  --pc-choice-size: 1.25rem;
  display: flex;
  flex: 0 0 auto;
  align-items: stretch;
  width: var(--pc-choice-size);
  height: var(--pc-choice-size);
  margin-right: var(--p-space-2);
}

@media (max-width: 47.9975em) {
  .Polaris-Choice__Control {
    --pc-choice-size: 1.375rem;
  }
}

.Polaris-Choice__Control > * {
  width: 100%;
}

.Polaris-Choice__Label {
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Choice__Label:hover {
  cursor: pointer;
}

.Polaris-Choice__Descriptions {
  --pc-choice-size: 1.25rem;
  padding-left: calc(var(--p-space-2) + var(--pc-choice-size));
}

@media (max-width: 47.9975em) {
  .Polaris-Choice__Descriptions {
    --pc-choice-size: 1.375rem;
  }
}

.Polaris-Choice__HelpText {
  margin-bottom: var(--p-space-1);
}

.Polaris-Checkbox {
  position: relative;
  margin: var(--p-space-025);
}

.Polaris-Checkbox__Input {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Checkbox__Input:focus-visible + .Polaris-Checkbox__Backdrop::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate
  + .Polaris-Checkbox__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-Checkbox__Input:active:not(:disabled)
  + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate
  + .Polaris-Checkbox__Backdrop::before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .Polaris-Checkbox__Input:active:not(:disabled)
    + .Polaris-Checkbox__Backdrop::before,
  .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::before,
  .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate
    + .Polaris-Checkbox__Backdrop::before {
    border: var(--p-border-width-2) solid windowText;
  }
}

.Polaris-Checkbox__Input:active:not(:disabled) ~ .Polaris-Checkbox__Icon,
.Polaris-Checkbox__Input:checked ~ .Polaris-Checkbox__Icon,
.Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate
  ~ .Polaris-Checkbox__Icon {
  transition:
    opacity var(--p-duration-150) var(--p-ease),
    transform var(--p-duration-150) var(--p-ease);
  transform: translate3d(-50%, -50%, 0) scale(1);
  opacity: 1;
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop::before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop:hover {
  cursor: default;
}

.Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox__Input:disabled.Polaris-Checkbox__Input--indeterminate
  + .Polaris-Checkbox__Backdrop {
  background: var(--p-border-disabled);
}

.Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox__Input:disabled.Polaris-Checkbox__Input--indeterminate
  + .Polaris-Checkbox__Backdrop::before {
  background: var(--p-border-disabled);
}

.Polaris-Checkbox__Backdrop {
  position: relative;
  border: var(--p-border-width-2) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-1);
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.Polaris-Checkbox__Backdrop::before {
  content: "";
  position: absolute;
  top: calc(var(--p-border-width-2) * -1);
  right: calc(var(--p-border-width-2) * -1);
  bottom: calc(var(--p-border-width-2) * -1);
  left: calc(var(--p-border-width-2) * -1);
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-interactive);
  opacity: 0;
  transform: scale(0.25);
  transition:
    opacity var(--p-duration-100) var(--p-ease),
    transform var(--p-duration-100) var(--p-ease);
}

.Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover,
.Polaris-Checkbox__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-Checkbox__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  right: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  bottom: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  left: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-2) * -1 + -0.0625rem)
    var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Checkbox__Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  pointer-events: none;
  transform: translate3d(-50%, -50%, 0) scale(0.25);
  opacity: 0;
  transition:
    opacity var(--p-duration-100) var(--p-ease),
    transform var(--p-duration-100) var(--p-ease);
}

.Polaris-Checkbox__Icon svg {
  fill: var(--p-icon-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-Checkbox__Icon {
    fill: windowText;
  }
}

.Polaris-Checkbox--error .Polaris-Checkbox__Icon svg {
  fill: var(--p-icon-on-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover,
.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop:hover {
  border-color: var(--p-border-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop::before {
  background-color: var(--p-border-critical);
}

.Polaris-Checkbox--error
  .Polaris-Checkbox__Input:checked
  + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox--error
  .Polaris-Checkbox__Input:active
  + .Polaris-Checkbox__Backdrop::before,
.Polaris-Checkbox--error
  .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate
  + .Polaris-Checkbox__Backdrop::before {
  background-color: var(--p-border-critical);
}

.Polaris-Listbox-TextOption {
  margin: var(--p-space-1) var(--p-space-2) 0;
  flex: 1 1;
  border-radius: var(--p-border-radius-1);
  padding: var(--p-space-2) var(--p-space-2);
  cursor: pointer;
  display: flex;
  position: relative;
}

.Polaris-Listbox-TextOption::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--allowMultiple {
  margin: var(--p-space-1) var(--p-space-2) 0;
  padding: var(--p-space-1) var(--p-space-2);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--isAction {
  margin-top: 0;
  padding: var(--p-space-2);
}

.Polaris-Listbox-TextOption:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Listbox-TextOption:focus {
  outline: none;
}

.Polaris-Listbox-TextOption:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--selected::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(var(--p-space-1) * -1);
  height: 100%;
  width: var(--p-border-radius-1);
  background-color: var(--p-interactive);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
  transform: translateX(-100%);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--disabled {
  background-color: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: default;
}

li:first-of-type > .Polaris-Listbox-TextOption {
  margin-top: 0;
}

[data-focused]
  .Polaris-Listbox-TextOption:not(.Polaris-Listbox-TextOption--disabled) {
  outline: none;
  background-color: var(--p-surface-selected);
  transition: background-color var(--p-duration-400);
}

.Polaris-Listbox-TextOption__Content {
  flex: 1 1 auto;
  display: flex;
}

.Polaris-Listbox-TextOption__Checkbox {
  pointer-events: none;
}

.Polaris-Listbox-Option {
  display: flex;
  margin: 0;
  padding: 0;
}

.Polaris-Listbox-Option:focus {
  outline: none;
}

.Polaris-Listbox-Option--divider {
  border-bottom: var(--p-border-divider);
}

.Polaris-Listbox-Loading__ListItem {
  padding: 0;
  margin: 0;
}

.Polaris-Listbox-Loading {
  padding: var(--p-space-2) var(--p-space-4);
  display: grid;
  place-items: center;
}

.Polaris-Listbox-Action {
  display: flex;
  flex: 1 1;
}

.Polaris-Listbox-Action__ActionDivider {
  margin-bottom: var(--p-space-1);
}

.Polaris-Listbox-Action__Icon {
  padding-right: var(--p-space-2);
}

.Polaris-Listbox {
  padding: 0;
  margin: 0;
  list-style: none;
  max-width: 100%;
}

.Polaris-Listbox:focus {
  outline: none;
}

.Polaris-Autocomplete-MappedOption__Content {
  display: flex;
  flex: 1 1;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Polaris-Autocomplete-MappedOption__Media {
  padding: 0 var(--p-space-2);
}

.Polaris-Autocomplete-MappedOption__Media svg {
  fill: var(--p-icon);
  color: var(--p-icon-on-interactive);
}

.Polaris-Autocomplete-MappedOption--singleSelectionMedia {
  padding: 0 var(--p-space-2) 0 0;
}

.Polaris-Autocomplete-MappedOption--disabledMedia svg {
  fill: var(--p-icon-disabled);
  color: var(--p-text-on-interactive);
}

.Polaris-Autocomplete-MappedAction__ActionContainer {
  --pc-mapped-actions-image-size: 1.25rem;
  --pc-mapped-actions-item-min-height: var(--p-space-10);
  --pc-mapped-actions-item-vertical-padding: calc(
    (var(--pc-mapped-actions-item-min-height) - var(--p-font-line-height-2)) / 2
  );
  margin-bottom: var(--p-space-3);
}

[data-focused] .Polaris-Autocomplete-MappedAction__Action svg {
  fill: var(--p-interactive);
}

[data-focused]
  .Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Autocomplete-MappedAction__Action {
  position: relative;
  display: block;
  width: 100%;
  min-height: var(--pc-mapped-actions-item-min-height);
  text-align: left;
  cursor: pointer;
  padding: var(--pc-mapped-actions-item-vertical-padding) var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  border-top: var(--p-border-width-1) solid var(--p-surface);
}

.Polaris-Autocomplete-MappedAction__Action::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Autocomplete-MappedAction__Action:hover {
  background-color: var(--p-surface-hovered);
  text-decoration: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Autocomplete-MappedAction__Action:hover {
    outline: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--selected
  svg {
  fill: var(--p-interactive);
}

.Polaris-Autocomplete-MappedAction__Action:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Autocomplete-MappedAction__Action:active svg {
  fill: var(--p-interactive);
}

.Polaris-Autocomplete-MappedAction__Action:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive
  svg {
  fill: var(--p-icon-critical);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive:hover {
  background-color: var(--p-surface-critical-subdued-hovered);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive:active,
.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive.Polaris-Autocomplete-MappedAction--selected {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled {
  background-image: none;
  color: var(--p-text-disabled);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled
  .Polaris-Autocomplete-MappedAction__Prefix
  svg,
.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled
  .Polaris-Autocomplete-MappedAction__Suffix
  svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Autocomplete-MappedAction__Content {
  display: flex;
  align-items: center;
}

.Polaris-Autocomplete-MappedAction__Prefix {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: var(--pc-mapped-actions-image-size);
  width: var(--pc-mapped-actions-image-size);
  border-radius: var(--p-border-radius-1);
  margin: calc(var(--pc-mapped-actions-image-size) * -0.5) var(--p-space-4)
    calc(var(--pc-mapped-actions-image-size) * -0.5) 0;
  background-size: cover;
  background-position: center center;
}

.Polaris-Autocomplete-MappedAction__Prefix svg {
  fill: var(--p-icon);
}

.Polaris-Autocomplete-MappedAction__Suffix {
  margin-left: var(--p-space-4);
}

.Polaris-Autocomplete-MappedAction__Suffix svg {
  fill: var(--p-icon);
}

.Polaris-Autocomplete-MappedAction__Text {
  min-width: 0;
  max-width: 100%;
  flex: 1 1 auto;
}

.Polaris-Autocomplete-MappedAction__Text
  .Polaris-Autocomplete-MappedAction__ContentWrap {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Polaris-Autocomplete__Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--p-space-2) var(--p-space-4);
}

.Polaris-Autocomplete__SectionWrapper > *:not(:first-child) {
  margin-top: var(--p-space-2);
}

[data-lock-scrolling] {
  overflow-y: scroll;
  margin: 0;
}

[data-lock-scrolling][data-lock-scrolling-hidden] {
  overflow-y: hidden;
}

[data-lock-scrolling] [data-lock-scrolling-wrapper] {
  overflow: hidden;
  height: 100%;
}

.Polaris-Backdrop {
  position: fixed;
  z-index: var(--p-z-index-10);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--p-backdrop);
  animation: var(--p-keyframes-fade-in) var(--p-duration-200) 1 forwards;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
}

.Polaris-Backdrop--transparent {
  background-color: transparent;
}

.Polaris-Backdrop--belowNavigation {
  z-index: var(--p-z-index-7);
}

.Polaris-Banner {
  --pc-banner-secondary-action-horizontal-padding: var(--p-space-3);
  --pc-banner-secondary-action-vertical-padding: var(--p-space-2);
  position: relative;
  display: flex;
}

.Polaris-Banner.Polaris-Banner--statusCritical
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Banner.Polaris-Banner--statusCritical
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:hover {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusCritical
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:active {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusCritical
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Banner.Polaris-Banner--statusWarning
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued);
}

.Polaris-Banner.Polaris-Banner--statusWarning
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:hover {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusWarning
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:active {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusWarning
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued);
}

.Polaris-Banner.Polaris-Banner--statusInfo
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued);
}

.Polaris-Banner.Polaris-Banner--statusInfo
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:hover {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusInfo
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:active {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusInfo
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued);
}

.Polaris-Banner.Polaris-Banner--statusSuccess
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued);
}

.Polaris-Banner.Polaris-Banner--statusSuccess
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:hover {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusSuccess
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:active {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusSuccess
  .Polaris-Banner__PrimaryAction.Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued);
}

.Polaris-Banner__ContentWrapper {
  margin-top: calc(var(--p-space-05) * -1);
  flex: 1 1 auto;
}

.Polaris-Banner--withinContentContainer {
  padding: var(--p-space-4);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  background-color: var(--p-background);
  position: relative;
  border-radius: var(--p-border-radius-1);
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Dismiss {
  top: var(--p-space-4);
  right: var(--p-space-3);
  position: absolute;
}

.Polaris-Banner--withinContentContainer::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Banner--withinContentContainer:focus {
  outline: none;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess {
  border-color: var(--p-border-success-subdued);
  background-color: var(--p-surface-success-subdued);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo {
  border-color: var(--p-border-highlight-subdued);
  background-color: var(--p-surface-highlight-subdued);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning {
  border-color: var(--p-border-warning-subdued);
  background-color: var(--p-surface-warning-subdued);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical {
  border-color: var(--p-border-critical-subdued);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-Banner--withinContentContainer + .Polaris-Banner {
  margin-top: var(--p-space-2);
}

.Polaris-Banner--withinPage {
  border-radius: 0 0 var(--p-border-radius-1) var(--p-border-radius-1);
  padding: var(--p-space-5);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  background-color: var(--p-background);
  position: relative;
  border-radius: var(--p-border-radius-2);
}

.Polaris-Banner--withinPage::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-2);
}

@media (max-width: 30.6225em) {
  .Polaris-Banner--withinPage {
    border-radius: 0;
    border-width: var(--p-border-width-1) 0;
  }
}

.Polaris-Banner--withinPage:focus {
  outline: none;
}

.Polaris-Banner--withinPage.Polaris-Banner--keyFocused::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner--withinPage.Polaris-Banner--statusSuccess {
  border-color: var(--p-border-success-subdued);
  background-color: var(--p-surface-success-subdued);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusInfo {
  border-color: var(--p-border-highlight-subdued);
  background-color: var(--p-surface-highlight-subdued);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusWarning {
  border-color: var(--p-border-warning-subdued);
  background-color: var(--p-surface-warning-subdued);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusCritical {
  border-color: var(--p-border-critical-subdued);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-Banner--withinPage + .Polaris-Banner {
  margin-top: var(--p-space-5);
}

.Polaris-Banner--withinPage .Polaris-Banner__Ribbon {
  padding-right: var(--p-space-4);
}

.Polaris-Banner--withinPage .Polaris-Banner__Dismiss {
  right: var(--p-space-4);
  top: var(--p-space-5);
  position: absolute;
}

.Polaris-Banner--hasDismiss {
  padding-right: calc(var(--p-space-8) + var(--p-space-2));
}

.Polaris-Banner__SecondaryAction {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  text-align: left;
  text-decoration: none;
  margin: calc(var(--pc-banner-secondary-action-vertical-padding) * -1)
    calc(var(--pc-banner-secondary-action-horizontal-padding) * -0.5);
  padding: var(--pc-banner-secondary-action-vertical-padding)
    var(--pc-banner-secondary-action-horizontal-padding);
  color: var(--p-text);
  padding-left: var(--p-space-2);
}

.Polaris-Banner__SecondaryAction:focus {
  outline: none;
}

.Polaris-Banner__SecondaryAction:hover > .Polaris-Banner__Text {
  text-decoration: underline;
}

.Polaris-Banner__SecondaryAction:active > .Polaris-Banner__Text {
  text-decoration: underline;
}

.Polaris-Banner__SecondaryAction:focus-visible > .Polaris-Banner__Text {
  padding: var(--p-space-05) var(--p-space-1);
  margin: calc(var(--p-space-05) * -1) calc(var(--p-space-1) * -1);
  background: transparent;
  border-radius: var(--p-border-radius-1);
  box-shadow: none;
  text-decoration: underline;
}

.Polaris-Banner__SecondaryAction:focus-visible > .Polaris-Banner__Text::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

@media (-ms-high-contrast: active) {
  .Polaris-Banner__SecondaryAction:focus-visible > .Polaris-Banner__Text {
    outline: var(--p-border-width-2) dotted;
  }
}

.Polaris-Banner__SecondaryAction:visited {
  color: inherit;
}

.Polaris-Banner__Text {
  position: relative;
}

.Polaris-Banner__Text::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Banner__Button {
  position: relative;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  min-width: 2.25rem;
  margin: 0;
  padding: calc((2.25rem - var(--p-font-line-height-2) - var(--p-space-05)) / 2)
    var(--p-space-4);
  background: var(--p-surface);
  box-shadow: var(--p-shadow-sm);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  font-size: var(--p-font-size-100);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-1);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
}

.Polaris-Banner__Button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  right: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  bottom: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  left: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-1) * -1 + -0.0625rem)
    var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Banner__Button svg {
  fill: var(--p-icon);
}

.Polaris-Banner__Button:hover {
  background: var(--p-action-secondary-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner__Button:focus-visible {
  box-shadow: var(--p-shadow-sm);
  outline: 0;
}

.Polaris-Banner__Button:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner__Button:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-shadow-sm);
}

.Polaris-Banner__Button:active::after {
  border: none;
  box-shadow: none;
}

.Polaris-Banner__Button.Polaris-Banner--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-shadow-inset-md);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Banner__Button.Polaris-Banner--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Banner__Button {
    border: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-Banner__Button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.1875rem;
  right: -0.1875rem;
  bottom: -0.1875rem;
  left: -0.1875rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.1875rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Banner__Button:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner--loading {
  position: relative;
  color: transparent;
  pointer-events: none;
}

.Polaris-Banner__Spinner {
  --pc-spinner-size: var(--p-space-5);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: calc((var(--pc-spinner-size) / 2) * -1);
  margin-left: calc((var(--pc-spinner-size) / 2) * -1);
}

.Polaris-Bleed {
  --pc-bleed-margin-block-start-xs: initial;
  --pc-bleed-margin-block-start-sm: var(--pc-bleed-margin-block-start-xs);
  --pc-bleed-margin-block-start-md: var(--pc-bleed-margin-block-start-sm);
  --pc-bleed-margin-block-start-lg: var(--pc-bleed-margin-block-start-md);
  --pc-bleed-margin-block-start-xl: var(--pc-bleed-margin-block-start-lg);
  --pc-bleed-margin-block-end-xs: initial;
  --pc-bleed-margin-block-end-sm: var(--pc-bleed-margin-block-end-xs);
  --pc-bleed-margin-block-end-md: var(--pc-bleed-margin-block-end-sm);
  --pc-bleed-margin-block-end-lg: var(--pc-bleed-margin-block-end-md);
  --pc-bleed-margin-block-end-xl: var(--pc-bleed-margin-block-end-lg);
  --pc-bleed-margin-inline-start-xs: initial;
  --pc-bleed-margin-inline-start-sm: var(--pc-bleed-margin-inline-start-xs);
  --pc-bleed-margin-inline-start-md: var(--pc-bleed-margin-inline-start-sm);
  --pc-bleed-margin-inline-start-lg: var(--pc-bleed-margin-inline-start-md);
  --pc-bleed-margin-inline-start-xl: var(--pc-bleed-margin-inline-start-lg);
  --pc-bleed-margin-inline-end-xs: initial;
  --pc-bleed-margin-inline-end-sm: var(--pc-bleed-margin-inline-end-xs);
  --pc-bleed-margin-inline-end-md: var(--pc-bleed-margin-inline-end-sm);
  --pc-bleed-margin-inline-end-lg: var(--pc-bleed-margin-inline-end-md);
  --pc-bleed-margin-inline-end-xl: var(--pc-bleed-margin-inline-end-lg);
  margin-block-start: calc(var(--pc-bleed-margin-block-start-xs) * -1);
  margin-block-end: calc(var(--pc-bleed-margin-block-end-xs) * -1);
  margin-inline-start: calc(var(--pc-bleed-margin-inline-start-xs) * -1);
  margin-inline-end: calc(var(--pc-bleed-margin-inline-end-xs) * -1);
}

@media (min-width: 30.625em) {
  .Polaris-Bleed {
    margin-block-start: calc(var(--pc-bleed-margin-block-start-sm) * -1);
    margin-block-end: calc(var(--pc-bleed-margin-block-end-sm) * -1);
    margin-inline-start: calc(var(--pc-bleed-margin-inline-start-sm) * -1);
    margin-inline-end: calc(var(--pc-bleed-margin-inline-end-sm) * -1);
  }
}

@media (min-width: 48em) {
  .Polaris-Bleed {
    margin-block-start: calc(var(--pc-bleed-margin-block-start-md) * -1);
    margin-block-end: calc(var(--pc-bleed-margin-block-end-md) * -1);
    margin-inline-start: calc(var(--pc-bleed-margin-inline-start-md) * -1);
    margin-inline-end: calc(var(--pc-bleed-margin-inline-end-md) * -1);
  }
}

@media (min-width: 65em) {
  .Polaris-Bleed {
    margin-block-start: calc(var(--pc-bleed-margin-block-start-lg) * -1);
    margin-block-end: calc(var(--pc-bleed-margin-block-end-lg) * -1);
    margin-inline-start: calc(var(--pc-bleed-margin-inline-start-lg) * -1);
    margin-inline-end: calc(var(--pc-bleed-margin-inline-end-lg) * -1);
  }
}

@media (min-width: 90em) {
  .Polaris-Bleed {
    margin-block-start: calc(var(--pc-bleed-margin-block-start-xl) * -1);
    margin-block-end: calc(var(--pc-bleed-margin-block-end-xl) * -1);
    margin-inline-start: calc(var(--pc-bleed-margin-inline-start-xl) * -1);
    margin-inline-end: calc(var(--pc-bleed-margin-inline-end-xl) * -1);
  }
}

.Polaris-Breadcrumbs__Breadcrumb {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  min-width: 2.25rem;
  color: var(--p-text-subdued);
  text-decoration: none;
  margin: 0;
  padding: var(--p-space-05);
  border-radius: var(--p-border-radius-1);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  position: relative;
}

.Polaris-Breadcrumbs__Breadcrumb:focus {
  outline: none;
}

.Polaris-Breadcrumbs__Breadcrumb::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Breadcrumbs__Breadcrumb:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Breadcrumbs__Breadcrumb:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Breadcrumbs__Breadcrumb:hover,
.Polaris-Breadcrumbs__Breadcrumb:active {
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Breadcrumbs__Breadcrumb:hover .Polaris-Breadcrumbs__Icon svg,
.Polaris-Breadcrumbs__Breadcrumb:active .Polaris-Breadcrumbs__Icon svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Breadcrumbs__Breadcrumb:focus-visible {
  outline: none;
}

.Polaris-Breadcrumbs__Breadcrumb:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Breadcrumbs__Content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.Polaris-Breadcrumbs__Icon {
  --pc-breadcrumbs-icon-size: 1.25rem;
  width: var(--pc-breadcrumbs-icon-size);
  height: var(--pc-breadcrumbs-icon-size);
  margin: calc(var(--pc-breadcrumbs-icon-size) * -0.5) 0
    calc(var(--pc-breadcrumbs-icon-size) * -0.5) calc(var(--p-space-2) * -1);
  margin: 0;
}

.Polaris-Breadcrumbs__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Indicator {
  --pc-indicator-size: 0.625rem;
  --pc-indicator-base-position: calc(var(--p-space-1) * -1);
}

.Polaris-Indicator::before,
.Polaris-Indicator::after {
  content: "";
  position: absolute;
  background-color: var(--p-border-highlight);
  right: var(--pc-indicator-base-position);
  top: var(--pc-indicator-base-position);
  width: var(--pc-indicator-size);
  height: var(--pc-indicator-size);
  border-radius: var(--p-border-radius-full);
  border: calc(var(--pc-indicator-size) / 2) solid transparent;
}

.Polaris-Indicator--pulseIndicator::before {
  z-index: 1;
  animation: var(--p-keyframes-bounce) var(--p-duration-5000) ease infinite;
}

.Polaris-Indicator--pulseIndicator::after {
  right: var(--pc-indicator-base-position);
  top: var(--pc-indicator-base-position);
  animation: var(--p-keyframes-pulse) var(--p-duration-5000) ease infinite;
}

.Polaris-BulkActions__Group {
  /* stylelint-disable -- polaris: Used to apply dark theme to action buttons */
  --p-surface: var(--p-surface-dark);
  --p-text: var(--p-text-on-dark);
  --p-icon: var(--p-text-on-dark);
  --p-border-neutral-subdued: var(--p-border-on-dark);
  --p-border-subdued: var(--p-border-on-dark);
  --p-border-shadow-subdued: var(--p-border-on-dark);
  --p-action-secondary-hovered: var(--p-surface-hovered-dark);
  --p-action-secondary-pressed: var(
    --p-surface-pressed-dark
  ); /* stylelint-enable */
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-3);
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  opacity: 0;
  width: 100%;
  justify-content: center;
  transition: var(--p-duration-100) var(--p-ease);
  transition-property: transform, opacity;
  transform: translateY(7.5rem);
  padding: 0 var(--p-space-4);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, opacity;
}

@media (min-width: 48em) {
  .Polaris-BulkActions__Group {
    font-size: var(--p-font-size-100);
  }
}

.Polaris-BulkActions__Group.Polaris-BulkActions--groupNotSticky {
  transform: none;
  opacity: 1;
}

.Polaris-BulkActions__Group.Polaris-BulkActions__Group--entering,
.Polaris-BulkActions__Group.Polaris-BulkActions__Group--exiting {
  opacity: 0;
  display: flex;
}

.Polaris-BulkActions__Group.Polaris-BulkActions__Group--entered {
  opacity: 1;
  display: flex;
  transform: translateY(0);
}

.Polaris-BulkActions__Group.Polaris-BulkActions__Group--exited {
  opacity: 0;
  display: none;
}

.Polaris-BulkActions__Group.Polaris-BulkActions__Group--measuring {
  transition: none;
  display: flex;
  opacity: 0;
}

.Polaris-BulkActions__ButtonGroupWrapper {
  width: auto;
  justify-content: flex-start;
  padding: var(--p-space-4);
  background: var(--p-surface);
  border-radius: var(--p-border-radius-2);
  box-shadow: var(--p-shadow-md), var(--p-shadow-xl);
  max-width: 100%;
  pointer-events: auto;
}

@media (max-width: 30.6225em) {
  .Polaris-BulkActions__ButtonGroupWrapper > div > div:first-child {
    flex: 1 1 auto;
  }
}

@media (min-width: 30.625em) {
  .Polaris-BulkActions__ButtonGroupWrapper {
    width: auto;
    justify-content: flex-start;
    margin-right: var(--p-space-2);
    margin-left: calc(var(--p-space-05) * -1);
  }
}

.Polaris-BulkActions__Group--measuring
  .Polaris-BulkActions__ButtonGroupWrapper {
  position: absolute;
  width: auto;
}

.Polaris-BulkActions__BulkActionButton {
  white-space: nowrap;
}

.Polaris-BulkActions__BulkActionButton button {
  display: flex;
}

.Polaris-BulkActions--disabled {
  transition: none;
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: default;
  pointer-events: none;
}

.Polaris-BulkActions--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-LegacyStack {
  --pc-stack-spacing: var(--p-space-4);
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: calc(var(--pc-stack-spacing) * -1);
  margin-left: calc(var(--pc-stack-spacing) * -1);
}

.Polaris-LegacyStack > .Polaris-LegacyStack__Item {
  /* margin-top: var(--pc-stack-spacing); */
  margin-left: var(--pc-stack-spacing);
  padding: 20px;
  flex-direction: column;
  max-width: 100%;
}

.Polaris-LegacyStack--noWrap {
  flex-wrap: nowrap;
}

.Polaris-LegacyStack--spacingNone {
  --pc-stack-spacing: 0;
}

.Polaris-LegacyStack--spacingExtraTight {
  --pc-stack-spacing: var(--p-space-1);
}

.Polaris-LegacyStack--spacingTight {
  --pc-stack-spacing: var(--p-space-2);
}

.Polaris-LegacyStack--spacingBaseTight {
  --pc-stack-spacing: var(--p-space-3);
}

.Polaris-LegacyStack--spacingLoose {
  --pc-stack-spacing: var(--p-space-5);
}

.Polaris-LegacyStack--spacingExtraLoose {
  --pc-stack-spacing: var(--p-space-8);
}

.Polaris-LegacyStack--distributionLeading {
  justify-content: flex-start;
}

.Polaris-LegacyStack--distributionTrailing {
  justify-content: flex-end;
}

.Polaris-LegacyStack--distributionCenter {
  justify-content: center;
}

.Polaris-LegacyStack--distributionEqualSpacing {
  justify-content: space-between;
}

.Polaris-LegacyStack--distributionFill > .Polaris-LegacyStack__Item {
  flex: 1 1 auto;
}

.Polaris-LegacyStack--distributionFillEvenly > .Polaris-LegacyStack__Item {
  flex: 1 1 auto;
}

@supports (
  (min-width: -webkit-fit-content) or (min-width: -moz-fit-content) or
    (min-width: fit-content)
) {
  .Polaris-LegacyStack--distributionFillEvenly > .Polaris-LegacyStack__Item {
    flex: 1 0;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

.Polaris-LegacyStack--alignmentLeading {
  align-items: flex-start;
}

.Polaris-LegacyStack--alignmentTrailing {
  align-items: flex-end;
}

.Polaris-LegacyStack--alignmentCenter {
  align-items: center;
}

.Polaris-LegacyStack--alignmentFill {
  align-items: stretch;
}

.Polaris-LegacyStack--alignmentBaseline {
  align-items: center;
}

.Polaris-LegacyStack--vertical {
  flex-direction: column;
  margin-left: 0;
}

.Polaris-LegacyStack--vertical > .Polaris-LegacyStack__Item {
  margin-inline: auto;
}

.Polaris-LegacyStack__Item {
  flex: 0 0 auto;
  min-width: 0;
}

.Polaris-LegacyStack__Item--fill {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: start;
}

.Polaris-LegacyCard {
  /* display: flex;
  align-items: baseline; */
  background-color: var(--p-surface);
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
  outline: var(--p-border-width-1) solid transparent; /* stylelint-disable-next-line polaris/conventions/selector-disallowed-list -- Temporary override, will be removed in v11 */
}
.polaris-welcomeCard {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 20px;
}
.polaris-card-heading {
  margin-bottom: var(--p-space-2);
  color: var(--font-grey, #777980);
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-LegacyCard + .Polaris-LegacyCard {
  margin-top: var(--p-space-4);
}

@media print {
  .Polaris-LegacyCard + .Polaris-LegacyCard {
    margin-top: calc(var(--p-space-2) * -1);
  }
}

.Polaris-LegacyCard + [class^="Polaris-Card"] {
  margin-top: var(--p-space-4);
}

@media print {
  .Polaris-LegacyCard + [class^="Polaris-Card"] {
    margin-top: calc(var(--p-space-2) * -1);
  }
}

@media (min-width: 30.625em) {
  .Polaris-LegacyCard {
    border-radius: var(--p-border-radius-3);
  }
}

@media print {
  .Polaris-LegacyCard {
    box-shadow: none;
  }
}

.Polaris-LegacyCard--subdued {
  background-color: var(--p-surface-subdued);
}

@media print {
  .Polaris-LegacyCard__Section--hideOnPrint,
  .Polaris-LegacyCard--hideOnPrint {
    display: none !important;
  }
}

.Polaris-LegacyCard__Header {
}

/* @media (min-width: 30.625em) {
  .Polaris-LegacyCard__Header {
    padding: var(--p-space-5) var(--p-space-5) 0;
  }
} */

@media print and (min-width: 30.625em) {
  .Polaris-LegacyCard__Header {
    padding: var(--p-space-2) var(--p-space-4) 0;
  }
}

.Polaris-LegacyCard__Section {
  /* padding: var(--p-space-4); */
}

@media (min-width: 30.625em) {
  .Polaris-LegacyCard__Section {
    /* padding: var(--p-space-5); */
  }
}

.Polaris-LegacyCard__Section + .Polaris-LegacyCard__Section {
  border-top: var(--p-border-divider);
}

@media print {
  .Polaris-LegacyCard__Section + .Polaris-LegacyCard__Section {
    border-top: 0;
  }
}

@media print {
  .Polaris-LegacyCard__Section {
    padding-top: var(--p-space-1);
    padding-bottom: var(--p-space-1);
  }
}

.Polaris-LegacyCard__Section:first-child {
  border-top-left-radius: var(--p-border-radius-2);
  border-top-right-radius: var(--p-border-radius-2);
}

.Polaris-LegacyCard__Section:last-child {
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-LegacyCard__Section--fullWidth {
  padding: var(--p-space-4) 0;
}

@media (min-width: 30.625em) {
  .Polaris-LegacyCard__Section--fullWidth {
    padding: var(--p-space-5) 0;
  }
}

.Polaris-LegacyCard__Section--flush {
  padding: 0;
}

@media (min-width: 30.625em) {
  .Polaris-LegacyCard__Section--flush {
    padding: 0;
  }
}

.Polaris-LegacyCard__Section--subdued {
  background-color: var(--p-surface-subdued);
}

@media (-ms-high-contrast: active) {
  .Polaris-LegacyCard__Section--subdued {
    background-color: transparent;
  }
}

.Polaris-LegacyCard__Header + .Polaris-LegacyCard__Section--subdued {
  border-top: var(--p-border-divider);
  margin-top: var(--p-space-5);
}

.Polaris-LegacyCard__SectionHeader {
  padding-bottom: var(--p-space-2);
}

.Polaris-LegacyCard__Section--fullWidth .Polaris-LegacyCard__SectionHeader {
  padding-left: var(--p-space-4);
  padding-right: var(--p-space-4);
}

@media (min-width: 30.625em) {
  .Polaris-LegacyCard__Section--fullWidth .Polaris-LegacyCard__SectionHeader {
    padding-left: var(--p-space-5);
    padding-right: var(--p-space-5);
  }
}

.Polaris-LegacyCard__Subsection + .Polaris-LegacyCard__Subsection {
  margin-top: var(--p-space-4);
  padding-top: var(--p-space-4);
  border-top: var(--p-border-divider);
}

@media print {
  .Polaris-LegacyCard__Subsection + .Polaris-LegacyCard__Subsection {
    border-top: 0;
  }
}

@media print {
  .Polaris-LegacyCard__Subsection {
    padding-top: var(--p-space-1);
    padding-bottom: var(--p-space-1);
  }
}

.Polaris-LegacyCard__Footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--p-space-4) var(--p-space-4);
}

@media (min-width: 30.625em) {
  .Polaris-LegacyCard__Footer {
    padding: 0 var(--p-space-5) var(--p-space-5);
  }
}

.Polaris-LegacyCard__Footer.Polaris-LegacyCard__LeftJustified {
  justify-content: flex-start;
}

.Polaris-LegacyCard__Section--subdued + .Polaris-LegacyCard__Footer {
  border-top: var(--p-border-divider);
  padding: var(--p-space-5);
}

.Polaris-TextContainer {
  --pc-text-container-spacing: var(--p-space-4);
}

.Polaris-TextContainer > *:not(:first-child) {
  margin-top: var(--pc-text-container-spacing);
}

.Polaris-TextContainer--spacingTight {
  --pc-text-container-spacing: var(--p-space-2);
}

.Polaris-TextContainer--spacingLoose {
  --pc-text-container-spacing: var(--p-space-5);
}

.Polaris-CalloutCard {
  display: flex;
  align-items: center;
}

.Polaris-CalloutCard__Image {
  display: none;
  flex: 0 0 auto;
  width: 6.25rem;
}

@media (min-width: 30.625em) {
  .Polaris-CalloutCard__Image {
    display: block;
    margin-left: var(--p-space-5);
  }
}

.Polaris-CalloutCard__DismissImage {
  margin-right: var(--p-space-5);
}

.Polaris-CalloutCard__Content {
  flex: 1 1 auto;
}

.Polaris-CalloutCard__Title {
  margin-bottom: var(--p-space-5);
}

.Polaris-CalloutCard__Buttons {
  margin-top: var(--p-space-5);
}

.Polaris-CalloutCard__Container {
  position: relative;
}

.Polaris-CalloutCard__Dismiss {
  right: var(--p-space-4);
  top: var(--p-space-4);
  position: absolute;
}

.Polaris-CalloutCard--hasDismiss {
  padding-right: calc(var(--p-space-8) + var(--p-space-2));
}

.Polaris-Caption {
  margin: 0;
  font-size: 0.8125rem;
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-2);
}

@media (min-width: 48em) {
  .Polaris-Caption {
    font-size: var(--p-font-size-75);
    line-height: var(--p-font-line-height-1);
  }
}

.Polaris-Card {
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-md);
  outline: var(--p-border-width-1) solid transparent; /* stylelint-disable-next-line polaris/conventions/selector-disallowed-list -- Temporary override, will be removed in v11 */
}

.Polaris-Card + .Polaris-Card {
  margin-top: var(--p-space-4);
}

@media print {
  .Polaris-Card + .Polaris-Card {
    margin-top: calc(var(--p-space-2) * -1);
  }
}

.Polaris-Card + [class^="Polaris-LegacyCard"] {
  margin-top: var(--p-space-4);
}

@media print {
  .Polaris-Card + [class^="Polaris-LegacyCard"] {
    margin-top: calc(var(--p-space-2) * -1);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card {
    border-radius: var(--p-border-radius-2);
  }
}

@media print {
  .Polaris-Card {
    box-shadow: none;
  }
}

.Polaris-Card--subdued {
  background-color: var(--p-surface-subdued);
}

@media print {
  .Polaris-Card__Section--hideOnPrint,
  .Polaris-Card--hideOnPrint {
    display: none !important;
  }
}

.Polaris-Card__Header {
  padding: var(--p-space-4) var(--p-space-4) 0;
}

@media (min-width: 30.625em) {
  .Polaris-Card__Header {
    padding: var(--p-space-5) var(--p-space-5) 0;
  }
}

@media print and (min-width: 30.625em) {
  .Polaris-Card__Header {
    padding: var(--p-space-2) var(--p-space-4) 0;
  }
}

.Polaris-Card__Section {
  padding: var(--p-space-4);
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section {
    padding: var(--p-space-5);
  }
}

.Polaris-Card__Section + .Polaris-Card__Section {
  border-top: var(--p-border-divider);
}

@media print {
  .Polaris-Card__Section + .Polaris-Card__Section {
    border-top: 0;
  }
}

@media print {
  .Polaris-Card__Section {
    padding-top: var(--p-space-1);
    padding-bottom: var(--p-space-1);
  }
}

.Polaris-Card__Section:first-child {
  border-top-left-radius: var(--p-border-radius-2);
  border-top-right-radius: var(--p-border-radius-2);
}

.Polaris-Card__Section:last-child {
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-Card__Section--fullWidth {
  padding: var(--p-space-4) 0;
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--fullWidth {
    padding: var(--p-space-5) 0;
  }
}

.Polaris-Card__Section--flush {
  padding: 0;
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--flush {
    padding: 0;
  }
}

.Polaris-Card__Section--subdued {
  background-color: var(--p-surface-subdued);
}

@media (-ms-high-contrast: active) {
  .Polaris-Card__Section--subdued {
    background-color: transparent;
  }
}

.Polaris-Card__Header + .Polaris-Card__Section--subdued {
  border-top: var(--p-border-divider);
  margin-top: var(--p-space-5);
}

.Polaris-Card__SectionHeader {
  padding-bottom: var(--p-space-2);
}

.Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
  padding-left: var(--p-space-4);
  padding-right: var(--p-space-4);
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
    padding-left: var(--p-space-5);
    padding-right: var(--p-space-5);
  }
}

.Polaris-Card__Subsection + .Polaris-Card__Subsection {
  margin-top: var(--p-space-4);
  padding-top: var(--p-space-4);
  border-top: var(--p-border-divider);
}

@media print {
  .Polaris-Card__Subsection + .Polaris-Card__Subsection {
    border-top: 0;
  }
}

@media print {
  .Polaris-Card__Subsection {
    padding-top: var(--p-space-1);
    padding-bottom: var(--p-space-1);
  }
}

.Polaris-Card__Footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--p-space-4) var(--p-space-4);
}

@media (min-width: 30.625em) {
  .Polaris-Card__Footer {
    padding: 0 var(--p-space-5) var(--p-space-5);
  }
}

.Polaris-Card__Footer.Polaris-Card__LeftJustified {
  justify-content: flex-start;
}

.Polaris-Card__Section--subdued + .Polaris-Card__Footer {
  border-top: var(--p-border-divider);
  padding: var(--p-space-5);
}

.Polaris-RadioButton {
  position: relative;
  margin: var(--p-space-025);
}

.Polaris-RadioButton__Input {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-RadioButton__Input:focus-visible
  + .Polaris-RadioButton__Backdrop::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-RadioButton__Input:focus-visible
  + .Polaris-RadioButton__Backdrop::after {
  border-radius: var(--p-border-radius-full);
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop::before {
  transition:
    opacity var(--p-duration-150) var(--p-ease),
    transform var(--p-duration-150) var(--p-ease);
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop {
  border-color: var(--p-border-disabled);
  cursor: default;
}

.Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop::before {
  background-color: var(--p-border-disabled);
}

.Polaris-RadioButton__Backdrop {
  --pc-icon-size-small: 0.5rem;
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: var(--p-border-width-2) solid var(--p-border);
  border-radius: var(--p-border-radius-full);
  background-color: var(--p-surface);
  transition: border-color var(--p-duration-100) var(--p-ease);
  position: relative;
}

@media (max-width: 47.9975em) {
  .Polaris-RadioButton__Backdrop {
    --pc-icon-size-small: 0.625rem;
  }
}

.Polaris-RadioButton__Backdrop::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.1);
  transform-origin: 50% 50%;
  height: var(--pc-icon-size-small);
  width: var(--pc-icon-size-small);
  background-color: var(--p-interactive);
  border-radius: var(--p-border-radius-full);
  transition:
    opacity var(--p-duration-100) var(--p-ease),
    transform var(--p-duration-100) var(--p-ease);
}

@media (forced-colors: active) {
  .Polaris-RadioButton__Backdrop::before {
    border: var(--p-border-width-5) solid transparent;
  }
}

.Polaris-RadioButton__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  right: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  bottom: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  left: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-2) * -1 + -0.0625rem)
    var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-RadioButton__Backdrop::after {
  border-radius: var(--p-border-radius-full);
}

.Polaris-RadioButton__Backdrop.Polaris-RadioButton--hover,
.Polaris-RadioButton__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-ChoiceList__ChoiceChildren {
  padding-left: calc(var(--p-space-2) + 1.25rem);
}

.Polaris-Collapsible {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: max-height;
  transition-property: max-height;
  transition-duration: var(--p-duration-100);
  transition-timing-function: var(--p-ease-out);
}

.Polaris-Collapsible--isFullyVD-Closed {
  display: none;
}

@media print {
  .Polaris-Collapsible--expandOnPrint {
    max-height: none !important;
    overflow: visible;
    display: block;
  }
}

.Polaris-ColorPicker {
  --pc-color-picker-size: 10rem;
  --pc-color-picker-dragger-size: 1.125rem;
  --pc-color-picker-z-index: 10;
  --pc-color-picker-adjustments: 20;
  --pc-color-picker-dragger: 30;
  --pc-color-picker-inner-shadow: inset 0 0 0.125rem 0
    var(--p-shadow-color-picker);
  --pc-color-picker-dragger-shadow: inset 0 0.0625rem 0.125rem 0
      var(--p-shadow-color-picker-dragger),
    0 0.0625rem 0.125rem 0 var(--p-shadow-color-picker-dragger);
  -webkit-user-select: none;
  user-select: none;
  display: flex;
}

.Polaris-ColorPicker__MainColor {
  background: repeating-conic-gradient(
      var(--p-surface) 0% 25%,
      var(--p-surface-neutral-subdued) 0% 50%
    )
    50% / var(--p-space-4) var(--p-space-4);
  position: relative;
  overflow: hidden;
  height: var(--pc-color-picker-size);
  width: var(--pc-color-picker-size);
  border-radius: var(--p-border-radius-1);
  cursor: pointer;
}

.Polaris-ColorPicker--fullWidth .Polaris-ColorPicker__MainColor {
  width: auto;
  flex-grow: 1;
}

.Polaris-ColorPicker__MainColor .Polaris-ColorPicker__Dragger {
  right: calc(var(--pc-color-picker-dragger-size) * 0.5);
  margin: 0;
  box-shadow: var(--pc-color-picker-dragger-shadow);
}

.Polaris-ColorPicker__MainColor .Polaris-ColorPicker__ColorLayer {
  border-radius: var(--p-border-radius-1);
}

.Polaris-ColorPicker__MainColor::after,
.Polaris-ColorPicker__MainColor::before {
  content: "";
  position: absolute;
  z-index: var(--pc-color-picker-adjustments);
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: var(--p-border-radius-1);
}

.Polaris-ColorPicker__MainColor::before {
  background: linear-gradient(to right, white, transparent);
}

.Polaris-ColorPicker__MainColor::after {
  background-image: linear-gradient(to top, black, transparent);
  box-shadow: var(--pc-color-picker-inner-shadow);
}

@media (-ms-high-contrast: active) {
  .Polaris-ColorPicker__MainColor {
    outline: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-ColorPicker__Dragger {
  position: relative;
  z-index: var(--pc-color-picker-dragger);
  bottom: calc(var(--pc-color-picker-dragger-size) * 0.5);
  transform: none;
  height: var(--pc-color-picker-dragger-size);
  width: var(--pc-color-picker-dragger-size);
  margin: 0 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  background: transparent;
  border: var(--p-border-radius-1) solid var(--p-surface);
  border-radius: var(--p-border-radius-full);
  pointer-events: none;
  box-shadow: var(--pc-color-picker-dragger-shadow);
}

.Polaris-ColorPicker__HuePicker,
.Polaris-ColorPicker__AlphaPicker {
  position: relative;
  overflow: hidden;
  height: var(--pc-color-picker-size);
  width: var(--p-space-6);
  margin-left: var(--p-space-2);
  border-width: var(--p-border-radius-1);
  border-radius: calc(var(--pc-color-picker-size) * 0.5);
}

.Polaris-ColorPicker__HuePicker::after,
.Polaris-ColorPicker__AlphaPicker::after {
  content: "";
  position: absolute;
  z-index: var(--pc-color-picker-adjustments);
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: calc(var(--pc-color-picker-size) * 0.5);
  box-shadow: var(--pc-color-picker-inner-shadow);
}

@media (-ms-high-contrast: active) {
  .Polaris-ColorPicker__HuePicker,
  .Polaris-ColorPicker__AlphaPicker {
    outline: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-ColorPicker__HuePicker {
  background-image: linear-gradient(
    to bottom,
    red var(--pc-color-picker-dragger-size),
    yellow,
    lime,
    cyan,
    blue,
    magenta,
    red calc(var(--pc-color-picker-size) - var(--pc-color-picker-dragger-size))
  );
}

.Polaris-ColorPicker__AlphaPicker {
  background: repeating-conic-gradient(
      var(--p-surface) 0% 25%,
      var(--p-surface-neutral-subdued) 0% 50%
    )
    50% / var(--p-space-4) var(--p-space-4);
}

.Polaris-ColorPicker__ColorLayer {
  position: absolute;
  z-index: var(--pc-color-picker-z-index);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.Polaris-ColorPicker__Slidable {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.Polaris-Columns {
  --pc-columns-gap-xs: initial;
  --pc-columns-gap-sm: initial;
  --pc-columns-gap-md: initial;
  --pc-columns-gap-lg: initial;
  --pc-columns-gap-xl: initial;
  gap: var(--pc-columns-gap-xs);
  --pc-columns-grid-template-columns-xs: initial;
  --pc-columns-grid-template-columns-sm: initial;
  --pc-columns-grid-template-columns-md: initial;
  --pc-columns-grid-template-columns-lg: initial;
  --pc-columns-grid-template-columns-xl: initial;
  grid-template-columns: var(--pc-columns-grid-template-columns-xs);
  --pc-columns-align-items: initial;
  display: grid;
  align-items: var(--pc-columns-align-items);
}

@media (min-width: 30.625em) {
  .Polaris-Columns {
    gap: var(--pc-columns-gap-sm, var(--pc-columns-gap-xs));
  }
}

@media (min-width: 48em) {
  .Polaris-Columns {
    gap: var(
      --pc-columns-gap-md,
      var(--pc-columns-gap-sm, var(--pc-columns-gap-xs))
    );
  }
}

@media (min-width: 65em) {
  .Polaris-Columns {
    gap: var(
      --pc-columns-gap-lg,
      var(
        --pc-columns-gap-md,
        var(--pc-columns-gap-sm, var(--pc-columns-gap-xs))
      )
    );
  }
}

@media (min-width: 90em) {
  .Polaris-Columns {
    gap: var(
      --pc-columns-gap-xl,
      var(
        --pc-columns-gap-lg,
        var(
          --pc-columns-gap-md,
          var(--pc-columns-gap-sm, var(--pc-columns-gap-xs))
        )
      )
    );
  }
}

@media (min-width: 30.625em) {
  .Polaris-Columns {
    grid-template-columns: var(
      --pc-columns-grid-template-columns-sm,
      var(--pc-columns-grid-template-columns-xs)
    );
  }
}

@media (min-width: 48em) {
  .Polaris-Columns {
    grid-template-columns: var(
      --pc-columns-grid-template-columns-md,
      var(
        --pc-columns-grid-template-columns-sm,
        var(--pc-columns-grid-template-columns-xs)
      )
    );
  }
}

@media (min-width: 65em) {
  .Polaris-Columns {
    grid-template-columns: var(
      --pc-columns-grid-template-columns-lg,
      var(
        --pc-columns-grid-template-columns-md,
        var(
          --pc-columns-grid-template-columns-sm,
          var(--pc-columns-grid-template-columns-xs)
        )
      )
    );
  }
}

@media (min-width: 90em) {
  .Polaris-Columns {
    grid-template-columns: var(
      --pc-columns-grid-template-columns-xl,
      var(
        --pc-columns-grid-template-columns-lg,
        var(
          --pc-columns-grid-template-columns-md,
          var(
            --pc-columns-grid-template-columns-sm,
            var(--pc-columns-grid-template-columns-xs)
          )
        )
      )
    );
  }
}

.Polaris-DataTable {
  --pc-data-table-first-column-width: 9.0625rem;
  position: relative;
  max-width: 100vw;
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-2);
  overflow: hidden;
}

.Polaris-DataTable--condensed .Polaris-DataTable__Navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--p-space-4) var(--p-space-2) 0;
}

@media (min-width: 48em) {
  .Polaris-DataTable--condensed .Polaris-DataTable__Navigation {
    justify-content: flex-end;
  }
}

.Polaris-DataTable__Navigation {
  display: none;
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Navigation {
  padding: var(--p-space-2) var(--p-space-2) 0 var(--p-space-2);
}

.Polaris-DataTable__Pip {
  height: 0.375rem;
  width: 0.375rem;
  background: var(--p-text-subdued);
  border-radius: var(--p-border-radius-1);
}

.Polaris-DataTable__Pip:not(:last-of-type) {
  margin-right: var(--p-space-1);
}

.Polaris-DataTable__Pip--visible {
  background: var(--p-text);
}

.Polaris-DataTable__ScrollContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  background-color: inherit;
}

.Polaris-DataTable__Table {
  width: 100%;
  border-spacing: 0;
}

.Polaris-DataTable__TableRow
  + .Polaris-DataTable__TableRow
  .Polaris-DataTable__Cell {
  border-top: var(--p-border-divider);
}

.Polaris-DataTable__Cell {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  padding: var(--p-space-4);
  white-space: nowrap;
  text-align: left;
  transition: background-color var(--p-duration-200) var(--p-ease-in-out);
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell {
  padding: var(--p-space-2) var(--p-space-4);
}

.Polaris-DataTable__ZebraStripingOnData
  .Polaris-DataTable__TableRow:nth-child(2n + 1)
  .Polaris-DataTable__Cell,
.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__RowCountIsEven
  .Polaris-DataTable__TableRow:nth-child(2n)
  .Polaris-DataTable__Cell,
.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter
  .Polaris-DataTable__TableRow:nth-child(2n)
  .Polaris-DataTable__Cell,
.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter.Polaris-DataTable__RowCountIsEven
  .Polaris-DataTable__TableRow:nth-child(2n + 1)
  .Polaris-DataTable__Cell {
  background: none;
}

.Polaris-DataTable__ZebraStripingOnData
  .Polaris-DataTable__TableRow:nth-child(2n)
  .Polaris-DataTable__Cell,
.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__RowCountIsEven
  .Polaris-DataTable__TableRow:nth-child(2n + 1)
  .Polaris-DataTable__Cell,
.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter
  .Polaris-DataTable__TableRow:nth-child(2n + 1)
  .Polaris-DataTable__Cell,
.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter.Polaris-DataTable__RowCountIsEven
  .Polaris-DataTable__TableRow:nth-child(2n)
  .Polaris-DataTable__Cell {
  background: var(--p-surface-subdued);
}

.Polaris-DataTable__Cell--separate::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: var(--p-border-divider);
}

.Polaris-DataTable__Cell--firstColumn {
  text-align: left;
  white-space: normal;
}

.Polaris-DataTable__Cell--numeric {
  text-align: right;
}

.Polaris-DataTable__Cell--truncated {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: var(--pc-data-table-first-column-width);
}

.Polaris-DataTable__Cell--header {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  border-bottom: var(--p-border-divider);
  border-top: 0;
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--header {
  font-weight: var(--p-font-weight-medium);
  font-size: var(--p-font-size-75);
}

.Polaris-DataTable__Cell--sortable {
  padding: 0;
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--sortable {
  padding: var(--p-space-2) var(--p-space-3);
}

.Polaris-DataTable__IncreasedTableDensity
  .Polaris-DataTable__Cell--sortable:first-child {
  padding-left: var(--p-space-3);
}

.Polaris-DataTable__IncreasedTableDensity
  .Polaris-DataTable__Cell--sortable:last-child {
  padding-right: var(--p-space-3);
}

.Polaris-DataTable__IncreasedTableDensity
  .Polaris-DataTable__Cell--sortable
  .Polaris-DataTable__Heading--left {
  padding-right: 0;
  padding-left: var(--p-space-1);
}

.Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: top;
}

.Polaris-DataTable__Cell--verticalAlignBottom {
  vertical-align: bottom;
}

.Polaris-DataTable__Cell--verticalAlignMiddle {
  vertical-align: middle;
}

.Polaris-DataTable__Cell--verticalAlignBaseline {
  vertical-align: baseline;
}

@media (min-width: 48em) {
  .Polaris-DataTable--hoverable .Polaris-DataTable__Cell--hovered {
    background: var(--p-surface-hovered);
  }
}

.Polaris-DataTable__Icon {
  display: flex;
  align-self: flex-end;
  opacity: 0;
  transition: opacity var(--p-duration-200) var(--p-ease);
}

.Polaris-DataTable__Heading {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: baseline;
  color: var(--p-text);
  transition: color var(--p-duration-200) var(--p-ease);
  cursor: pointer;
  padding: var(--p-space-2);
  margin: var(--p-space-2);
}

.Polaris-DataTable__Heading:focus {
  outline: none;
}

.Polaris-DataTable__Heading::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-DataTable__Heading svg {
  fill: var(--p-icon-disabled);
}

.Polaris-DataTable__StickyHeaderEnabled
  [data-sticky-active]
  .Polaris-DataTable__Heading {
  visibility: hidden;
}

.Polaris-DataTable__StickyHeaderEnabled
  [data-sticky-active]
  .Polaris-DataTable__StickyHeaderWrapper
  .Polaris-DataTable__Heading {
  visibility: visible;
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Heading {
  font-weight: var(--p-font-weight-medium);
  font-size: var(--p-font-size-75);
  padding: 0;
  padding-right: var(--p-space-1);
  margin: 0;
}

.Polaris-DataTable__Heading:hover {
  color: var(--p-interactive-hovered);
}

.Polaris-DataTable__Heading:hover .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Heading:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DataTable__Heading:focus-visible:not(:active)
  .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Heading:focus-visible:not(:active)
  .Polaris-DataTable__Icon
  svg {
  fill: var(--p-icon-disabled);
}

.Polaris-DataTable__Heading--left {
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon svg {
  fill: var(--p-icon);
}

.Polaris-DataTable__Cell--sorted:hover svg {
  fill: var(--p-interactive-hovered);
}

.Polaris-DataTable__Cell--sorted
  .Polaris-DataTable__Heading:focus:not(:active)
  svg {
  fill: var(--p-icon);
}

.Polaris-DataTable__Cell--total {
  font-weight: var(--p-font-weight-semibold);
  background: var(--p-surface-subdued);
  border-bottom: var(--p-border-divider);
}

.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotals
  .Polaris-DataTable__Cell--total {
  background: var(--p-surface-subdued);
}

.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotals.Polaris-DataTable__RowCountIsEven
  .Polaris-DataTable__Cell--total,
.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter
  .Polaris-DataTable__Cell--total {
  background: none;
}

.Polaris-DataTable--cellTotalFooter {
  border-top: var(--p-border-divider);
  border-bottom: none;
}

.Polaris-DataTable--cellTotalFooter:first-child {
  border-bottom-left-radius: var(--p-border-radius-2);
}

.Polaris-DataTable--cellTotalFooter:last-child {
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-DataTable__Footer {
  padding: var(--p-space-4);
  background: var(--p-surface-subdued);
  color: var(--p-text-subdued);
  text-align: center;
  border-top: var(--p-border-divider);
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-DataTable__ZebraStripingOnData .Polaris-DataTable__Footer {
  background: none;
}

.Polaris-DataTable__StickyHeaderEnabled
  .Polaris-DataTable__StickyHeaderWrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  z-index: var(--p-z-index-1);
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyHeaderInner {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  border-spacing: 0;
}

.Polaris-DataTable__StickyHeaderEnabled
  .Polaris-DataTable__StickyHeaderInner:not(
    .Polaris-DataTable__StickyHeaderInner--isSticky
  ) {
  top: -624.9375rem;
  left: -624.9375rem;
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyHeaderTable {
  border-collapse: collapse;
  display: block;
  overflow-x: auto;
  width: 100%;
  scrollbar-width: none;
}

.Polaris-DataTable__StickyHeaderEnabled
  .Polaris-DataTable__StickyHeaderTable::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  height: 0;
  width: 0;
}

.Polaris-DataTable__StickyHeaderEnabled
  .Polaris-DataTable__StickyHeaderTable
  .Polaris-DataTable__FixedFirstColumn {
  bottom: 0;
  top: auto;
}

.Polaris-DataTable__StickyHeaderEnabled
  .Polaris-DataTable__StickyTableHeadingsRow {
  background-color: var(--p-surface);
}

.Polaris-DataTable__StickyHeaderEnabled
  .Polaris-DataTable__StickyHeaderInner--isSticky {
  visibility: visible;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-md);
}

.Polaris-DataTable__FixedFirstColumn {
  position: absolute;
  background: inherit;
  z-index: 3;
  border-spacing: 0;
  top: 0;
  left: 0;
}

@media (max-width: 47.9975em) {
  .Polaris-DataTable__FixedFirstColumn {
    z-index: 1;
  }
}

.Polaris-DataTable__TooltipContent {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Polaris-DatePicker {
  --pc-date-picker-range-end-border-radius: var(--p-border-radius-6);
  position: relative;
}

.Polaris-DatePicker__MonthLayout {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-4) * -1);
}

.Polaris-DatePicker__MonthContainer {
  flex: 1 1 14.375rem;
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-4);
  max-width: calc(100% - var(--p-space-4));
  min-width: 14.375rem;
}

.Polaris-DatePicker__Month {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: none;
  border-spacing: 0;
}

.Polaris-DatePicker__Month--current {
  font-weight: var(--p-font-weight-bold);
}

.Polaris-DatePicker__DayCell {
  width: 14.28571%;
  background: transparent;
  margin: 0;
  padding: 0;
  border-radius: var(--p-border-radius-1);
}

.Polaris-DatePicker__DayCell--inRange {
  border-radius: 0;
}

.Polaris-DatePicker__Day {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: var(--p-space-2);
  background: transparent;
  border: none;
  border-radius: var(--p-border-radius-1);
  outline: none;
  font-size: var(--p-font-size-75);
  text-align: center;
  color: var(--p-text);
  cursor: pointer;
  position: relative;
}

.Polaris-DatePicker__Day:hover {
  background: var(--p-interactive-hovered);
  color: var(--p-text-on-interactive);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DatePicker__Day::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-DatePicker__Day:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DatePicker__Day--today {
  font-weight: var(--p-font-weight-bold);
}

.Polaris-DatePicker__Day--inRange {
  background: var(--p-surface-selected);
  border-radius: 0;
}

@media (-ms-high-contrast: active) {
  .Polaris-DatePicker__Day--inRange {
    -ms-high-contrast-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }
  .Polaris-DatePicker__Day--inRange:hover {
    background-color: HighlightText;
    color: Highlight;
    outline: var(--p-border-width-2) solid Highlight;
  }
}

.Polaris-DatePicker__Day--selected {
  background: var(--p-interactive);
  color: var(--p-text-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-DatePicker__Day--selected {
    -ms-high-contrast-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }
  .Polaris-DatePicker__Day--selected:hover {
    background-color: HighlightText;
    color: Highlight;
    outline: var(--p-border-width-2) solid Highlight;
  }
}

.Polaris-DatePicker__Day--disabled {
  background-color: transparent;
  color: var(--p-text-disabled);
}

.Polaris-DatePicker__Day--disabled:hover {
  background-color: transparent;
  color: var(--p-text-disabled);
}

@media (-ms-high-contrast) {
  .Polaris-DatePicker__Day--disabled {
    -ms-high-contrast-adjust: none;
    color: grayText;
  }
  .Polaris-DatePicker__Day--disabled:hover {
    color: grayText;
    outline: none;
  }
}

.Polaris-DatePicker__Day--disabled:focus::after {
  content: none;
}

.Polaris-DatePicker__EmptyDayCell {
  width: 14.28571%;
  margin: 0;
  padding: 0;
}

.Polaris-DatePicker__Weekday {
  padding: var(--p-space-2);
  background: transparent;
  font-size: var(--p-font-size-75);
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text-subdued);
  text-align: center;
}

.Polaris-DatePicker__Weekday--current {
  font-weight: var(--p-font-weight-bold);
  color: var(--p-text);
}

.Polaris-DatePicker__Header {
  position: absolute;
  top: var(--p-space-4);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Polaris-DatePicker__Title {
  flex: 1 1 auto;
  margin-top: var(--p-space-05);
  padding-bottom: var(--p-space-1);
  text-align: center;
}

.Polaris-DatePicker__Day--firstInRange {
  border-radius: var(--p-border-radius-1);
}

.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange,
.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight {
  border-radius: var(--pc-date-picker-range-end-border-radius) 0 0
    var(--pc-date-picker-range-end-border-radius);
}

.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange::after,
.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight::after {
  border-radius: var(--pc-date-picker-range-end-border-radius) 0 0
    var(--pc-date-picker-range-end-border-radius);
}

.Polaris-DatePicker__Day--lastInRange {
  border-radius: 0 var(--pc-date-picker-range-end-border-radius)
    var(--pc-date-picker-range-end-border-radius) 0;
}

.Polaris-DatePicker__Day--lastInRange::after {
  border-radius: 0 var(--pc-date-picker-range-end-border-radius)
    var(--pc-date-picker-range-end-border-radius) 0;
}

.Polaris-DatePicker__Week {
  margin-bottom: var(--p-space-05);
}

.Polaris-DatePicker__Week
  > .Polaris-DatePicker__Day--inRange:first-child:not(
    .Polaris-DatePicker__Day--firstInRange
  ):not(.Polaris-DatePicker__Day--lastInRange) {
  border-radius: var(--p-border-radius-1) 0 0 var(--p-border-radius-1);
}

.Polaris-DatePicker__Week
  > .Polaris-DatePicker__Day--inRange:last-child:not(
    .Polaris-DatePicker__Day--firstInRange
  ):not(.Polaris-DatePicker__Day--lastInRange) {
  border-radius: 0 var(--p-border-radius-1) var(--p-border-radius-1) 0;
}

.Polaris-DatePicker__Day--inRange::after,
.Polaris-DatePicker__Day--inRange:not(:hover)
  + .Polaris-DatePicker__Day::after {
  border-radius: 0 var(--pc-date-picker-range-end-border-radius)
    var(--pc-date-picker-range-end-border-radius) 0;
}

.Polaris-DescriptionList {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

@media (min-width: 30.625em) {
  .Polaris-DescriptionList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.Polaris-DescriptionList__Term {
  font-weight: var(--p-font-weight-semibold);
  padding: var(--p-space-4) 0 var(--p-space-2);
}

.Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
  padding: var(--p-space-2) 0 var(--p-space-1);
}

@media (min-width: 30.625em) {
  .Polaris-DescriptionList__Term {
    flex: 0 1 25%;
    padding: var(--p-space-4) var(--p-space-4) var(--p-space-4) 0;
  }
  .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
    padding: var(--p-space-2) var(--p-space-2) var(--p-space-2) 0;
  }
  .Polaris-DescriptionList__Description
    + .Polaris-DescriptionList__Term
    + .Polaris-DescriptionList__Description {
    border-top: var(--p-border-divider);
  }
}

.Polaris-DescriptionList__Description {
  margin-left: 0;
  padding: 0 0 var(--p-space-4);
}

.Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
  padding: 0 0 var(--p-space-2);
}

.Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term {
  border-top: var(--p-border-divider);
}

@media (min-width: 30.625em) {
  .Polaris-DescriptionList__Description {
    flex: 1 1 51%;
    padding: var(--p-space-4) 0;
  }
  .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
    padding: var(--p-space-2) 0;
  }
  .Polaris-DescriptionList__Description
    + .Polaris-DescriptionList__Term
    + .Polaris-DescriptionList__Description {
    border-top: var(--p-border-divider);
  }
}

.Polaris-DisplayText {
  margin: 0;
}

.Polaris-DisplayText--sizeSmall {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-3);
}

@media (min-width: 48em) {
  .Polaris-DisplayText--sizeSmall {
    font-size: var(--p-font-size-300);
    line-height: var(--p-font-line-height-4);
  }
}

.Polaris-DisplayText--sizeMedium {
  font-size: 1.3125rem;
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-4);
}

@media (min-width: 48em) {
  .Polaris-DisplayText--sizeMedium {
    font-size: 1.625rem;
    line-height: var(--p-font-line-height-5);
  }
}

.Polaris-DisplayText--sizeLarge {
  font-size: var(--p-font-size-400);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-font-line-height-4);
}

@media (min-width: 48em) {
  .Polaris-DisplayText--sizeLarge {
    font-size: var(--p-font-size-500);
    line-height: var(--p-font-line-height-5);
  }
}

.Polaris-DisplayText--sizeExtraLarge {
  font-size: 1.6875rem;
  font-weight: var(--p-font-weight-semibold);
  line-height: 2.25rem;
}

@media (min-width: 48em) {
  .Polaris-DisplayText--sizeExtraLarge {
    font-size: 2.625rem;
    line-height: 2.75rem;
  }
}

.Polaris-Divider {
  border: 0;
  margin: 0;
  border-block-start: var(--pc-divider-border-style);
}

.Polaris-DropZone-FileUpload {
  padding: var(--p-space-4);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Polaris-DropZone-FileUpload--large {
  padding: var(--p-space-8);
}

.Polaris-DropZone-FileUpload--small {
  padding: var(--p-space-3);
}

.Polaris-DropZone-FileUpload img {
  vertical-align: bottom;
}

.Polaris-DropZone-FileUpload__Action {
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
  border: none;
  border-radius: var(--p-border-radius-1);
  padding: var(--p-space-1) var(--p-space-2);
  margin: 0;
  text-decoration: none;
  color: var(--p-interactive-hovered);
  background: var(--p-surface-selected-pressed);
  font-size: var(--p-font-size-75);
  font-weight: var(--p-font-weight-semibold);
  line-height: 1;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
}

.Polaris-DropZone-FileUpload__Action::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-DropZone-FileUpload__Action:hover {
  color: var(--p-interactive-pressed);
}

.Polaris-DropZone-FileUpload__Action.Polaris-DropZone-FileUpload--disabled {
  transition: none;
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: not-allowed;
  box-shadow: none;
}

.Polaris-DropZone-FileUpload__Action.Polaris-DropZone-FileUpload--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-DropZone-FileUpload__ActionTitle {
  color: var(--p-interactive);
  text-decoration: none;
}

.Polaris-DropZone-FileUpload__ActionTitle:not(
    .Polaris-DropZone-FileUpload__ActionTitle--disabled
  ) {
  cursor: pointer;
}

.Polaris-DropZone-FileUpload__ActionTitle:not(
    .Polaris-DropZone-FileUpload__ActionTitle--disabled
  ):hover,
.Polaris-DropZone-FileUpload__ActionTitle:not(
    .Polaris-DropZone-FileUpload__ActionTitle--disabled
  ):active {
  color: var(--p-interactive-pressed);
  text-decoration: underline;
}

.Polaris-DropZone-FileUpload__ActionTitle--focused {
  text-decoration: underline;
}

.Polaris-DropZone-FileUpload__ActionTitle--disabled {
  color: var(--p-interactive-disabled);
}

.Polaris-DropZone {
  --pc-drop-zone-outline: 29;
  --pc-drop-zone-overlay: 30;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 1px dashed #e0e2e7;
  background: #f9f9fc;
}

.Polaris-DropZone::after {
  content: "";
  position: absolute;
  z-index: var(--pc-drop-zone-outline);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: var(--p-border-width-1) dashed transparent;
  border-radius: var(--p-border-radius-1);
  pointer-events: none;
}

.Polaris-DropZone:not(.Polaris-DropZone--focused)::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transform: scale(1);
  border: var(--p-border-width-1) dashed transparent;
}

.Polaris-DropZone:hover {
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DropZone--hasOutline {
  padding: var(--p-space-025);
}

.Polaris-DropZone--hasOutline::after {
  border-color: var(--p-border-neutral-subdued);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover {
  cursor: pointer;
  background-color: var(--p-surface-subdued);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover::after {
  border-color: var(--p-interactive-hovered);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--focused)::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transform: scale(1);
  /* border: 2px dashed #e0e2e7; */
  border-radius: var(--p-border-radius-2);
  /* border-color: #7441f6; */
}

.Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled) {
  background-color: var(--p-surface-hovered);
}

.Polaris-DropZone--isDisabled {
  cursor: not-allowed;
}

.Polaris-DropZone--isDisabled::after {
  border-color: var(--p-border-disabled);
}

.Polaris-DropZone--sizeLarge {
  min-height: 7.5rem;
}

.Polaris-DropZone--sizeMedium {
  min-height: 6.25rem;
  align-items: center;
}

.Polaris-DropZone--sizeSmall {
  padding: 0;
  align-items: center;
  min-height: 3.125rem;
}

.Polaris-DropZone--measuring {
  visibility: hidden;
  min-height: 0;
}

.Polaris-DropZone__Container {
  position: relative;
  flex: 1 1;
}

.Polaris-DropZone__Container::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-DropZone__Overlay {
  border-radius: var(--p-border-radius-2);
  position: absolute;
  z-index: var(--pc-drop-zone-overlay);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--p-space-4);
  border: var(--p-border-width-1) dashed var(--p-interactive);
  text-align: center;
  color: var(--p-interactive);
  background-color: var(--p-surface-selected);
  pointer-events: none;
}

.Polaris-DropZone--hasError .Polaris-DropZone__Overlay {
  border-color: var(--p-border-critical);
  color: var(--p-text-critical);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-DropZone--sizeSmall .Polaris-DropZone__Overlay {
  padding: 0;
}

.Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled)
  .Polaris-DropZone__Container::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

@media (min-width: 48em) {
  .Polaris-EmptyState--imageContained {
    position: initial;
    width: 100%;
  }
}

.Polaris-Truncate {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Polaris-ExceptionList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ExceptionList__Item {
  position: relative;
  padding-left: var(--p-space-6);
  color: var(--p-text-subdued);
}

.Polaris-ExceptionList__Item + .Polaris-ExceptionList__Item {
  margin-top: var(--p-space-1);
}

.Polaris-ExceptionList__Icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--p-space-5);
  height: var(--p-space-5);
  margin-right: var(--p-space-1);
}

.Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-subdued);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-critical);
}

.Polaris-ExceptionList__Bullet {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: var(--p-border-radius-full);
  background-color: var(--p-icon-subdued);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Bullet {
  background-color: var(--p-icon-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Bullet {
  background-color: var(--p-icon-critical);
}

.Polaris-ExceptionList__Title + .Polaris-ExceptionList__Description::before {
  content: "–";
  margin: 0 var(--p-space-1);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title,
.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title {
  font-weight: var(--p-font-weight-medium);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title {
  color: var(--p-text-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title {
  color: var(--p-text-critical);
}

.Polaris-Tag {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  min-height: 1.75rem;
  padding: 0 var(--p-space-2);
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
}

.Polaris-Tag.Polaris-Tag--disabled {
  transition: none;
  background: var(--p-surface-neutral-disabled);
  color: var(--p-text-disabled);
}

.Polaris-Tag.Polaris-Tag--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Tag.Polaris-Tag--clickable {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  cursor: pointer;
  padding: var(--p-space-1) var(--p-space-2);
  background-color: var(--p-surface-neutral);
  outline: var(--p-border-width-1) solid transparent;
  font-size: 0.8125rem;
  line-height: var(--p-font-line-height-2);
  position: relative;
}

.Polaris-Tag.Polaris-Tag--clickable:focus {
  outline: none;
}

.Polaris-Tag.Polaris-Tag--clickable:hover {
  background: var(--p-surface-neutral-hovered);
}

.Polaris-Tag.Polaris-Tag--clickable::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Tag.Polaris-Tag--clickable:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tag.Polaris-Tag--clickable:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-Tag.Polaris-Tag--clickable:disabled {
  background: var(--p-surface-neutral-disabled);
  cursor: default;
  pointer-events: none;
  color: var(--p-text-disabled);
}

@media (min-width: 30.625em) {
  .Polaris-Tag.Polaris-Tag--clickable {
    padding: var(--p-space-05) var(--p-space-2);
    font-size: var(--p-font-size-75);
  }
}

.Polaris-Tag.Polaris-Tag--removable {
  padding-right: 0;
}

.Polaris-Tag.Polaris-Tag--linkable {
  padding: 0;
}

@media (min-width: 30.625em) {
  .Polaris-Tag {
    min-height: 1.5rem;
  }
}

.Polaris-Tag__TagText {
  font-size: 0.8125rem;
  line-height: var(--p-font-line-height-2);
  min-height: 1.25rem;
  padding: var(--p-space-1) 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

@media (min-width: 30.625em) {
  .Polaris-Tag__TagText {
    font-size: var(--p-font-size-75);
    padding: var(--p-space-05) 0;
  }
}

.Polaris-Tag__Button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 var(--p-space-1);
  border-radius: var(--p-border-radius-full);
  position: relative;
}

.Polaris-Tag__Button:focus {
  outline: none;
}

.Polaris-Tag__Button svg {
  fill: var(--p-icon);
}

.Polaris-Tag__Button:hover {
  background: var(--p-surface-neutral-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tag__Button::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Tag__Button:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tag__Button:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-Tag__Button:disabled {
  cursor: default;
  pointer-events: none;
}

.Polaris-Tag__Button:disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Tag__Button.Polaris-Tag--segmented {
  margin-left: calc(var(--p-space-1) * -1);
}

.Polaris-Tag__Link {
  display: inline-grid;
  color: var(--p-text);
  outline: none;
  border-radius: var(--p-border-radius-1);
  text-decoration: none;
  min-height: 1.25rem;
  padding: 0 var(--p-space-2);
  position: relative;
}

.Polaris-Tag__Link .Polaris-Tag__LinkText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8125rem;
  line-height: var(--p-font-line-height-2);
  padding-top: var(--p-space-1);
  padding-bottom: var(--p-space-1);
}

@media (min-width: 30.625em) {
  .Polaris-Tag__Link .Polaris-Tag__LinkText {
    font-size: var(--p-font-size-75);
    padding-top: var(--p-space-05);
    padding-bottom: var(--p-space-05);
  }
}

.Polaris-Tag__Link::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Tag__Link:focus-visible:not(:active) {
  text-decoration: underline;
}

.Polaris-Tag__Link:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tag__Link:hover {
  background: var(--p-surface-neutral-hovered);
  text-decoration: underline;
}

.Polaris-Tag__Link.Polaris-Tag--segmented:hover {
  background: none;
}

.Polaris-Tag__Link.Polaris-Tag--segmented::after {
  margin-right: var(--p-space-1);
}

.Polaris-Sheet {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-2xl);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Sheet {
    border-left: var(--p-border-base);
  }
}

@media (min-width: 48em) {
  .Polaris-Sheet {
    right: 0;
    width: 23.75rem;
  }
}

.Polaris-Sheet:focus {
  outline: 0;
}

.Polaris-Sheet__Container {
  position: fixed;
  z-index: var(--p-z-index-11);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 48em) {
  .Polaris-Sheet__Container {
    left: auto;
    width: 23.75rem;
  }
}

.Polaris-Sheet__Bottom {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  transition: transform var(--p-duration-300) var(--p-ease);
  transform-origin: bottom;
}

.Polaris-Sheet--enterBottom {
  transform: translateY(100%);
}

.Polaris-Sheet--enterBottomActive {
  transform: translateY(0%);
}

.Polaris-Sheet--exitBottom {
  transform: translateY(0%);
}

.Polaris-Sheet--exitBottomActive {
  transform: translateY(100%);
}

.Polaris-Sheet__Right {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  transition: transform var(--p-duration-300) var(--p-ease);
  transform-origin: right;
}

.Polaris-Sheet--enterRight {
  transform: translateX(100%);
}

.Polaris-Sheet--enterRightActive {
  transform: translateX(0%);
}

.Polaris-Sheet--exitRight {
  transform: translateX(0%);
}

.Polaris-Sheet--exitRightActive {
  transform: translateX(100%);
}

.Polaris-Filters-ConnectedFilterControl {
  --pc-connceted-filter-control-item: 10;
  --pc-connceted-filter-control-focused: 20;
  display: flex;
  flex-grow: 1;
}

.Polaris-Filters-ConnectedFilterControl
  .Polaris-Filters-ConnectedFilterControl__CenterContainer {
  flex: 1 1 auto;
  min-width: 6.25rem;
}

.Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right
  .Polaris-Filters-ConnectedFilterControl__CenterContainer
  * {
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__Item {
  position: relative;
  z-index: var(--pc-connceted-filter-control-item);
}

.Polaris-Filters-ConnectedFilterControl__Item--focused {
  z-index: var(--pc-connceted-filter-control-focused);
}

.Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer {
  position: absolute;
  top: -62.5rem;
  left: -62.5rem;
  display: flex;
  width: 100%;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer > * {
  flex-shrink: 0;
}

.Polaris-Filters-ConnectedFilterControl__CenterContainer
  + .Polaris-Filters-ConnectedFilterControl__RightContainer,
.Polaris-Filters-ConnectedFilterControl__CenterContainer
  + .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  margin-left: var(--p-space-2);
}

.Polaris-Filters-ConnectedFilterControl__RightContainer {
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer
  .Polaris-Filters-ConnectedFilterControl__Item
  > div
  > button {
  margin-right: calc(var(--p-space-025) * -1);
  border-radius: 0;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer
  .Polaris-Filters-ConnectedFilterControl__Item {
  flex-shrink: 0;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer
  .Polaris-Filters-ConnectedFilterControl__Item:first-of-type
  > div
  > button {
  border-top-left-radius: var(--p-border-radius-1);
  border-bottom-left-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__RightContainer.Polaris-Filters-ConnectedFilterControl--queryFieldHidden
  .Polaris-Filters-ConnectedFilterControl__Item:first-of-type
  > div
  > button {
  border-top-left-radius: var(--p-border-radius-1);
  border-bottom-left-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__RightContainerWithoutMoreFilters
  .Polaris-Filters-ConnectedFilterControl__Item:last-child
  > div
  > button {
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer
  .Polaris-Filters-ConnectedFilterControl__Item
  > div
  > button {
  white-space: nowrap;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer.Polaris-Filters-ConnectedFilterControl--onlyButtonVisible
  .Polaris-Filters-ConnectedFilterControl__Item
  > div
  > button {
  border-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__Wrapper {
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer {
  flex-grow: 0;
}

.Polaris-Filters {
  position: relative;
}

.Polaris-Filters__FiltersContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Polaris-Filters__FiltersContainerHeader {
  top: 0;
  width: 100%;
  padding: var(--p-space-4) var(--p-space-5);
  border-bottom: var(--p-border-divider);
  height: 3.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-Filters__FiltersDesktopContainerContent {
  width: 100%;
  height: calc(100% - 7.875rem);
  padding: var(--p-space-2);
}

.Polaris-Filters__FiltersMobileContainerContent {
  width: 100%;
  height: calc(100% - 3.5rem);
  padding: var(--p-space-2);
}

.Polaris-Filters__FiltersContainerFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--p-space-4) var(--p-space-5);
  border-top: var(--p-border-divider);
  height: 4.375rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-Filters__FiltersMobileContainerFooter {
  width: 100%;
  padding: var(--p-space-4) var(--p-space-4);
  height: 4.375rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-Filters__EmptyFooterState {
  border-top: var(--p-border-divider);
  padding-top: var(--p-space-4);
  width: 100%;
  display: flex;
  justify-content: center;
}

.Polaris-Filters__FilterTriggerContainer {
  position: relative;
}

.Polaris-Filters__FilterTrigger {
  width: 100%;
  margin: 0;
  padding: var(--p-space-4) var(--p-space-5);
  color: var(--p-text);
  border-radius: var(--p-border-radius-1);
  background: none;
  border: none;
  outline: none;
  position: relative;
}

.Polaris-Filters__FilterTrigger::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Filters__FilterTrigger:focus {
  box-shadow: none;
}

.Polaris-Filters__FilterTrigger:hover {
  cursor: pointer;
  background-color: var(--p-surface-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Filters__FilterTrigger:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Filters__FilterTriggerTitle {
  font-size: 0.9375rem;
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-Filters__AppliedFilterBadgeContainer {
  padding-top: var(--p-space-1);
  display: flex;
}

.Polaris-Filters--VD-open .Polaris-Filters__AppliedFilterBadgeContainer {
  display: none;
}

.Polaris-Filters__FilterTriggerLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-Filters--VD-open::before,
.Polaris-Filters--VD-open::after {
  content: "";
  position: relative;
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-Filters--VD-open::before {
  top: 0;
}

.Polaris-Filters--VD-open::after {
  bottom: 0;
}

.Polaris-Filters--VD-open.Polaris-Filters--first::after {
  content: "";
  bottom: 0;
  position: relative;
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-Filters--VD-open.Polaris-Filters--first::before {
  display: none;
}

.Polaris-Filters--VD-open ~ .Polaris-Filters--VD-open::before {
  display: none;
}

.Polaris-Filters--VD-open.Polaris-Filters--last::before {
  content: "";
  top: 0;
  position: relative;
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-Filters--VD-open.Polaris-Filters--last::after {
  display: none;
}

.Polaris-Filters--VD-open + .Polaris-Filters--last::before {
  display: none;
}

.Polaris-Filters__FilterNodeContainer {
  padding: var(--p-space-2) var(--p-space-5) var(--p-space-5) var(--p-space-5);
}

.Polaris-Filters__SearchIcon {
  fill: currentColor;
}

.Polaris-Filters__Backdrop {
  position: fixed;
  z-index: var(--p-z-index-10);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  opacity: 0;
}

.Polaris-Filters__HelpText {
  margin-top: var(--p-space-2);
}

.Polaris-Filters__TagsContainer {
  display: flex;
  padding-top: var(--p-space-2);
  flex-wrap: wrap;
}

.Polaris-Filters__TagsContainer > * {
  margin-right: var(--p-space-2);
  margin-bottom: var(--p-space-2);
}

.Polaris-FooterHelp {
  display: flex;
  justify-content: center;
  margin: var(--p-space-5) 0;
  width: 100%;
}

@media (min-width: 30.625em) {
  .Polaris-FooterHelp {
    margin: var(--p-space-5);
    width: auto;
  }
}

.Polaris-FooterHelp__Text {
  font-size: var(--p-font-size-100);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-2);
  border: none;
  text-transform: initial;
  letter-spacing: initial;
}

.Polaris-FormLayout {
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-5) * -1);
}

.Polaris-FormLayout__Title {
  margin-bottom: calc(var(--p-space-2) * -1);
  padding: var(--p-space-4) var(--p-space-5) 0;
}

.Polaris-FormLayout__Items {
  display: flex;
  flex-wrap: wrap;
}

.Polaris-FormLayout__Item {
  flex: 1 1 13.75rem;
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-5);
  max-width: calc(100% - var(--p-space-5));
}

.Polaris-FormLayout--grouped .Polaris-FormLayout__Item {
  min-width: 13.75rem;
}

.Polaris-FormLayout--condensed .Polaris-FormLayout__Item {
  flex-basis: 6.875rem;
  min-width: 6.875rem;
}

.Polaris-Frame-Toast {
  display: inline-flex;
  max-width: 31.25rem;
  padding: var(--p-space-2) var(--p-space-3);
  border-radius: var(--p-border-radius-1);
  background: var(--p-surface-dark);
  color: var(--p-text-on-interactive);
  margin-bottom: var(--p-space-5);
  box-shadow: var(--p-shadow-xl);
}

@media (min-width: 30.625em) {
  .Polaris-Frame-Toast {
    padding: var(--p-space-3);
  }
}

@media (forced-colors: active) {
  .Polaris-Frame-Toast {
    border: var(--p-border-width-2) solid transparent;
  }
}

.Polaris-Frame-Toast__Action {
  margin-left: var(--p-space-2);
  color: var(--p-text-on-interactive);
}

.Polaris-Frame-Toast--error {
  background: var(--p-action-critical);
  color: var(--p-text-on-critical);
}

.Polaris-Frame-Toast--error .Polaris-Frame-Toast__VD-CloseButton {
  color: var(--p-icon-on-critical);
}

.Polaris-Frame-Toast__LeadingIcon {
  margin-right: var(--p-space-2);
}

.Polaris-Frame-Toast__LeadingIcon svg {
  fill: currentColor;
}

.Polaris-Frame-Toast__VD-CloseButton {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  color: var(--p-icon-subdued);
  cursor: pointer;
  margin-left: var(--p-space-2);
}

.Polaris-Frame-Toast__VD-CloseButton svg {
  fill: currentColor;
}

.Polaris-Frame-Toast__VD-CloseButton:focus {
  outline: none;
}

.Polaris-Frame-Toast__VD-CloseButton:focus,
.Polaris-Frame-Toast__VD-CloseButton:hover {
  color: var(--p-text-on-interactive);
}

:root {
  --pc-toast-manager-translate-y-out: 9.375rem;
  --pc-toast-manager-translate-y-in: 0;
}

.Polaris-Frame-ToastManager {
  position: fixed;
  z-index: var(--p-z-index-12);
  right: 0;
  left: 0;
  text-align: center;
  bottom: var(--pc-frame-global-ribbon-height);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Polaris-Frame-ToastManager__ToastWrapper {
  position: absolute;
  display: inline-flex;
  opacity: 0;
  transition:
    transform var(--p-duration-400) ease,
    opacity var(--p-duration-400) ease;
  transform: translateY(var(--pc-toast-manager-translate-y-out));
}

.Polaris-Frame-ToastManager__ToastWrapper--enter,
.Polaris-Frame-ToastManager__ToastWrapper--exit {
  transform: translateY(var(--pc-toast-manager-translate-y-out));
  opacity: 0;
}

.Polaris-Frame-ToastManager--toastWrapperEnterDone {
  transform: translateY(var(--pc-toast-manager-translate-y-in));
  opacity: 1;
}

.Polaris-Frame-Loading {
  overflow: hidden;
  height: 0.1875rem;
  background-color: var(--p-surface);
  opacity: 1;
}

.Polaris-Frame-Loading__Level {
  width: 100%;
  height: 100%;
  transform-origin: 0;
  background-color: var(--p-icon-success);
  transition: transform var(--p-duration-500) linear;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Frame-Loading__Level {
    background-color: highlight;
  }
}

.Polaris-Modal-Dialog__Container {
  position: fixed;
  z-index: var(--p-z-index-11);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
}

@media (min-width: 48em) {
  .Polaris-Modal-Dialog__Container {
    justify-content: center;
  }
}

.Polaris-Modal-Dialog:focus {
  outline: 0;
}

.Polaris-Modal-Dialog__Modal {
  pointer-events: initial;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 3.75rem);
  background: var(--p-surface);
  box-shadow: var(--p-shadow-2xl);
}

@media (forced-colors: active) {
  .Polaris-Modal-Dialog__Modal {
    border: var(--p-border-width-1) solid transparent;
  }
}

@media (max-width: 47.9975em) {
  .Polaris-Modal-Dialog__Modal {
    bottom: 0;
    max-height: 100%;
  }
}

@media (min-width: 48em) {
  .Polaris-Modal-Dialog__Modal {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    border-radius: var(--p-border-radius-2);
  }
}

@media (min-width: 48em) and (min-height: 41.25em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--limitHeight {
    max-height: 37.5rem;
  }
}

@media (min-width: 48em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
    max-width: calc(100% - var(--p-space-16));
  }
}

@media (min-width: 30.625em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
    max-width: 23.75rem;
  }
}

@media (min-width: 48em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
    max-width: calc(100% - var(--p-space-16));
  }
}

@media (min-width: 65em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
    max-width: 61.25rem;
  }
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--fullScreen {
  height: 100%;
}

@media (min-width: 48em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--fullScreen {
    height: unset;
  }
}

.Polaris-Modal-Dialog--animateFadeUp {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, opacity;
  opacity: 1;
  transform: translateY(0);
  transition:
    transform var(--p-ease) var(--p-duration-200),
    opacity var(--p-ease) var(--p-duration-200);
}

.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entering,
.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exiting,
.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exited {
  opacity: 0;
  transform: translateY(12.5rem);
}

.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entered {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-Modal-VD-CloseButton {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  margin-left: var(--p-space-5);
  margin-right: calc(var(--p-space-2) * -1);
  padding: var(--p-space-2);
  border-radius: var(--p-border-radius-2);
}

.Polaris-Modal-VD-CloseButton:focus {
  outline: none;
}

.Polaris-Modal-VD-CloseButton::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Modal-VD-CloseButton:hover {
  background: var(--p-surface-hovered);
}

.Polaris-Modal-VD-CloseButton:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Modal-VD-CloseButton:active,
.Polaris-Modal-VD-CloseButton.Polaris-Modal-VD-CloseButton--pressed {
  background: var(--p-surface-pressed);
}

.Polaris-Modal-VD-CloseButton:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  /* outline: var(--p-border-width-1) solid transparent; */
}

.Polaris-Modal-VD-CloseButton.Polaris-Modal-VD-CloseButton--titleHidden {
  margin: var(--p-space-2);
}

.Polaris-Modal-Section {
  flex: 0 0 auto;
}

.Polaris-Modal-Section:not(:last-of-type) {
  border-bottom: var(--p-border-divider);
}

.Polaris-Modal-Section--titleHidden {
  padding-right: calc(var(--p-space-12) + var(--p-space-1));
}

.Polaris-Modal__Body {
  width: 100%;
  scrollbar-width: none;
}

.Polaris-Modal__IFrame {
  display: block;
  width: 38.75rem;
  max-width: 100vw;
  border: none;
}

@media (min-width: 48em) {
  .Polaris-Modal__IFrame {
    max-width: 38.75rem;
  }
}

.Polaris-Frame-ContextualSaveBar {
  /* stylelint-disable -- polaris: Used to apply dark theme to action buttons */
  --p-surface: var(--p-surface-dark);
  --p-text: var(--p-text-on-dark);
  --p-action-secondary-hovered: var(--p-action-secondary-hovered-dark);
  --p-action-secondary-pressed: var(
    --p-action-secondary-pressed-dark
  ); /* stylelint-enable */
  display: flex;
  height: 3.5rem;
  background: var(--p-surface-dark);
  box-shadow: 0 0.125rem 0.25rem var(--p-hint-from-direct-light);
}

.Polaris-Frame-ContextualSaveBar
  .Polaris-Frame-ContextualSaveBar__LogoContainer {
  border-right: none;
}

.Polaris-Frame-ContextualSaveBar
  .Polaris-Frame-ContextualSaveBar__ContextControl {
  opacity: 0.3;
  pointer-events: none;
}

@media (forced-colors: active) {
  .Polaris-Frame-ContextualSaveBar {
    border: var(--p-border-width-1) solid transparent;
  }
}

.Polaris-Frame-ContextualSaveBar__LogoContainer {
  display: none;
}

@media (min-width: 48em) {
  .Polaris-Frame-ContextualSaveBar__LogoContainer {
    display: flex;
    flex: 0 0 15rem;
    align-items: center;
    height: 100%;
    padding: 0 var(--p-space-4);
    background-color: transparent;
  }
}

.Polaris-Frame-ContextualSaveBar__Contents {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
  min-width: 0.0625rem;
  max-width: 62.375rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 var(--p-space-4);
}

@media (min-width: 30.625em) {
  .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 var(--p-space-5);
  }
}

@media (min-width: 48em) {
  .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 var(--p-space-8);
  }
}

.Polaris-Frame-ContextualSaveBar--fullWidth {
  max-width: none;
  padding: 0 var(--p-space-4);
}

.Polaris-Frame-ContextualSaveBar__ActionContainer {
  flex-shrink: 0;
}

.Polaris-Frame-ContextualSaveBar__Action {
  margin-left: var(--p-space-2);
}

.Polaris-Frame-ContextualSaveBar__ContextControl {
  display: none;
}

@media (min-width: 48em) {
  .Polaris-Frame-ContextualSaveBar__ContextControl {
    display: block;
    width: 15rem;
  }
}

.Polaris-Frame-CSSAnimation--startFade {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
  transition: opacity var(--p-duration-300) var(--p-ease-out);
  pointer-events: none;
}

.Polaris-Frame-CSSAnimation--endFade {
  opacity: 1;
  pointer-events: auto;
}

.Polaris-Frame {
  --pc-frame-button-size: var(--p-space-8);
  width: 100%;
  display: flex;
  background-color: var(--p-background);
}

@media print {
  .Polaris-Frame {
    background-color: transparent;
  }
}

@media (min-width: 48em) {
  .Polaris-Frame {
    width: calc(100% - var(--pc-frame-offset));
    margin-left: var(--pc-frame-offset);
  }
}

.Polaris-Frame__Navigation {
  position: fixed;
  z-index: var(--p-z-index-8);
  top: 0;
  left: 0;
  display: none;
  flex: 0 0 auto;
  align-items: stretch;
  height: 100%;
  outline: none;
  transform: translateX(0%);
}

@media print {
  .Polaris-Frame__Navigation {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .Polaris-Frame__Navigation {
    z-index: 1;
    left: var(--pc-frame-offset);
    display: flex;
  }
  .Polaris-Frame--hasTopBar .Polaris-Frame__Navigation {
    top: 3.5rem;
    height: calc(100% - 3.5rem);
  }
}

.Polaris-Frame__Navigation:focus {
  outline: none;
}

.Polaris-Frame__Navigation--enter,
.Polaris-Frame__Navigation--enterActive,
.Polaris-Frame__Navigation--exit,
.Polaris-Frame__Navigation--exitActive {
  display: flex;
}

.Polaris-Frame__Navigation--enter {
  transform: translateX(-100%);
}

.Polaris-Frame__Navigation--enterActive {
  transform: translateX(0%);
  transition: transform var(--p-duration-300) var(--p-ease-out);
}

.Polaris-Frame__Navigation--exit {
  transform: translateX(0%);
}

.Polaris-Frame__Navigation--exitActive {
  transform: translateX(-100%);
  transition: transform var(--p-duration-300) var(--p-ease-out);
}

.Polaris-Frame__NavigationDismiss {
  position: relative;
  position: absolute;
  top: 0;
  left: 100%;
  width: var(--pc-frame-button-size);
  height: var(--pc-frame-button-size);
  margin: var(--p-space-4);
  padding: 0;
  border: none;
  border-radius: var(--p-border-radius-full);
  background: none;
  opacity: 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
  cursor: pointer;
  transition: opacity var(--p-duration-100) var(--p-ease);
}

.Polaris-Frame__NavigationDismiss::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Frame__NavigationDismiss svg {
  fill: var(--p-surface);
}

@media print {
  .Polaris-Frame__NavigationDismiss {
    display: none !important;
  }
}

.Polaris-Frame__Navigation--visible .Polaris-Frame__NavigationDismiss {
  pointer-events: all;
  opacity: 1;
}

.Polaris-Frame__NavigationDismiss:focus {
  position: absolute;
  border-radius: var(--p-border-radius-1);
  outline: none;
}

.Polaris-Frame__NavigationDismiss:focus::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

@media (min-width: 48em) {
  .Polaris-Frame__NavigationDismiss {
    display: none;
  }
}

.Polaris-Frame__NavigationDismiss:hover {
  background-color: var(--p-overlay);
}

.Polaris-Frame__NavigationDismiss:active {
  background-color: var(--p-overlay);
}

.Polaris-Frame__TopBar {
  position: fixed;
  z-index: var(--p-z-index-4);
  top: 0;
  left: 0;
  width: 100%;
  height: 3.5rem;
  background-color: var(--body-primary-primary-50);
}

@media print {
  .Polaris-Frame__TopBar {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .Polaris-Frame__TopBar {
    left: var(--pc-frame-offset);
  }
}

.Polaris-Frame__ContextualSaveBar {
  position: fixed;
  z-index: var(--p-z-index-5);
  top: 0;
  left: 0;
  width: 100%;
}

@media (min-width: 48em) {
  .Polaris-Frame__ContextualSaveBar {
    left: var(--pc-frame-offset);
    width: calc(100% - var(--pc-frame-offset));
  }
}

.Polaris-Frame__Main {
  flex: 1 1;
  display: flex;
  align-items: stretch;
  min-width: 0;
  max-width: 100%;
  padding-right: 0;
  padding-right: calc(constant(safe-area-inset-right));
  padding-right: calc(env(safe-area-inset-right));
  padding-left: 0;
  padding-left: calc(constant(safe-area-inset-left));
  padding-left: calc(env(safe-area-inset-left));
  padding-bottom: 0;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
}

@media (min-width: 48em) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 15rem;
    padding-left: 240px;
    padding-left: calc(15rem + constant(safe-area-inset-left));
    padding-left: calc(15rem + env(safe-area-inset-left));
  }
}

@media print and (min-width: 48em) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 0;
  }
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 3.5rem;
}

@media print {
  .Polaris-Frame--hasTopBar .Polaris-Frame__Main {
    padding-top: 0;
  }
}

.Polaris-Frame__Content {
  position: relative;
  padding-bottom: var(--pc-frame-global-ribbon-height, 0);
  flex: 1 1;
  min-width: 0;
  max-width: 100%;
}

.Polaris-Frame__GlobalRibbonContainer {
  position: fixed;
  z-index: var(--p-z-index-3);
  bottom: 0;
  width: 100%;
}

@media (min-width: 48em) {
  .Polaris-Frame__GlobalRibbonContainer {
    left: var(--pc-frame-offset);
  }
  .Polaris-Frame--hasNav .Polaris-Frame__GlobalRibbonContainer {
    left: calc(15rem + var(--pc-frame-offset));
    left: calc(240px + var(--pc-frame-offset));
    left: calc(15rem + var(--pc-frame-offset) + constant(safe-area-inset-left));
    left: calc(15rem + var(--pc-frame-offset) + env(safe-area-inset-left));
    width: calc(100% - 15rem - var(--pc-frame-offset));
  }
}

.Polaris-Frame__LoadingBar {
  position: fixed;
  z-index: var(--p-z-index-6);
  top: 0;
  right: 0;
  left: 0;
}

@media print {
  .Polaris-Frame__LoadingBar {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .Polaris-Frame--hasNav .Polaris-Frame__LoadingBar {
    left: var(--pc-frame-offset);
  }
  .Polaris-Frame--hasTopBar .Polaris-Frame__LoadingBar {
    z-index: var(--p-z-index-6);
  }
}

.Polaris-Frame__Skip {
  --pc-frame-skip-vertical-offset: 0.625rem;
  position: fixed;
  z-index: var(--p-z-index-9);
  top: var(--pc-frame-skip-vertical-offset);
  left: calc(var(--p-space-2) + var(--pc-frame-offset));
  opacity: 0;
  pointer-events: none;
}

.Polaris-Frame__Skip.Polaris-Frame--focused {
  pointer-events: all;
  opacity: 1;
}

.Polaris-Frame__Skip.Polaris-Frame--focused > a::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Frame__Skip > a {
  position: relative;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  min-width: 2.25rem;
  margin: 0;
  padding: calc((2.25rem - var(--p-font-line-height-2) - var(--p-space-05)) / 2)
    var(--p-space-4);
  background: var(--p-surface);
  box-shadow: var(--p-shadow-sm);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  font-size: var(--p-font-size-100);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-1);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
}

.Polaris-Frame__Skip > a::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  right: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  bottom: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  left: calc(var(--p-border-width-1) * -1 + -0.0625rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-1) * -1 + -0.0625rem)
    var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Frame__Skip > a svg {
  fill: var(--p-icon);
}

.Polaris-Frame__Skip > a:hover {
  background: var(--p-action-secondary-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Frame__Skip > a:focus-visible {
  box-shadow: var(--p-shadow-sm);
  outline: 0;
}

.Polaris-Frame__Skip > a:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Frame__Skip > a:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-shadow-sm);
}

.Polaris-Frame__Skip > a:active::after {
  border: none;
  box-shadow: none;
}

.Polaris-Frame__Skip > a.Polaris-Frame--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-shadow-inset-md);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Frame__Skip > a.Polaris-Frame--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Frame__Skip > a {
    border: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-Frame__Skip > a::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Frame__Skip > a:focus {
  border-color: none;
  box-shadow: none;
}

.Polaris-FullscreenBar {
  position: relative;
  display: flex;
  height: 3.5rem;
  box-shadow: var(--p-shadow-sm);
  background-color: var(--p-surface);
}

.Polaris-FullscreenBar__BackAction {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  padding-left: var(--p-space-3);
  padding-right: var(--p-space-3);
  border-width: 0;
  border-right: var(--p-border-base);
  background-color: var(--p-surface);
  font-weight: var(--p-font-weight-medium);
  cursor: pointer;
}

.Polaris-FullscreenBar__BackAction :first-child {
  padding-right: var(--p-space-05);
}

.Polaris-Grid-Cell {
  --pc-row-xs: initial;
  --pc-row-sm: var(--pc-row-xs);
  --pc-row-md: var(--pc-row-sm);
  --pc-row-lg: var(--pc-row-md);
  --pc-row-xl: var(--pc-row-lg);
  --pc-column-xs: initial;
  --pc-column-sm: var(--pc-column-xs);
  --pc-column-md: var(--pc-column-sm);
  --pc-column-lg: var(--pc-column-md);
  --pc-column-xl: var(--pc-column-lg);
  min-width: 0;
  grid-row: var(--pc-row-xs);
  grid-column: var(--pc-column-xs);
}

@media (min-width: 30.625em) {
  .Polaris-Grid-Cell {
    grid-row: var(--pc-row-sm);
    grid-column: var(--pc-column-sm);
  }
}

@media (min-width: 48em) {
  .Polaris-Grid-Cell {
    grid-row: var(--pc-row-md);
    grid-column: var(--pc-column-md);
  }
}

@media (min-width: 65em) {
  .Polaris-Grid-Cell {
    grid-row: var(--pc-row-lg);
    grid-column: var(--pc-column-lg);
  }
}

@media (min-width: 90em) {
  .Polaris-Grid-Cell {
    grid-row: var(--pc-row-xl);
    grid-column: var(--pc-column-xl);
  }
}

.Polaris-Grid-Cell--cell_1ColumnXs {
  grid-column-end: span 1;
}

.Polaris-Grid-Cell--cell_2ColumnXs {
  grid-column-end: span 2;
}

.Polaris-Grid-Cell--cell_3ColumnXs {
  grid-column-end: span 3;
}

.Polaris-Grid-Cell--cell_4ColumnXs {
  grid-column-end: span 4;
}

.Polaris-Grid-Cell--cell_5ColumnXs {
  grid-column-end: span 5;
}

.Polaris-Grid-Cell--cell_6ColumnXs {
  grid-column-end: span 6;
}

@media (min-width: 30.625em) {
  .Polaris-Grid-Cell--cell_1ColumnSm {
    grid-column-end: span 1;
  }
  .Polaris-Grid-Cell--cell_2ColumnSm {
    grid-column-end: span 2;
  }
  .Polaris-Grid-Cell--cell_3ColumnSm {
    grid-column-end: span 3;
  }
  .Polaris-Grid-Cell--cell_4ColumnSm {
    grid-column-end: span 4;
  }
  .Polaris-Grid-Cell--cell_5ColumnSm {
    grid-column-end: span 5;
  }
  .Polaris-Grid-Cell--cell_6ColumnSm {
    grid-column-end: span 6;
  }
}

@media (min-width: 48em) {
  .Polaris-Grid-Cell--cell_1ColumnMd {
    grid-column-end: span 1;
  }
  .Polaris-Grid-Cell--cell_2ColumnMd {
    grid-column-end: span 2;
  }
  .Polaris-Grid-Cell--cell_3ColumnMd {
    grid-column-end: span 3;
  }
  .Polaris-Grid-Cell--cell_4ColumnMd {
    grid-column-end: span 4;
  }
  .Polaris-Grid-Cell--cell_5ColumnMd {
    grid-column-end: span 5;
  }
  .Polaris-Grid-Cell--cell_6ColumnMd {
    grid-column-end: span 6;
  }
}

@media (min-width: 65em) {
  .Polaris-Grid-Cell--cell_1ColumnLg {
    grid-column-end: span 1;
  }
  .Polaris-Grid-Cell--cell_2ColumnLg {
    grid-column-end: span 2;
  }
  .Polaris-Grid-Cell--cell_3ColumnLg {
    grid-column-end: span 3;
  }
  .Polaris-Grid-Cell--cell_4ColumnLg {
    grid-column-end: span 4;
  }
  .Polaris-Grid-Cell--cell_5ColumnLg {
    grid-column-end: span 5;
  }
  .Polaris-Grid-Cell--cell_6ColumnLg {
    grid-column-end: span 6;
  }
  .Polaris-Grid-Cell--cell_7ColumnLg {
    grid-column-end: span 7;
  }
  .Polaris-Grid-Cell--cell_8ColumnLg {
    grid-column-end: span 8;
  }
  .Polaris-Grid-Cell--cell_9ColumnLg {
    grid-column-end: span 9;
  }
  .Polaris-Grid-Cell--cell_10ColumnLg {
    grid-column-end: span 10;
  }
  .Polaris-Grid-Cell--cell_11ColumnLg {
    grid-column-end: span 11;
  }
  .Polaris-Grid-Cell--cell_12ColumnLg {
    grid-column-end: span 12;
  }
}

@media (min-width: 90em) {
  .Polaris-Grid-Cell--cell_1ColumnXl {
    grid-column-end: span 1;
  }
  .Polaris-Grid-Cell--cell_2ColumnXl {
    grid-column-end: span 2;
  }
  .Polaris-Grid-Cell--cell_3ColumnXl {
    grid-column-end: span 3;
  }
  .Polaris-Grid-Cell--cell_4ColumnXl {
    grid-column-end: span 4;
  }
  .Polaris-Grid-Cell--cell_5ColumnXl {
    grid-column-end: span 5;
  }
  .Polaris-Grid-Cell--cell_6ColumnXl {
    grid-column-end: span 6;
  }
  .Polaris-Grid-Cell--cell_7ColumnXl {
    grid-column-end: span 7;
  }
  .Polaris-Grid-Cell--cell_8ColumnXl {
    grid-column-end: span 8;
  }
  .Polaris-Grid-Cell--cell_9ColumnXl {
    grid-column-end: span 9;
  }
  .Polaris-Grid-Cell--cell_10ColumnXl {
    grid-column-end: span 10;
  }
  .Polaris-Grid-Cell--cell_11ColumnXl {
    grid-column-end: span 11;
  }
  .Polaris-Grid-Cell--cell_12ColumnXl {
    grid-column-end: span 12;
  }
}

.Polaris-Grid {
  --pc-grid-areas-xs: initial;
  --pc-grid-areas-sm: var(--pc-grid-areas-xs);
  --pc-grid-areas-md: var(--pc-grid-areas-sm);
  --pc-grid-areas-lg: var(--pc-grid-areas-md);
  --pc-grid-areas-xl: var(--pc-grid-areas-lg);
  --pc-grid-columns-xs: 6;
  --pc-grid-columns-sm: var(--pc-grid-columns-xs);
  --pc-grid-columns-md: var(--pc-grid-columns-sm);
  --pc-grid-columns-lg: 12;
  --pc-grid-columns-xl: var(--pc-grid-columns-lg);
  display: grid;
  gap: var(--pc-grid-gap-xs, var(--p-space-4));
  grid-template-areas: var(--pc-grid-areas-xs);
  grid-template-columns: repeat(var(--pc-grid-columns-xs), minmax(0, 1fr));
}

@media (min-width: 30.625em) {
  .Polaris-Grid {
    gap: var(--pc-grid-gap-sm, var(--p-space-4));
    grid-template-areas: var(--pc-grid-areas-sm);
    grid-template-columns: repeat(var(--pc-grid-columns-sm), minmax(0, 1fr));
  }
}

@media (min-width: 48em) {
  .Polaris-Grid {
    gap: var(--pc-grid-gap-md, var(--p-space-4));
    grid-template-areas: var(--pc-grid-areas-md);
    grid-template-columns: repeat(var(--pc-grid-columns-md), minmax(0, 1fr));
  }
}

@media (min-width: 65em) {
  .Polaris-Grid {
    gap: var(--pc-grid-gap-lg, var(--p-space-4));
    grid-template-areas: var(--pc-grid-areas-lg);
    grid-template-columns: repeat(var(--pc-grid-columns-lg), minmax(0, 1fr));
  }
}

@media (min-width: 90em) {
  .Polaris-Grid {
    gap: var(--pc-grid-gap-xl, var(--p-space-4));
    grid-template-areas: var(--pc-grid-areas-xl);
    grid-template-columns: repeat(var(--pc-grid-columns-xl), minmax(0, 1fr));
  }
}

.Polaris-Heading {
  margin: 0;
  font-size: 1.0625rem;
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-font-line-height-3);
}

@media (min-width: 48em) {
  .Polaris-Heading {
    font-size: var(--p-font-size-200);
  }
}

@media print {
  .Polaris-Heading {
    font-size: var(--p-font-size-100);
    line-height: var(--p-font-line-height-1);
  }
}

.Polaris-IndexTable {
  --pc-index-table-translate-offset: 2.1875rem;
  --pc-index-table-table-header-offset: 2.25rem;
  --pc-index-table-cell: 1;
  --pc-index-table-sticky-cell: 31;
  --pc-index-table-scroll-bar: 35;
  --pc-index-table-bulk-actions: 36;
  --pc-index-table-loading-panel: 37;
  position: relative;
  border-radius: inherit;
}

.Polaris-IndexTable__IndexTableWrapper {
  border-radius: inherit;
}

.Polaris-IndexTable__IndexTableWrapperWithBulkActions {
  --pc-index-table-bulk-actions-offset: 5.75rem;
  padding-bottom: var(--pc-index-table-bulk-actions-offset);
  border-radius: 0;
}

.Polaris-IndexTable__LoadingContainer--enter {
  opacity: 0;
  transform: translateY(-100%);
}

.Polaris-IndexTable--loadingContainerEnterActive {
  opacity: 1;
  transition:
    opacity var(--p-duration-100) var(--p-ease-out),
    transform var(--p-duration-100) var(--p-ease-out);
  transform: translateY(0);
}

.Polaris-IndexTable__LoadingContainer--exit {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-IndexTable--loadingContainerExitActive {
  opacity: 0;
  transform: translateY(-100%);
  transition:
    opacity var(--p-duration-100) var(--p-ease-in),
    transform var(--p-duration-100) var(--p-ease-in);
}

.Polaris-IndexTable__LoadingPanel {
  position: absolute;
  z-index: var(--p-z-index-2);
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--p-surface);
  padding: var(--p-space-2) var(--p-space-4);
  box-shadow: var(--p-shadow-md);
}

.Polaris-IndexTable__LoadingPanel .Polaris-IndexTable__LoadingPanelRow {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  background: var(--p-surface-highlight-subdued);
  padding: var(--p-space-2);
  padding-bottom: var(--p-space-1);
  border-radius: var(--p-border-radius-1);
}

.Polaris-IndexTable__LoadingPanelText {
  margin-left: var(--p-space-3);
  color: var(--p-text);
}

.Polaris-IndexTable__Table {
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
}

.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__Table--scrolling
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell,
.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--first,
.Polaris-IndexTable__Table--scrolling
  .Polaris-IndexTable__TableHeading--second {
  visibility: visible;
  /* background-color: var(--p-surface); */
}

.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--first {
  filter: drop-shadow(0.0625rem 0 0 var(--p-divider));
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--sticky
    .Polaris-IndexTable__TableCell--first
    + .Polaris-IndexTable__TableCell,
  .Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--sticky
    .Polaris-IndexTable__TableHeading--second {
    filter: drop-shadow(0.0625rem 0 0 var(--p-divider));
  }
}

.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--sticky.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable__TableHeading--second,
.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--sticky.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable__TableCell:first-child {
  filter: drop-shadow(0.0625rem 0 0 var(--p-divider));
}

.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable__TableHeading--second,
.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable__TableCell:first-child {
  visibility: visible;
}

.Polaris-IndexTable__TableRow {
  background-color: var(--p-surface);
  cursor: pointer;
  border-top: var(--p-border-width-1) solid var(--p-divider);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--unclickable {
  cursor: auto;
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess,
.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess
  .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued,
.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued
  .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered
  .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableHeading--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableHeading--second,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-selected);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableCell--first,
.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableCell--first
  + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-selected-hovered);
}

.Polaris-IndexTable__TableRow--subdued {
  color: var(--p-text-subdued);
}

.Polaris-IndexTable__TableRow--disabled {
  cursor: default;
  color: var(--p-text-subdued);
}

.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow:nth-child(2n + 1) {
  background: none;
}

.Polaris-IndexTable__ZebraStriping .Polaris-IndexTable__TableRow:nth-child(2n),
.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow:nth-child(2n)
  .Polaris-IndexTable__TableCell:first-child,
.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow:nth-child(2n)
  .Polaris-IndexTable__TableCell:last-child {
  background: var(--p-surface-subdued);
}

.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow:nth-child(2n):hover,
.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow:nth-child(2n)
  .Polaris-IndexTable__TableCell:first-child:hover,
.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow:nth-child(2n)
  .Polaris-IndexTable__TableCell:last-child:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow--hovered:nth-child(2n + 1),
.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow--hovered:nth-child(2n),
.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow--hovered:nth-child(2n)
  .Polaris-IndexTable__TableCell:first-child,
.Polaris-IndexTable__ZebraStriping
  .Polaris-IndexTable__TableRow--hovered:nth-child(2n)
  .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable__TableHeading {
  background: var(--p-surface-subdued);
  z-index: var(--p-z-index-1);
  padding: 20px;
  text-align: left;
  font-weight: var(--p-font-weight-medium);
  color: var(--p-text-subdued);
  white-space: nowrap;
  border: 0;
}

.Polaris-IndexTable--tableHeadingAlignCenter {
  text-align: center;
}

.Polaris-IndexTable--tableHeadingAlignCenter [class*="TooltipContainer"] {
  justify-content: center;
}

.Polaris-IndexTable--tableHeadingAlignEnd {
  text-align: right;
}

.Polaris-IndexTable--tableHeadingAlignEnd [class*="TooltipContainer"] {
  justify-content: end;
}

.Polaris-IndexTable__TableHeading--sortable {
  background: var(--p-surface-subdued);
}

.Polaris-IndexTable__TableHeading--flush {
  padding: 0;
}

.Polaris-IndexTable__TableHeading--first {
  --pc-index-table-checkbox-offset-left: 1rem;
  --pc-index-table-checkbox-offset-right: 1.125rem;
  position: sticky;
  left: 0;
  padding-left: var(--pc-index-table-checkbox-offset-left);
  padding-right: var(--pc-index-table-checkbox-offset-right);
  width: var(--p-space-5);
}

.Polaris-IndexTable__TableHeadingSortButton {
  position: static;
  background: none;
  padding: 0;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--p-font-weight-medium);
  color: var(--p-text-subdued);
  font-size: var(--p-font-size-75);
}

.Polaris-IndexTable__TableHeadingSortButton:hover
  .Polaris-IndexTable__TableHeadingSortIcon,
.Polaris-IndexTable__TableHeadingSortButton:focus
  .Polaris-IndexTable__TableHeadingSortIcon {
  opacity: 1;
}

.Polaris-IndexTable__TableHeadingSortButton
  .Polaris-IndexTable--tableHeadingSortIconHeadingAlignEnd {
  animation: Polaris-IndexTable--hideRightAlignedSortButtonIcon
    var(--p-duration-50);
}

.Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEnd {
  transform: translateX(var(--p-space-5));
  transition-delay: var(--p-duration-50);
}

.Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEnd:hover,
.Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEnd:focus {
  transition-delay: none;
  transform: translateX(var(--p-space-1));
}

.Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEndCurrentlySorted {
  transform: translateX(var(--p-space-1));
}

.Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEndCurrentlySorted
  .Polaris-IndexTable--tableHeadingSortIconHeadingAlignEnd {
  animation: none;
}

.Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEndPreviouslySorted {
  animation: Polaris-IndexTable--rightAlignedSortButtonSlideOut
    var(--p-duration-50) var(--p-ease);
}

.Polaris-IndexTable__TableHeadingSortIcon {
  order: 1;
  opacity: 0;
  height: var(--p-space-5);
  width: var(--p-space-5);
}

.Polaris-IndexTable__TableHeadingSortIcon:not(
    .Polaris-IndexTable--tableHeadingSortIconHeadingAlignEnd
  ),
.Polaris-IndexTable__TableHeadingSortIcon:not(
    .Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEndPreviouslySorted
  ) {
  transition: opacity var(--p-duration-50) var(--p-ease);
}

.Polaris-IndexTable__TableHeadingSortButton:hover
  .Polaris-IndexTable--tableHeadingSortIconHeadingAlignEnd {
  animation: Polaris-IndexTable--revealRightAlignedSortButtonIcon
    var(--p-duration-200) var(--p-ease);
}

.Polaris-IndexTable--tableHeadingSortButtonHeadingAlignEndCurrentlySorted:hover
  .Polaris-IndexTable--tableHeadingSortIconHeadingAlignEnd {
  animation: none;
}

.Polaris-IndexTable__TableHeadingUnderline {
  border-bottom: var(--p-border-width-2) dotted var(--p-border-subdued);
}

.Polaris-IndexTable__TableHeadingTooltipUnderlinePlaceholder {
  border-bottom: var(--p-border-width-2) dotted transparent;
}

.Polaris-IndexTable__TableHeadingSortIcon--visible {
  opacity: 1;
}

.Polaris-IndexTable__TableHeadingSortSvg {
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.Polaris-IndexTable__SortableTableHeadingWithCustomMarkup {
  display: flex;
  flex-wrap: nowrap;
}

.Polaris-IndexTable__SortableTableHeaderWrapper {
  cursor: pointer;
}

.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
  display: flex;
}

.Polaris-IndexTable__FirstStickyHeaderElement {
  padding-right: var(--p-space-05);
}

.Polaris-IndexTable__TableHeading--second:not(
    .Polaris-IndexTable__TableHeading--unselectable
  ) {
  padding-left: 0;
}

.Polaris-IndexTable__TableCell {
  z-index: var(--pc-index-table-cell);
  text-align: left;
  padding: 20px;
  overflow: auto;
}
.Polaris-IndexTable__TableCell::-webkit-scrollbar {
  width: 0;
}

.Polaris-IndexTable__TableCell--flush {
  padding: 0;
}

.Polaris-IndexTable__TableCell--first {
  position: sticky;
  left: 15px;
  z-index: var(--pc-index-table-sticky-cell);
  padding: var(--p-space-2) 0;
  vertical-align: middle;
}

.Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  left: var(--pc-checkbox-offset);
  padding-left: 0;
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable__Table--sticky
    .Polaris-IndexTable__TableCell--first
    + .Polaris-IndexTable__TableCell {
    position: sticky;
    z-index: var(--pc-index-table-sticky-cell);
  }
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable__Table--sticky
    .Polaris-IndexTable__TableHeading--second:not(
      .Polaris-IndexTable__TableHeading--unselectable
    ) {
    position: sticky;
    left: 0;
  }
}

.Polaris-IndexTable__Table--sticky
  .Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  .Polaris-IndexTable__TableCell:first-child {
  left: 0;
  background-color: var(--p-surface);
  z-index: var(--pc-index-table-sticky-cell);
  position: sticky;
}

.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable--statusSuccess
  .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable--statusSubdued
  .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__Table--unselectable
  .Polaris-IndexTable__TableRow--hovered
  .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-hovered);
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable--tableStickyScrolling
    .Polaris-IndexTable__TableCell:last-child,
  .Polaris-IndexTable--tableStickyScrolling
    .Polaris-IndexTable__TableHeading--last {
    filter: drop-shadow(-0.0625rem 0 0 var(--p-divider));
  }
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable--tableStickyLast
    .Polaris-IndexTable__TableCell:last-child {
    position: sticky;
    right: 0;
    background-color: var(--p-surface);
    z-index: var(--pc-index-table-sticky-cell);
  }
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableHeading--last {
    position: sticky;
    right: 0;
    background-color: var(--p-surface);
    z-index: auto;
  }
}

.Polaris-IndexTable--tableStickyLast
  .Polaris-IndexTable--statusSuccess
  .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable--tableStickyLast
  .Polaris-IndexTable--statusSubdued
  .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable--tableStickyLast
  .Polaris-IndexTable__TableRow--hovered
  .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable--tableStickyLast
  .Polaris-IndexTable__TableRow--selected
  .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-selected);
}

.Polaris-IndexTable__Table--sortable .Polaris-IndexTable__TableHeading {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__StickyTable {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  z-index: var(--pc-index-table-loading-panel);
}

.Polaris-IndexTable__StickyTableHeader {
  position: absolute;
  display: flex;
  width: 100%;
}

.Polaris-IndexTable__StickyTableHeader:not(
    .Polaris-IndexTable__StickyTableHeader--isSticky
  ) {
  top: -62.5rem;
  left: -62.5rem;
}

.Polaris-IndexTable__StickyTableColumnHeader {
  flex: 0 0 auto;
}

.Polaris-IndexTable__StickyTableHeadings {
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
}

.Polaris-IndexTable__StickyTableHeading--second {
  padding-left: 0;
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable__StickyTableHeading--second {
    display: none;
  }
}

.Polaris-IndexTable__StickyTableHeading--second.Polaris-IndexTable--unselectable {
  display: none;
}

.Polaris-IndexTable--stickyTableHeadingSecondScrolling {
  padding: 0 var(--p-space-025) 0 var(--p-space-4);
  display: none;
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable--stickyTableHeadingSecondScrolling {
    display: block;
  }
}

.Polaris-IndexTable__StickyTableHeader--isSticky {
  /* visibility: visible; */
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-md);
}

.Polaris-IndexTable:hover .Polaris-IndexTable__ScrollLeft {
  display: block;
}

.Polaris-IndexTable:hover .Polaris-IndexTable__ScrollRight {
  display: block;
}

.Polaris-IndexTable .Polaris-IndexTable__ScrollRight--onboarding {
  display: block;
}

.Polaris-IndexTable__BulkActionsWrapper {
  visibility: visible;
  position: absolute;
  z-index: var(--pc-index-table-bulk-actions);
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--p-space-4);
  pointer-events: none;
}

.Polaris-IndexTable__BulkActionsWrapperSticky {
  position: fixed;
  top: auto;
  bottom: calc(var(--p-space-10) - var(--p-space-1));
}

.Polaris-IndexTable__SelectAllActionsWrapper {
  visibility: visible;
  position: relative;
  z-index: var(--pc-index-table-bulk-actions);
  top: 0;
  left: 0;
  right: 0;
  padding: 0 var(--p-space-2) 0 var(--p-space-4);
  background: var(--p-surface);
  border-radius: var(--p-border-radius-2);
}

.Polaris-IndexTable__SelectAllActionsWrapper.Polaris-IndexTable__StickyTableHeader--condensed {
  padding-top: calc(var(--p-space-2) + var(--p-space-05));
}

.Polaris-IndexTable__ScrollBarContainer {
  position: sticky;
  z-index: var(--pc-index-table-scroll-bar);
  bottom: 0;
  padding: var(--p-space-05);
  background-color: var(--p-surface);
  border-bottom-right-radius: var(--p-border-radius-2);
  border-bottom-left-radius: var(--p-border-radius-2);
}

.Polaris-IndexTable--scrollBarContainerCondensed {
  visibility: hidden;
  pointer-events: none;
}

.Polaris-IndexTable--scrollBarContainerHidden {
  display: none;
}

.Polaris-IndexTable__ScrollBar {
  overflow-x: scroll;
  width: 100%;
  margin: 0;
  padding: 0;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar-track {
  border-radius: var(--p-border-radius-1);
  background-color: transparent;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  height: var(--p-space-2);
  width: var(--p-space-2);
  background-color: transparent;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar-thumb {
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-border-neutral-subdued);
  -webkit-transition: background-color var(--p-duration-100) var(--p-ease-out);
  transition: background-color var(--p-duration-100) var(--p-ease-out);
}

.Polaris-IndexTable__ScrollBar:hover::-webkit-scrollbar-thumb {
  background-color: var(--p-border-depressed);
}

.Polaris-IndexTable--disableTextSelection {
  -webkit-user-select: none;
  user-select: none;
}

.Polaris-IndexTable--selectMode {
  transform: translateY(calc(var(--pc-index-table-table-header-offset) * -1));
  margin-bottom: calc(var(--pc-index-table-table-header-offset) * -1);
}

.Polaris-IndexTable__EmptySearchResultWrapper {
  padding: var(--p-space-4);
}

.Polaris-IndexTable--condensedRow {
  width: calc(100% + var(--pc-index-table-translate-offset));
  transform: translateX(calc(var(--pc-index-table-translate-offset) * -1));
  transition: transform var(--p-ease) var(--p-duration-200);
  display: flex;
  border-top: var(--p-border-divider);
  filter: none;
  align-items: center;
}

[data-selectmode="true"] .Polaris-IndexTable--condensedRow {
  transform: none;
}

.Polaris-IndexTable__CondensedList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.Polaris-IndexTable__HeaderWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: var(--p-space-2) var(--p-space-4);
  background-color: var(--p-surface);
}

.Polaris-IndexTable__HeaderWrapper.Polaris-IndexTable--unselectable {
  min-height: auto;
  padding: 0;
}

.Polaris-IndexTable__StickyTable--condensed {
  visibility: visible;
}

.Polaris-IndexTable__StickyTableHeader--condensed {
  padding: var(--p-space-4) var(--p-space-4) var(--p-space-2);
}

.Polaris-IndexTable__ScrollBarContent {
  height: 0.0625rem;
  width: var(--pc-index-table-scroll-bar-content-width);
}

/* stylelint-disable-next-line polaris/motion/at-rule-disallowed-list -- custom reset animation for right-aligned header */
@keyframes Polaris-IndexTable--rightAlignedSortButtonSlideOut {
  0% {
    transform: translateX(var(--p-space-1));
  }
  80% {
    transform: translateX(var(--p-space-1));
  }
  100% {
    transform: translateX(var(--p-space-5));
  }
}

/* stylelint-disable-next-line polaris/motion/at-rule-disallowed-list -- custom animation for right-aligned header */
@keyframes Polaris-IndexTable--revealRightAlignedSortButtonIcon {
  0% {
    transform: translateX(calc(var(--p-space-5) * -1));
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}

/* stylelint-disable-next-line polaris/motion/at-rule-disallowed-list -- custom animation for right-aligned header */
@keyframes Polaris-IndexTable--hideRightAlignedSortButtonIcon {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    opacity: 0;
    transform: translateX(0);
  }
  90% {
    transform: translateX(calc(var(--p-space-5) * -1));
  }
  100% {
    opacity: 0;
    transform: translateX(calc(var(--p-space-5) * -1));
  }
}

.Polaris-IndexTable-Checkbox__TableCellContentContainer {
  display: flex;
  align-items: center;
}

.Polaris-IndexTable-Checkbox__Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Polaris-IndexTable-Checkbox--expanded {
  width: 2.25rem;
  height: 1.25rem;
  padding-left: var(--p-space-4);
}

.Polaris-IndexTable-Checkbox--condensed {
  min-width: 3.125rem;
  min-height: 2.75rem;
  margin-right: calc(var(--p-space-4) * -1);
}

.Polaris-IndexTable-ScrollContainer {
  overflow-x: auto;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: inherit;
}

.Polaris-IndexTable-ScrollContainer::-webkit-scrollbar {
  display: none;
}

.Polaris-CheckableButton {
  color: var(--p-text);
  font-size: var(--p-font-size-75);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-1);
  text-transform: initial;
  letter-spacing: initial;
  display: flex;
  align-items: center;
  min-height: 2.25rem;
  min-width: 2.25rem;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  text-align: left;
  border-radius: var(--p-border-radius-1);
  width: auto;
}

.Polaris-CheckableButton svg {
  fill: var(--p-icon-on-interactive);
}

.Polaris-CheckableButton:hover,
.Polaris-CheckableButton:active {
  background: transparent;
}

.Polaris-CheckableButton:focus {
  outline: none;
}

.Polaris-CheckableButton__Checkbox {
  pointer-events: none;
  height: 1.25rem;
  width: 1.25rem;
}

.Polaris-CheckableButton__Label {
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  padding: var(--p-space-025) 0;
  margin-left: calc(var(--p-space-5) - var(--p-border-width-2));
}

.Polaris-SelectAllActions {
  display: flex;
  gap: var(--p-space-2);
  align-items: center;
  justify-content: flex-start;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
}

.Polaris-SelectAllActions__SelectAllActions--entering,
.Polaris-SelectAllActions__SelectAllActions--exiting {
  opacity: 0;
  display: flex;
}

.Polaris-SelectAllActions__SelectAllActions--entered {
  opacity: 1;
  display: flex;
}

.Polaris-SelectAllActions__SelectAllActions--exited {
  opacity: 0;
  display: none;
}

.Polaris-SelectAllActions__PaginatedSelectAll {
  font-size: var(--p-font-size-75);
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-SelectAllActions__AllAction {
  font-size: var(--p-font-size-75);
  font-weight: var(--p-font-weight-semibold);
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  color: var(--p-interactive);
  position: relative;
}

.Polaris-SelectAllActions__AllAction::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-SelectAllActions__AllAction:hover,
.Polaris-SelectAllActions__AllAction:focus {
  color: var(--p-interactive-hovered);
}

.Polaris-SelectAllActions__AllAction:active {
  color: var(--p-interactive-pressed);
}

.Polaris-SelectAllActions__AllAction:focus::after {
  content: none;
}

.Polaris-SelectAllActions__AllAction:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-InlineCode__Code {
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-05);
  font-family: var(--p-font-family-mono);
  font-size: 0.85em;
  font-weight: var(--p-font-weight-medium);
  padding: var(--p-space-025) var(--p-space-1);
}

.Polaris-KeyboardKey {
  height: 1.75rem;
  display: inline-flex;
  justify-content: center;
  margin: 0 var(--p-space-05) var(--p-space-05);
  padding: 0 var(--p-space-2);
  background: var(--p-surface-depressed);
  box-shadow: 0 var(--p-space-025) 0 var(--p-hint-from-direct-light);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  font-size: var(--p-font-size-100);
  font-weight: var(--p-font-weight-medium);
  font-family: var(--p-font-family-sans);
  line-height: 1.75rem;
  text-align: center;
  min-width: 1.75rem;
  -webkit-user-select: none;
  user-select: none;
}

.Polaris-KeyboardKey--small {
  background: var(--p-background-selected);
  border-radius: var(--p-border-radius-05);
  box-shadow: none;
  line-height: unset;
  padding: 0 var(--p-space-1);
  font-size: var(--p-font-size-75);
  height: var(--p-space-5);
  min-width: var(--p-space-5);
}

.Polaris-Layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-5) * -1);
}

@media print {
  body .Polaris-Layout {
    font-size: var(--p-font-size-75);
    line-height: var(--p-font-line-height-1);
  }
  .Polaris-Layout a,
  .Polaris-Layout button {
    color: var(--p-text);
  }
}

.Polaris-Layout__Section {
  flex: 2 2 30rem;
  min-width: 51%;
}

@media print {
  .Polaris-Layout__Section {
    flex: 2 2 22.5rem;
  }
}

.Polaris-Layout__Section--secondary {
  flex: 1 1 15rem;
  min-width: 0;
}

.Polaris-Layout__Section--fullWidth {
  flex: 1 1 100%;
}

.Polaris-Layout__Section--oneHalf {
  flex: 1 1 28.125rem;
  min-width: 0;
}

.Polaris-Layout__Section--oneThird {
  flex: 1 1 15rem;
  min-width: 0;
}

.Polaris-Layout__AnnotatedSection {
  min-width: 0;
  flex: 1 1 100%;
}

.Polaris-Layout__Section,
.Polaris-Layout__AnnotatedSection {
  max-width: calc(100% - var(--p-space-5));
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-5);
}

@media (min-width: 30.625em) {
  .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection,
  .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
    padding-top: var(--p-space-4);
    border-top: var(--p-border-divider);
  }
}

.Polaris-Layout__AnnotationWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-5) * -1);
}

.Polaris-Layout__AnnotationContent {
  flex: 2 2 30rem;
}

.Polaris-Layout__Annotation {
  flex: 1 1 15rem;
  padding: var(--p-space-4) var(--p-space-5) 0;
}

@media (min-width: 30.625em) {
  .Polaris-Layout__Annotation {
    padding: var(--p-space-4) 0 0;
  }
}

@media (min-width: 48em) {
  .Polaris-Layout__Annotation {
    padding: var(--p-space-5) var(--p-space-5) var(--p-space-5) 0;
  }
}

.Polaris-Layout__Annotation,
.Polaris-Layout__AnnotationContent {
  min-width: 0;
  max-width: calc(100% - var(--p-space-5));
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-5);
}

.Polaris-LegacyFilters-ConnectedFilterControl {
  --pc-connceted-filter-control-item: 10;
  --pc-connceted-filter-control-focused: 20;
  display: flex;
  flex-grow: 1;
}

.Polaris-LegacyFilters-ConnectedFilterControl
  .Polaris-LegacyFilters-ConnectedFilterControl__CenterContainer {
  flex: 1 1 auto;
  min-width: 6.25rem;
}

.Polaris-LegacyFilters-ConnectedFilterControl.Polaris-LegacyFilters-ConnectedFilterControl--right
  .Polaris-LegacyFilters-ConnectedFilterControl__CenterContainer
  * {
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-LegacyFilters-ConnectedFilterControl__Item {
  position: relative;
  z-index: var(--pc-connceted-filter-control-item);
}

.Polaris-LegacyFilters-ConnectedFilterControl__Item--focused {
  z-index: var(--pc-connceted-filter-control-focused);
}

.Polaris-LegacyFilters-ConnectedFilterControl__ProxyButtonContainer {
  position: absolute;
  top: -62.5rem;
  left: -62.5rem;
  display: flex;
  width: 100%;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.Polaris-LegacyFilters-ConnectedFilterControl__ProxyButtonContainer > * {
  flex-shrink: 0;
}

.Polaris-LegacyFilters-ConnectedFilterControl__CenterContainer
  + .Polaris-LegacyFilters-ConnectedFilterControl__RightContainer,
.Polaris-LegacyFilters-ConnectedFilterControl__CenterContainer
  + .Polaris-LegacyFilters-ConnectedFilterControl__MoreFiltersButtonContainer {
  margin-left: var(--p-space-2);
}

.Polaris-LegacyFilters-ConnectedFilterControl__RightContainer {
  display: flex;
}

.Polaris-LegacyFilters-ConnectedFilterControl__RightContainer
  .Polaris-LegacyFilters-ConnectedFilterControl__Item
  > div
  > button {
  margin-right: calc(var(--p-space-025) * -1);
  border-radius: 0;
}

.Polaris-LegacyFilters-ConnectedFilterControl__RightContainer
  .Polaris-LegacyFilters-ConnectedFilterControl__Item {
  flex-shrink: 0;
}

.Polaris-LegacyFilters-ConnectedFilterControl__RightContainer
  .Polaris-LegacyFilters-ConnectedFilterControl__Item:first-of-type
  > div
  > button {
  border-top-left-radius: var(--p-border-radius-1);
  border-bottom-left-radius: var(--p-border-radius-1);
}

.Polaris-LegacyFilters-ConnectedFilterControl__RightContainer.Polaris-LegacyFilters-ConnectedFilterControl--queryFieldHidden
  .Polaris-LegacyFilters-ConnectedFilterControl__Item:first-of-type
  > div
  > button {
  border-top-left-radius: var(--p-border-radius-1);
  border-bottom-left-radius: var(--p-border-radius-1);
}

.Polaris-LegacyFilters-ConnectedFilterControl__RightContainerWithoutMoreFilters
  .Polaris-LegacyFilters-ConnectedFilterControl__Item:last-child
  > div
  > button {
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-LegacyFilters-ConnectedFilterControl__MoreFiltersButtonContainer
  .Polaris-LegacyFilters-ConnectedFilterControl__Item
  > div
  > button {
  white-space: nowrap;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Polaris-LegacyFilters-ConnectedFilterControl__MoreFiltersButtonContainer.Polaris-LegacyFilters-ConnectedFilterControl--onlyButtonVisible
  .Polaris-LegacyFilters-ConnectedFilterControl__Item
  > div
  > button {
  border-radius: var(--p-border-radius-1);
}

.Polaris-LegacyFilters-ConnectedFilterControl__Wrapper {
  display: flex;
}

.Polaris-LegacyFilters-ConnectedFilterControl__AuxiliaryContainer {
  flex-grow: 0;
}

.Polaris-LegacyFilters {
  position: relative;
}

.Polaris-LegacyFilters__LegacyFiltersContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Polaris-LegacyFilters__LegacyFiltersContainerHeader {
  top: 0;
  width: 100%;
  padding: var(--p-space-4) var(--p-space-5);
  border-bottom: var(--p-border-divider);
  height: 3.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-LegacyFilters__LegacyFiltersDesktopContainerContent {
  width: 100%;
  height: calc(100% - 7.875rem);
  padding: var(--p-space-2);
}

.Polaris-LegacyFilters__LegacyFiltersMobileContainerContent {
  width: 100%;
  height: calc(100% - 3.5rem);
  padding: var(--p-space-2);
}

.Polaris-LegacyFilters__LegacyFiltersContainerFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--p-space-4) var(--p-space-5);
  border-top: var(--p-border-divider);
  height: 4.375rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-LegacyFilters__LegacyFiltersMobileContainerFooter {
  width: 100%;
  padding: var(--p-space-4) var(--p-space-4);
  height: 4.375rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-LegacyFilters__EmptyFooterState {
  border-top: var(--p-border-divider);
  padding-top: var(--p-space-4);
  width: 100%;
  display: flex;
  justify-content: center;
}

.Polaris-LegacyFilters__FilterTriggerContainer {
  position: relative;
}

.Polaris-LegacyFilters__FilterTrigger {
  width: 100%;
  margin: 0;
  padding: var(--p-space-4) var(--p-space-5);
  color: var(--p-text);
  border-radius: var(--p-border-radius-1);
  background: none;
  border: none;
  outline: none;
  position: relative;
}

.Polaris-LegacyFilters__FilterTrigger::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-LegacyFilters__FilterTrigger:focus {
  box-shadow: none;
}

.Polaris-LegacyFilters__FilterTrigger:hover {
  cursor: pointer;
  background-color: var(--p-surface-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-LegacyFilters__FilterTrigger:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-LegacyFilters__FilterTriggerTitle {
  font-size: 0.9375rem;
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-LegacyFilters__AppliedFilterBadgeContainer {
  padding-top: var(--p-space-1);
  display: flex;
}

.Polaris-LegacyFilters--VD-open
  .Polaris-LegacyFilters__AppliedFilterBadgeContainer {
  display: none;
}

.Polaris-LegacyFilters__FilterTriggerLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Polaris-LegacyFilters--VD-open::before,
.Polaris-LegacyFilters--VD-open::after {
  content: "";
  position: relative;
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-LegacyFilters--VD-open::before {
  top: 0;
}

.Polaris-LegacyFilters--VD-open::after {
  bottom: 0;
}

.Polaris-LegacyFilters--VD-open.Polaris-LegacyFilters--first::after {
  content: "";
  bottom: 0;
  position: relative;
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-LegacyFilters--VD-open.Polaris-LegacyFilters--first::before {
  display: none;
}

.Polaris-LegacyFilters--VD-open ~ .Polaris-LegacyFilters--VD-open::before {
  display: none;
}

.Polaris-LegacyFilters--VD-open.Polaris-LegacyFilters--last::before {
  content: "";
  top: 0;
  position: relative;
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
}

.Polaris-LegacyFilters--VD-open.Polaris-LegacyFilters--last::after {
  display: none;
}

.Polaris-LegacyFilters--VD-open + .Polaris-LegacyFilters--last::before {
  display: none;
}

.Polaris-LegacyFilters__FilterNodeContainer {
  padding: var(--p-space-2) var(--p-space-5) var(--p-space-5) var(--p-space-5);
}

.Polaris-LegacyFilters__SearchIcon {
  fill: currentColor;
}

.Polaris-LegacyFilters__Backdrop {
  position: fixed;
  z-index: var(--p-z-index-10);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  opacity: 0;
}

.Polaris-LegacyFilters__HelpText {
  margin-top: var(--p-space-2);
}

.Polaris-LegacyFilters__TagsContainer {
  display: flex;
  padding-top: var(--p-space-2);
  flex-wrap: wrap;
}

.Polaris-LegacyFilters__TagsContainer > * {
  margin-right: var(--p-space-2);
  margin-bottom: var(--p-space-2);
}

.Polaris-LegacyTabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-LegacyTabs--fitted {
  flex-wrap: nowrap;
}

.Polaris-LegacyTabs--fitted .Polaris-LegacyTabs__TabContainer {
  flex: 1 1 100%;
}

.Polaris-LegacyTabs--fitted .Polaris-LegacyTabs__Title {
  width: 100%;
  padding: var(--p-space-2) var(--p-space-4);
}

.Polaris-LegacyTabs--fillSpace .Polaris-LegacyTabs__TabContainer {
  flex: 1 1 auto;
}

.Polaris-LegacyTabs__TabContainer {
  display: flex;
  margin: 0;
  padding: 0;
}

.Polaris-LegacyTabs__Tab {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: var(--p-text-subdued);
  color: var(--p-text);
  position: relative;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  margin-top: var(--p-space-025);
  margin-bottom: calc(var(--p-space-025) * -1);
  padding: var(--p-space-2) var(--p-space-1);
  outline: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
}

.Polaris-LegacyTabs__Tab:focus {
  outline: none;
}

.Polaris-LegacyTabs__Tab:hover {
  text-decoration: none;
}

.Polaris-LegacyTabs__Tab:hover .Polaris-LegacyTabs__Title {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  background-color: transparent;
}

.Polaris-LegacyTabs__Tab:hover .Polaris-LegacyTabs__Title::before {
  background-color: var(--p-border-hovered);
}

.Polaris-LegacyTabs__Tab:active .Polaris-LegacyTabs__Title {
  background-color: transparent;
}

.Polaris-LegacyTabs__Tab:active .Polaris-LegacyTabs__Title::before {
  background: var(--p-surface-primary-selected-pressed);
}

.Polaris-LegacyTabs__Tab:focus-visible .Polaris-LegacyTabs__Title {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
}

.Polaris-LegacyTabs__Tab:focus-visible:not(:active)
  .Polaris-LegacyTabs__Title::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-LegacyTabs__Tab:visited {
  color: inherit;
}

.Polaris-LegacyTabs__Tab--selected {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
}

.Polaris-LegacyTabs__Tab--selected:focus .Polaris-LegacyTabs__Title {
  outline: var(--p-border-width-3) solid transparent;
}

.Polaris-LegacyTabs__Tab--selected:focus .Polaris-LegacyTabs__Title::before {
  background: var(--p-action-primary);
}

.Polaris-LegacyTabs__Tab--selected .Polaris-LegacyTabs__Title {
  outline: var(--p-border-width-3) solid transparent;
  color: var(--p-text);
}

.Polaris-LegacyTabs__Tab--selected .Polaris-LegacyTabs__Title::before {
  background: var(--p-action-primary);
}

.Polaris-LegacyTabs__Title {
  position: relative;
  border-radius: var(--p-border-radius-1);
  display: block;
  padding: var(--p-space-2) var(--p-space-4);
  min-width: 3.125rem;
  color: var(--p-text-subdued);
}

.Polaris-LegacyTabs__Title::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-LegacyTabs__Title::before {
  content: "";
  position: absolute;
  bottom: calc(var(--p-space-2) * -1);
  left: 0;
  right: 0;
  height: var(--p-border-width-3);
  border-top-left-radius: var(--p-border-radius-1);
  border-top-right-radius: var(--p-border-radius-1);
}

.Polaris-LegacyTabs--titleWithIcon {
  display: flex;
}

.Polaris-LegacyTabs__Panel {
  display: block;
}

.Polaris-LegacyTabs__Panel:focus {
  outline: none;
}

.Polaris-LegacyTabs__Panel--hidden {
  display: none;
}

.Polaris-LegacyTabs__Item {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  min-height: 1rem;
  padding: 0.5rem var(--p-space-4);
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  border-radius: var(--p-border-radius-1);
  color: inherit;
}

.Polaris-LegacyTabs__Item:focus {
  outline: none;
}

.Polaris-LegacyTabs__Item::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-LegacyTabs__Item::-moz-focus-inner {
  border: none;
}

.Polaris-LegacyTabs__Item:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-LegacyTabs__Item:active {
  background-color: var(--p-surface-primary-selected-pressed);
}

.Polaris-LegacyTabs__Item:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-LegacyTabs__Item:visited {
  color: inherit;
}

.Polaris-LegacyTabs__DisclosureTab {
  display: none;
}

.Polaris-LegacyTabs__DisclosureTab--visible {
  display: flex;
}

.Polaris-LegacyTabs__DisclosureActivator {
  position: relative;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  margin: var(--p-space-025) var(--p-space-025) calc(var(--p-space-025) * -1) 0;
}

.Polaris-LegacyTabs__DisclosureActivator::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-LegacyTabs__DisclosureActivator:hover svg,
.Polaris-LegacyTabs__DisclosureActivator:focus svg {
  fill: var(--p-icon);
}

.Polaris-LegacyTabs__DisclosureActivator:focus-visible
  .Polaris-LegacyTabs__Title::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-LegacyTabs__DisclosureActivator:hover
  .Polaris-LegacyTabs__Title::before {
  background-color: var(--p-border-hovered);
}

.Polaris-LegacyTabs__TabMeasurer {
  display: flex;
  visibility: hidden;
  height: 0;
}

.Polaris-Link {
  -webkit-appearance: none;
  appearance: none;
  display: inline;
  text-align: inherit;
  padding: 0;
  background: none;
  border: 0;
  font-size: inherit;
  font-weight: inherit;
  color: var(--p-interactive);
  text-decoration: underline;
  cursor: pointer;
}

.Polaris-Link:hover {
  color: var(--p-interactive-hovered);
  text-decoration: none;
}

.Polaris-Link:focus:not(:active) {
  outline: var(--p-focused) auto var(--p-border-width-1);
}

.Polaris-Link:active {
  position: relative;
  color: var(--p-interactive-pressed);
}

.Polaris-Link:active::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -0.125rem;
  right: -0.3125rem;
  bottom: -0.125rem;
  left: -0.3125rem;
  display: block;
  border-radius: var(--p-border-radius-1);
}

@media print {
  .Polaris-Link {
    -webkit-text-decoration-color: var(--p-border-neutral-subdued);
    text-decoration-color: var(--p-border-neutral-subdued);
  }
}

.Polaris-Link--monochrome {
  color: inherit;
}

.Polaris-Link--monochrome:hover,
.Polaris-Link--monochrome:focus,
.Polaris-Link--monochrome:active {
  color: inherit;
}

.Polaris-Link--removeUnderline {
  text-decoration: none;
}

.Polaris-Link--removeUnderline:hover {
  text-decoration: underline;
}

.Polaris-List {
  padding-left: var(--p-space-5);
  margin-top: 0;
  margin-bottom: 0;
  list-style: disc outside none;
}

.Polaris-List + .Polaris-List {
  margin-top: var(--p-space-4);
}

.Polaris-List--typeNumber {
  padding-left: var(--p-space-8);
  list-style: decimal outside none;
}

.Polaris-List__Item .Polaris-List:first-child {
  margin-top: var(--p-space-2);
}

.Polaris-List--spacingLoose .Polaris-List__Item {
  margin-bottom: var(--p-space-2);
}

.Polaris-List__Item:last-child {
  margin-bottom: 0;
}

.Polaris-MediaCard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.Polaris-MediaCard.Polaris-MediaCard--portrait {
  flex-flow: column nowrap;
}

@media (max-width: 47.9975em) {
  .Polaris-MediaCard {
    flex-flow: column nowrap;
  }
}

.Polaris-MediaCard__MediaContainer {
  overflow: hidden;
}

.Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 40%;
}

@media (min-width: 48em) {
  .Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
    border-top-right-radius: 0;
    border-top-left-radius: var(--p-border-radius-2);
    border-bottom-left-radius: var(--p-border-radius-2);
  }
}

.Polaris-MediaCard__MediaContainer:not(
    .Polaris-MediaCard--portrait
  ).Polaris-MediaCard--sizeSmall {
  flex-basis: 33%;
}

@media (min-width: 30.625em) {
  .Polaris-MediaCard__MediaContainer {
    border-top-left-radius: var(--p-border-radius-2);
    border-top-right-radius: var(--p-border-radius-2);
  }
}

.Polaris-MediaCard__InfoContainer {
  position: relative;
}

.Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 60%;
}

.Polaris-MediaCard__InfoContainer:not(
    .Polaris-MediaCard--portrait
  ).Polaris-MediaCard--sizeSmall {
  flex-basis: 67%;
}

.Polaris-MediaCard__Popover {
  position: absolute;
  z-index: var(--p-z-index-2);
  top: var(--p-space-4);
  right: var(--p-space-5);
}

.Polaris-MediaCard__Heading {
  margin-right: var(--p-space-8);
}

.Polaris-MediaCard__PrimaryAction {
  margin-right: var(--p-space-2);
}

.Polaris-MediaCard__SecondaryAction {
  margin-left: -var(--p-space-2);
}

.Polaris-MediaCard__ActionContainer {
  padding-top: var(--p-space-2);
}

.Polaris-MediaCard__ActionContainer.Polaris-MediaCard--portrait {
  padding-top: var(--p-space-8);
}

@media (max-width: 47.9975em) {
  .Polaris-MediaCard__ActionContainer {
    padding-top: var(--p-space-8);
  }
}

.Polaris-Navigation {
  --pc-navigation-icon-size: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - 4rem);
  min-width: 15rem;
  max-width: 22.5rem;
  height: 100%;
  min-height: 100%;
  background-color: var(--p-background);
  -webkit-overflow-scrolling: touch;
  /* border-right: var(--p-border-divider); */
  border-right: 1px solid var(--Neutral-Gray-Gray-50, #f0f1f3);
  box-shadow: 4px 0px 30px 0px rgba(131, 98, 234, 0.05);
  padding-bottom: 0;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
  background-color: var(--p-white-color);
}

.Polaris-Navigation:focus {
  outline: none;
}

@media (min-width: 48em) {
  .Polaris-Navigation {
    max-width: 15rem;
    max-width: 240px;
    max-width: calc(15rem + constant(safe-area-inset-left));
    max-width: calc(15rem + env(safe-area-inset-left));
  }
}

.Polaris-Navigation__UserMenu {
  flex: 0 0 auto;
}

.Polaris-Navigation__ContextControl {
  min-height: 3.5rem;
}

@media (min-width: 48em) {
  .Polaris-Navigation__ContextControl {
    display: none;
  }
}

.Polaris-Navigation__PrimaryNavigation {
  display: flex;
  overflow: auto;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
}

.Polaris-Navigation__PrimaryNavigation:focus {
  outline: none;
}

@media (min-width: 48em) {
  .Polaris-Navigation__PrimaryNavigation {
    padding-top: var(--p-space-4);
  }
}

.Polaris-Navigation__LogoContainer {
  display: none;
}

@media (max-width: 47.9975em) {
  .Polaris-Navigation__LogoContainer {
    display: flex;
    flex: 0 0 3.5rem;
    align-items: center;
    height: 3.5rem;
    padding: 0 var(--p-space-2) 0 var(--p-space-4);
    background-color: var(--p-surface);
    box-shadow: var(--p-shadow-sm);
    margin-bottom: var(--p-space-4);
    flex-basis: 3.5rem;
    flex-basis: calc(3.5rem + constant(safe-area-inset-left));
    flex-basis: calc(3.5rem + env(safe-area-inset-left));
    padding-left: var(--p-space-4);
    padding-left: calc(var(--p-space-4) + constant(safe-area-inset-left));
    padding-left: calc(var(--p-space-4) + env(safe-area-inset-left));
  }
}

.Polaris-Navigation__LogoContainer.Polaris-Navigation--hasLogoSuffix {
  gap: var(--p-space-2);
}

.Polaris-Navigation__Logo,
.Polaris-Navigation__LogoLink {
  display: block;
}

.Polaris-Navigation__Item {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-semibold);
  line-height: 2.25rem;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  padding: 0 var(--p-space-1) 0 var(--p-space-3);
  margin: 0;
  color: var(--p-text);
  text-decoration: none;
  text-align: left;
  position: relative;
  border-radius: var(--p-border-radius-1);
}

.Polaris-Navigation__Item:focus {
  outline: none;
}

.Polaris-Navigation__Item::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Navigation__Item:focus-visible {
  background: var(--p-background-hovered);
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Navigation__Item:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__Item:active,
.Polaris-Navigation__Item:active:hover {
  color: var(--p-text);
  background-color: var(--p-background-pressed);
}

.Polaris-Navigation__Item:active::after,
.Polaris-Navigation__Item:active:hover::after {
  content: none;
}

@media (min-width: 48em) {
  .Polaris-Navigation__Item {
    font-size: var(--p-font-size-200);
    font-weight: var(--p-font-weight-medium);
    line-height: var(--p-font-line-height-5);
    padding-left: var(--p-space-3);
    align-items: center;
  }
}

.Polaris-Navigation__Item::-moz-focus-inner {
  border: 0;
}

.Polaris-Navigation__Item svg,
.Polaris-Navigation__Item img {
  display: block;
  height: var(--p-space-5);
  width: var(--p-space-5);
}

.Polaris-Navigation__Item .Polaris-Navigation__Icon--resized svg,
.Polaris-Navigation__Item .Polaris-Navigation__Icon--resized img {
  margin: var(--p-space-05);
  height: var(--p-space-4);
  width: var(--p-space-4);
}

.Polaris-Navigation__Item:is(:hover, :focus-visible) {
  background: var(--p-background-hovered);
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Navigation__Item--selected {
  font-weight: var(--p-font-weight-semibold);
  position: relative;
  align-items: center;
  color: var(--p-action-primary);
  padding: 4px !important;
}

.Polaris-Navigation__Item--selected:hover {
  color: var(--p-action-primary);
  background-color: var(--p-background-selected);
}

.Polaris-Navigation__Item--selected::before {
  content: "";
  position: absolute;
  top: 0.0625rem;
  bottom: 0.0625rem;
  left: calc(var(--p-space-2) * -1);
  width: 0.1875rem;
  background-color: var(--p-action-primary);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-Navigation__Item--selected::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Navigation__Item--selected:hover,
.Polaris-Navigation__Item--selected:focus-visible {
  background-color: var(--p-background-hovered);
}

.Polaris-Navigation__Item--selected :focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
}

.Polaris-Navigation__Item--selected:active,
.Polaris-Navigation__Item--selected:active:hover {
  color: var(--p-text-primary);
  background-color: var(--p-background-pressed);
}

.Polaris-Navigation__Item--selected:active::after,
.Polaris-Navigation__Item--selected:active:hover::after {
  content: none;
}

@media (min-width: 48em) {
  .Polaris-Navigation__Item--selected {
    font-weight: var(--p-font-weight-medium);
  }
}

.Polaris-Navigation__Item--disabled {
  color: var(--p-text-disabled);
  opacity: 0.6;
}

.Polaris-Navigation__Item--disabled .Polaris-Navigation__Icon {
  opacity: 0.6;
}

.Polaris-Navigation__Badge {
  margin-left: var(--p-space-2);
  display: inline-flex;
  height: var(--p-font-line-height-2);
  margin-top: calc(var(--p-space-2) + var(--p-space-05));
  margin-right: var(--p-space-4);
}

@media (min-width: 48em) {
  .Polaris-Navigation__Badge {
    margin: var(--p-space-1);
    margin-right: var(--p-space-2);
  }
}

.Polaris-Navigation__ListItem--hasAction .Polaris-Navigation__Badge {
  margin-right: var(--p-space-5);
}

@media (min-width: 48em) {
  .Polaris-Navigation__ListItem--hasAction .Polaris-Navigation__Badge {
    margin-right: var(--p-space-3);
  }
}

.Polaris-Navigation__Icon {
  /* --pc-navigation-filter-icon: brightness(0) saturate(100%) invert(29%)
    sepia(88%) saturate(5732%) hue-rotate(265deg) brightness(90%) contrast(77%); */
  --pc-navigation-filter-icon-action-primary: brightness(0) saturate(100%)
    invert(20%) sepia(59%) saturate(5557%) hue-rotate(162deg) brightness(95%)
    contrast(101%);
  --pc-navigation-filter-icon-on-interactive: brightness(0) saturate(100%)
    invert(100%);
  flex-shrink: 0;
  /* width: 25px;
  height: 25px; */
  /* margin-top: calc(var(--p-space-2) + var(--p-space-05));
  margin-right: var(--p-space-3);
  margin-bottom: calc(var(--p-space-2) + var(--p-space-05)); */
}

.Polaris-Navigation__Icon img {
  width: 18px;
  height: 18px;
}

.Polaris-Navigation__ItemInnerWrapper--selected .Polaris-Navigation__Icon {
  img {
    filter: brightness(0) saturate(100%) invert(26%) sepia(51%) saturate(4560%)
      hue-rotate(261deg) brightness(87%) contrast(84%);
  }
}
/* 
@media (min-width: 48em) {
  .Polaris-Navigation__Icon {
    margin-top: var(--p-space-1);
    margin-right: var(--p-space-2);
    margin-bottom: var(--p-space-1);
  }
} */

.Polaris-Navigation__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation__Item:focus-visible .Polaris-Navigation__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon img,
.Polaris-Navigation__Item:focus-visible .Polaris-Navigation__Icon img {
  /* filter: var(--pc-navigation-filter-icon); */
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg,
.Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg,
.Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation--itemChildActive .Polaris-Navigation__Icon svg,
.Polaris-Navigation--itemChildActive:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation__Item--selected:focus-visible
  .Polaris-Navigation__Icon
  svg {
  fill: var(--p-action-primary);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon img,
.Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon img,
.Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon img,
.Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon img,
.Polaris-Navigation--itemChildActive .Polaris-Navigation__Icon img,
.Polaris-Navigation--itemChildActive:hover .Polaris-Navigation__Icon img,
.Polaris-Navigation__Item--selected:focus-visible
  .Polaris-Navigation__Icon
  img {
  /* filter: var(--pc-navigation-filter-icon-action-primary); */
}

.Polaris-Navigation__Icon svg {
  display: block;
}

.Polaris-Navigation__ListItem {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  outline: none;
}

.Polaris-Navigation__RollupSection .Polaris-Navigation__ListItem,
.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__ListItem {
  opacity: 1;
}

.Polaris-Navigation__ListItem:nth-child(1) {
  animation-delay: 0ms;
}

.Polaris-Navigation__ListItem:nth-child(2) {
  animation-delay: 50ms;
}

.Polaris-Navigation__ListItem:nth-child(3) {
  animation-delay: 100ms;
}

.Polaris-Navigation__ListItem:nth-child(4) {
  animation-delay: 150ms;
}

.Polaris-Navigation__ListItem:nth-child(5) {
  animation-delay: 200ms;
}

.Polaris-Navigation__ListItem:nth-child(6) {
  animation-delay: 250ms;
}

.Polaris-Navigation__ListItem:nth-child(7) {
  animation-delay: 300ms;
}

.Polaris-Navigation__ListItem:nth-child(8) {
  animation-delay: 350ms;
}

.Polaris-Navigation__ListItem:nth-child(9) {
  animation-delay: 400ms;
}

.Polaris-Navigation__ListItem:nth-child(10) {
  animation-delay: 450ms;
}

.Polaris-Navigation__ListItem:nth-child(11) {
  animation-delay: 500ms;
}

.Polaris-Navigation__ListItem:nth-child(12) {
  animation-delay: 550ms;
}

.Polaris-Navigation__ListItem:not(:first-child)
  .Polaris-Navigation__ItemInnerWrapper {
  /* border-top: var(--p-border-width-1) solid var(--p-background); */
}

.Polaris-Navigation__ListItem--hasAction .Polaris-Navigation__Item {
  max-width: calc(
    100% - var(--pc-navigation-icon-size) + var(--p-space-4) * 2 +
      var(--p-space-1)
  );
}

.Polaris-Navigation__ItemWrapper {
  width: 100%;
}

.Polaris-Navigation__ItemInnerWrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.Polaris-Navigation__ItemInnerWrapper.Polaris-Navigation__ItemInnerDisabled {
  pointer-events: none;
}

.Polaris-Navigation__ItemInnerWrapper--selected {
  background-color: var(--p-background-selected);
  padding: 0 0 0 8px;
}
.Polaris-Navigation__ItemInnerWrapper--selected:hover {
  background-color: var(--p-background-selected) !important;
}

.Polaris-Navigation__Text {
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-font-line-height-2);
  /* padding: 10 0px; */
  margin-top: calc(var(--p-space-2) + var(--p-space-05));
  margin-bottom: calc(var(--p-space-2) + var(--p-space-05));
}

@media (min-width: 48em) {
  .Polaris-Navigation__Text {
    line-height: var(--p-font-line-height-2);
    /* margin-top: var(--p-space-1);
    margin-bottom: var(--p-space-1); */
  }
}

.Polaris-Navigation__Text--truncated {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.Polaris-Navigation__SecondaryActions {
  display: flex;
  height: 2.5rem;
}

.Polaris-Navigation__SecondaryActions:last-child {
  margin-right: calc(var(--p-space-2) + var(--p-space-05));
}

@media (min-width: 48em) {
  .Polaris-Navigation__SecondaryActions {
    height: 1.75rem;
  }
}

.Polaris-Navigation__ItemWithFloatingActions {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}

.Polaris-Navigation__ItemWithFloatingActions
  .Polaris-Navigation__SecondaryActions {
  margin-right: 0;
}

@media (min-width: 48em) {
  .Polaris-Navigation--itemInnerWrapperDisplayActionsOnHover
    .Polaris-Navigation__SecondaryActions {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--p-background-hovered);
    visibility: hidden;
    opacity: 0;
    transition:
      opacity var(--p-duration-200) ease-in-out,
      visibility var(--p-duration-200) ease-in-out;
  }
  .Polaris-Navigation--itemInnerWrapperDisplayActionsOnHover
    .Polaris-Navigation__SecondaryActions::before {
    content: "";
    pointer-events: none;
    position: absolute;
    right: 100%;
    display: block;
    height: 100%;
    width: var(--p-space-8);
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      var(--p-background-hovered) var(--p-space-8)
    );
  }
  .Polaris-Navigation--itemInnerWrapperDisplayActionsOnHover:focus-within
    .Polaris-Navigation__SecondaryActions,
  .Polaris-Navigation--itemInnerWrapperDisplayActionsOnHover:hover
    .Polaris-Navigation__SecondaryActions {
    visibility: visible;
    opacity: 1;
  }
}

.Polaris-Navigation__SecondaryAction {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: var(--p-space-1) calc(var(--p-space-2) + var(--p-space-05));
  border-radius: var(--p-border-radius-1);
  position: relative;
}

.Polaris-Navigation__SecondaryAction:focus {
  outline: none;
}

.Polaris-Navigation__SecondaryAction:focus {
  outline: none;
}

.Polaris-Navigation__SecondaryAction svg {
  fill: var(--p-icon);
}

@media (min-width: 48em) {
  .Polaris-Navigation__SecondaryAction {
    height: 1.75rem;
    padding: var(--p-space-05);
  }
}

.Polaris-Navigation__SecondaryAction:hover svg,
.Polaris-Navigation__SecondaryAction:focus svg,
.Polaris-Navigation__SecondaryAction:active svg {
  fill: var(--p-icon-hovered);
}

@media (-ms-high-contrast: active) {
  .Polaris-Navigation__SecondaryAction:hover svg,
  .Polaris-Navigation__SecondaryAction:focus svg,
  .Polaris-Navigation__SecondaryAction:active svg {
    fill: var(--p-icon-on-interactive);
  }
}

.Polaris-Navigation__SecondaryAction::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Navigation__SecondaryAction:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
}

.Polaris-Navigation__SecondaryAction:active {
  background: var(--p-background-pressed);
}

.Polaris-Navigation__SecondaryAction:active::after {
  content: none;
}

.Polaris-Navigation__SecondaryAction:active svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Navigation__SecondaryAction:focus,
.Polaris-Navigation__SecondaryAction:active {
  outline: none;
}

.Polaris-Navigation__SecondaryNavigation {
  flex-basis: 100%;
  margin-left: 0;
  overflow-x: visible;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__List {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: var(--p-space-2);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
  font-size: 0.9375rem;
  font-weight: var(--p-font-weight-medium);
  line-height: 2.5rem;
  color: var(--p-text-subdued);
  padding-left: calc(var(--p-space-8) + var(--p-space-3));
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:hover {
  color: var(--p-text-subdued);
}

.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item:focus-visible {
  color: var(--p-text);
}

.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item:active::after,
.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item:active:hover::after {
  content: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active {
  color: var(--p-text-primary);
}

@media (min-width: 48em) {
  .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
    font-size: var(--p-font-size-100);
    line-height: 1;
    padding-left: var(--p-space-10);
  }
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text {
  margin-top: var(--p-space-1);
  margin-bottom: var(--p-space-1);
  line-height: var(--p-space-5);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected {
  color: var(--p-text-primary);
  position: relative;
}

.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item--selected::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item--selected:hover {
  color: var(--p-text-primary-hovered);
}

.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item--selected:focus-visible {
  color: var(--p-text-primary);
}

.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item--selected:active {
  color: var(--p-text-primary);
}

.Polaris-Navigation__SecondaryNavigation
  .Polaris-Navigation__Item--selected:active::after {
  content: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--disabled {
  font-weight: var(--p-font-weight-medium);
  color: var(--p-text-disabled);
}

.Polaris-Navigation__SecondaryNavigation--noIcon .Polaris-Navigation__Item {
  padding-left: var(--p-space-6);
}

.Polaris-Navigation__Section {
  flex: 0 0 auto;
  margin: 0;
  padding: var(--p-space-4) 0;
  padding-top: 0;
  padding-left: 0;
  padding-left: calc(constant(safe-area-inset-left));
  padding-left: calc(env(safe-area-inset-left));
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.Polaris-Navigation__Section + .Polaris-Navigation__Section {
  padding-top: var(--p-space-2);
  padding-bottom: var(--p-space-4);
}

.Polaris-Navigation__Section--fill {
  flex: 1 0 auto;
}

.Polaris-Navigation__Section--withSeparator {
  border-top: var(--p-border-divider);
}

.Polaris-Navigation__SectionHeading {
  text-transform: none;
  display: flex;
  align-items: center;
  padding-left: calc(var(--p-space-5) + var(--p-space-05));
}

.Polaris-Navigation__SectionHeading > :first-child {
  flex: 1 1 auto;
  margin-top: calc(var(--p-space-2) + var(--p-space-05));
  margin-bottom: calc(var(--p-space-2) + var(--p-space-05));
}

@media (max-width: 47.9975em) {
  .Polaris-Navigation__SectionHeading > :first-child {
    font-size: var(--p-font-size-100);
    line-height: var(--p-font-line-height-2);
  }
}

@media (min-width: 48em) {
  .Polaris-Navigation__SectionHeading > :first-child {
    margin-top: var(--p-space-1);
    margin-bottom: var(--p-space-1);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: var(--p-space-1);
  padding: var(--p-space-1) var(--p-space-4);
  border-radius: var(--p-border-radius-1);
  position: relative;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus {
  outline: none;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg {
  fill: var(--p-icon);
}

@media (min-width: 48em) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg,
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action img {
    height: var(--p-space-4);
    width: var(--p-space-4);
    margin: var(--p-space-05);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus {
  background: var(--p-background-hovered);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg {
  fill: var(--p-icon-hovered);
}

@media (-ms-high-contrast: active) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg,
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg {
    fill: var(--p-icon-on-interactive);
  }
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img,
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus img {
    filter: var(--p-filter-icon-on-interactive);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg {
  fill: var(--p-filter-icon);
}

.Polaris-Navigation__SectionHeading
  .Polaris-Navigation__Action:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__SectionHeading
  .Polaris-Navigation__Action:focus:hover
  svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover {
  background: var(--p-background-pressed);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active::after,
.Polaris-Navigation__SectionHeading
  .Polaris-Navigation__Action:active:hover::after {
  content: none;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active svg,
.Polaris-Navigation__SectionHeading
  .Polaris-Navigation__Action:active:hover
  svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus,
.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active {
  outline: none;
}

.Polaris-Navigation__RollupToggle {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  color: var(--p-text-subdued);
}

.Polaris-Navigation__RollupToggle:hover {
  color: var(--p-text-primary);
}

.Polaris-Navigation__RollupToggle:hover svg {
  fill: var(--p-action-primary);
}

.Polaris-Navigation__RollupToggle:hover img {
  /* filter: var(--p-filter-icon-action-primary); */
}

.Polaris-Navigation__RollupToggle:focus-visible {
  outline: none;
}

.Polaris-Navigation__RollupToggle:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__List {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Navigation__Indicator {
  position: relative;
  display: inline-block;
  height: 0.625rem;
  width: 0.625rem;
}

.Polaris-OptionList-Checkbox {
  position: relative;
  width: 100%;
  margin: var(--p-space-025);
}

.Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--active
  .Polaris-OptionList-Checkbox__Backdrop::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Checkbox__Input {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-OptionList-Checkbox__Input:focus-visible
  + .Polaris-OptionList-Checkbox__Backdrop::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled)
  + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox__Input:checked
  + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate
  + .Polaris-OptionList-Checkbox__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled)
  + .Polaris-OptionList-Checkbox__Backdrop::before,
.Polaris-OptionList-Checkbox__Input:checked
  + .Polaris-OptionList-Checkbox__Backdrop::before,
.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate
  + .Polaris-OptionList-Checkbox__Backdrop::before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .Polaris-OptionList-Checkbox__Input:active:not(:disabled)
    + .Polaris-OptionList-Checkbox__Backdrop::before,
  .Polaris-OptionList-Checkbox__Input:checked
    + .Polaris-OptionList-Checkbox__Backdrop::before,
  .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate
    + .Polaris-OptionList-Checkbox__Backdrop::before {
    border: var(--p-border-width-2) solid windowText;
  }
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled)
  ~ .Polaris-OptionList-Checkbox__Icon,
.Polaris-OptionList-Checkbox__Input:checked
  ~ .Polaris-OptionList-Checkbox__Icon,
.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate
  ~ .Polaris-OptionList-Checkbox__Icon {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  transition:
    opacity var(--p-duration-150) var(--p-ease),
    transform var(--p-duration-150) var(--p-ease);
}

.Polaris-OptionList-Checkbox__Input:disabled
  + .Polaris-OptionList-Checkbox__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-OptionList-Checkbox__Input:disabled
  + .Polaris-OptionList-Checkbox__Backdrop::before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-OptionList-Checkbox__Input:disabled
  + .Polaris-OptionList-Checkbox__Backdrop:hover {
  cursor: default;
}

.Polaris-OptionList-Checkbox__Input:disabled:checked
  + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox__Input:disabled:checked
  + .Polaris-OptionList-Checkbox__Backdrop::before {
  background: var(--p-border-disabled);
}

.Polaris-OptionList-Checkbox__Backdrop {
  position: relative;
  border: var(--p-border-width-2) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-1);
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.Polaris-OptionList-Checkbox__Backdrop::before {
  content: "";
  position: absolute;
  top: calc(var(--p-border-width-2) * -1);
  right: calc(var(--p-border-width-2) * -1);
  bottom: calc(var(--p-border-width-2) * -1);
  left: calc(var(--p-border-width-2) * -1);
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-interactive);
  opacity: 0;
  transform: scale(0.25);
  transition:
    opacity var(--p-duration-100) var(--p-ease),
    transform var(--p-duration-100) var(--p-ease);
}

.Polaris-OptionList-Checkbox__Backdrop.Polaris-OptionList-Checkbox--hover,
.Polaris-OptionList-Checkbox__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-OptionList-Checkbox__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  right: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  bottom: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  left: calc(var(--p-border-width-2) * -1 + -0.0625rem);
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-2) * -1 + -0.0625rem)
    var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-OptionList-Checkbox__Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(0.25);
  opacity: 0;
  transition:
    opacity var(--p-duration-100) var(--p-ease),
    transform var(--p-duration-100) var(--p-ease);
}

@media (-ms-high-contrast: active) {
  .Polaris-OptionList-Checkbox__Icon {
    fill: windowText;
  }
}

.Polaris-OptionList-Checkbox__Icon svg {
  fill: var(--p-icon-on-interactive);
}

.Polaris-OptionList-Option {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  width: 100%;
  min-height: 2.25rem;
  text-align: left;
  text-decoration: none;
  border-radius: var(--p-border-radius-1);
  margin-top: var(--p-space-1);
  color: inherit;
}

.Polaris-OptionList-Option:focus {
  outline: none;
}

.Polaris-OptionList-Option:visited {
  color: inherit;
}

.Polaris-OptionList-Option__SingleSelectOption {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  text-align: left;
  position: relative;
}

.Polaris-OptionList-Option__SingleSelectOption:focus {
  outline: none;
}

.Polaris-OptionList-Option__SingleSelectOption::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--focused:focus-visible:not(
    :active
  )::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active {
  background: var(--p-surface-selected);
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active::before,
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select::before {
  content: "";
  background-color: var(--p-interactive);
  position: absolute;
  top: 0;
  left: calc(var(--p-space-2) * -1);
  height: 100%;
  display: block;
  width: var(--p-border-width-3);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-OptionList-Option__SingleSelectOption:not(
    .Polaris-OptionList-Option--disabled
  ) {
  color: inherit;
}

.Polaris-OptionList-Option__SingleSelectOption
  .Polaris-OptionList-Option__Media {
  padding: 0 var(--p-space-2) 0 0;
}

.Polaris-OptionList-Option__Label,
.Polaris-OptionList-Option__SingleSelectOption {
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  border-radius: var(--p-border-radius-1);
  padding: var(--p-space-2);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Polaris-OptionList-Option__Label:hover:not(
    .Polaris-OptionList-Option--disabled
  ),
.Polaris-OptionList-Option__SingleSelectOption:hover:not(
    .Polaris-OptionList-Option--disabled
  ) {
  background: var(--p-surface-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select,
.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select:hover:not(
    .Polaris-OptionList-Option--disabled
  ),
.Polaris-OptionList-Option__Label:active:not(
    .Polaris-OptionList-Option--disabled
  ),
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select,
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select:hover:not(
    .Polaris-OptionList-Option--disabled
  ),
.Polaris-OptionList-Option__SingleSelectOption:active:not(
    .Polaris-OptionList-Option--disabled
  ) {
  background: var(--p-surface-selected);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--disabled,
.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--disabled {
  background: var(--p-surface-disabled);
  cursor: default;
  color: var(--p-text-disabled);
}

.Polaris-OptionList-Option__Checkbox {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: var(--p-space-2);
  margin-left: calc(var(--p-space-025) * -1);
}

.Polaris-OptionList-Option--disabled .Polaris-OptionList-Option__Media svg {
  fill: var(--p-icon-disabled);
}

.Polaris-OptionList-Option__Media svg {
  fill: var(--p-icon);
}

.Polaris-OptionList-Option--verticalAlignTop {
  align-items: flex-start;
}

.Polaris-OptionList-Option--verticalAlignCenter {
  align-items: center;
}

.Polaris-OptionList-Option--verticalAlignBottom {
  align-items: flex-end;
}

.Polaris-Header-Title {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-weight: var(--p-font-weight-medium);
  font-size: var(--p-font-size-500);
  line-height: var(--p-font-line-height-4);
  letter-spacing: 0.18px;
}

.Polaris-Header-Desc {
  margin-top: 4px;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-weight: var(--p-font-weight-regular);
  font-size: var(--p-font-size-250);
  line-height: var(--p-font-line-height-3);
  color: var(--font-grey, #777980);
}

.Polaris-Page-subTitle {
  font-size: var(--p-font-size-300);
  line-height: var(--p-font-line-height-3);
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-Header-Title__TitleWithSubtitle {
  margin-top: var(--p-space-1);
}

.Polaris-Header-Title__SubTitle {
  margin-top: var(--p-space-1);
  color: var(--p-text-subdued);
}

.Polaris-Header-Title__SubTitle.Polaris-Header-Title__SubtitleCompact {
  margin-top: 0;
}

.Polaris-Header-Title__TitleWithMetadataWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: var(--p-space-2);
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title {
  display: inline;
  margin-right: var(--p-space-2);
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title > * {
  display: inline;
}

.Polaris-Header-Title__TitleWithMetadataWrapper
  .Polaris-Header-Title__TitleMetadata {
  margin-top: 0;
  vertical-align: bottom;
}

.Polaris-Page-Header__TitleWrapper {
  grid-area: title;
  margin-top: var(--p-space-1);
  align-self: center;
  flex: 1 1 auto;
}

@media (min-width: 30.625em) {
  .Polaris-Page-Header__TitleWrapper {
    margin-top: 0;
  }
}

.Polaris-Page-Header__BreadcrumbWrapper {
  grid-area: breadcrumbs;
}

.Polaris-Page-Header__PaginationWrapper {
  margin-left: var(--p-space-1);
  line-height: 1;
}

.Polaris-Page-Header__PaginationWrapper button {
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued) !important;
  box-shadow: none !important;
}

.Polaris-Page-Header__PaginationWrapper button:hover,
.Polaris-Page-Header__PaginationWrapper button:active,
.Polaris-Page-Header__PaginationWrapper button:focus {
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued) !important;
}

.Polaris-Page-Header__PrimaryActionWrapper {
  margin-top: 0;
  margin-left: var(--p-space-1);
}

@media (min-width: 48em) {
  .Polaris-Page-Header__PrimaryActionWrapper {
    margin-left: var(--p-space-4);
  }
}

.Polaris-Page-Header__Row {
  display: flex;
  justify-content: space-between;
}

.Polaris-Page-Header__Row:first-child {
  min-height: 2.25rem;
}

.Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
  margin-top: var(--p-space-1);
}

.Polaris-Page-Header--mobileView
  .Polaris-Page-Header__Row
  + .Polaris-Page-Header__Row {
  margin-top: var(--p-space-2);
}

.Polaris-Page-Header__Row
  + .Polaris-Page-Header__Row
  .Polaris-Page-Header__RightAlign {
  margin-left: 0;
}

.Polaris-Page-Header__RightAlign {
  grid-area: actions;
  display: flex;
  align-content: flex-end;
  flex: 1 1 auto;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-end;
  margin-left: var(--p-space-4);
  white-space: nowrap;
}

@media (max-width: 30.6225em) {
  .Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__RightAlign {
    margin-left: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page-Header__AdditionalMetaData {
    margin-left: calc(
      var(--p-space-5) * 2 + var(--p-space-2) + var(--p-space-1)
    );
  }
}

.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__AdditionalMetaData {
  margin-left: 0;
}

.Polaris-Page-Header__Actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

@media (max-width: 64.9975em) {
  .Polaris-Page-Header--longTitle .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0;
  }
  .Polaris-Page-Header--longTitle .Polaris-Page-Header__Row {
    display: grid;
    gap: var(--p-space-2) var(--p-space-4);
    grid-template-columns: auto 1fr;
    grid-template-areas: "breadcrumbs actions" "title title";
  }
  .Polaris-Page-Header--longTitle
    .Polaris-Page-Header__Row
    + .Polaris-Page-Header__Row {
    gap: 0;
  }
}

@media (max-width: 47.9975em) {
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs)
    .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0;
  }
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs)
    .Polaris-Page-Header__Row {
    display: grid;
    gap: var(--p-space-2) var(--p-space-4);
    grid-template-columns: auto 1fr;
    grid-template-areas: "breadcrumbs actions" "title title";
  }
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs)
    .Polaris-Page-Header__Row
    + .Polaris-Page-Header__Row {
    gap: 0;
  }
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs
  .Polaris-Page-Header__TitleWrapper {
  margin-top: 0;
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs
  .Polaris-Page-Header__RightAlign {
  margin-bottom: var(--p-space-1);
}

@media (min-width: 48em) {
  .Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs
    .Polaris-Page-Header__RightAlign {
    margin-bottom: 0;
  }
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs
  .Polaris-Page-Header__Row {
  flex-wrap: wrap-reverse;
}

@media (min-width: 48em) {
  .Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs
    .Polaris-Page-Header__Row {
    flex-wrap: nowrap;
  }
}

.Polaris-Page-Header--isSingleRow .Polaris-Page-Header__Row {
  gap: 0;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

.Polaris-Page {
  margin: 0 auto;
  padding: 0;
  max-width: 62.375rem;
}

@media (min-width: 30.625em) {
  .Polaris-Page {
    padding: 0 var(--p-space-5);
  }
}

.Polaris-Page::after {
  content: "";
  display: table;
}

.Polaris-Page--fullWidth {
  max-width: 1366px;
}

.Polaris-Page--narrowWidth {
  max-width: 41.375rem;
}

.Polaris-Page__Content {
  /* padding: var(--p-space-2) 0; */
}

@media (min-width: 48em) {
  .Polaris-Page__Content {
    padding-top: 40px;
  }
}

.Polaris-Page--divider {
  padding-top: var(--p-space-7);
}

.Polaris-PageActions {
  margin: 0 auto;
  padding: var(--p-space-5);
  border-top: var(--p-border-divider);
}

@media (min-width: 30.625em) {
  .Polaris-PageActions {
    padding: var(--p-space-5) 0;
  }
}

.Polaris-ProgressBar {
  overflow: hidden;
  width: 100%;
  background-color: var(--pc-progress-bar-background);
  border-radius: var(--p-border-radius-1);
}

@media (forced-colors: active) {
  .Polaris-ProgressBar {
    border: var(--p-border-width-1) solid transparent;
  }
}

.Polaris-ProgressBar--sizeSmall {
  height: 0.5rem;
}

.Polaris-ProgressBar--sizeMedium {
  height: 1rem;
}

.Polaris-ProgressBar--sizeLarge {
  height: 2rem;
}

.Polaris-ProgressBar--colorHighlight {
  --pc-progress-bar-background: var(--p-surface-neutral);
  --pc-progress-bar-indicator: var(--p-border-highlight);
}

.Polaris-ProgressBar--colorPrimary {
  --pc-progress-bar-background: var(--p-surface-neutral);
  --pc-progress-bar-indicator: var(--p-action-primary);
}

.Polaris-ProgressBar--colorSuccess {
  --pc-progress-bar-background: var(--p-surface-neutral);
  --pc-progress-bar-indicator: var(--p-border-success);
}

.Polaris-ProgressBar--colorCritical {
  --pc-progress-bar-background: var(--p-surface-neutral);
  --pc-progress-bar-indicator: var(--p-interactive-critical);
}

.Polaris-ProgressBar__Indicator {
  height: inherit;
  background-color: var(--pc-progress-bar-indicator);
  transition: transform var(--pc-progress-bar-duration) var(--p-ease);
  transform: scaleX(0);
  transform-origin: 0 50%;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-ProgressBar__Indicator {
    border: 1rem solid highlight;
  }
}

.Polaris-ProgressBar__IndicatorAppearActive,
.Polaris-ProgressBar__IndicatorAppearDone {
  transform: scaleX(var(--pc-progress-bar-percent));
}

.Polaris-ProgressBar__Progress,
.Polaris-ProgressBar__Label {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-RangeSlider {
  --pc-range-slider-input: 10;
  --pc-range-slider-output: 20;
  --pc-range-slider-track-height: 0.25rem;
  --pc-range-slider-thumb-size: 1rem;
  --pc-track-dashed-border-radius: var(--p-border-radius-1);
}

.Polaris-RangeSlider-DualThumb__RangeSlider {
  --pc-range-slider-input: 10;
  --pc-range-slider-output: 20;
  --pc-range-slider-track-height: 0.25rem;
  --pc-range-slider-thumb-size: 1rem;
  --pc-track-dashed-border-radius: var(--p-border-radius-1);
}

.Polaris-RangeSlider-DualThumb {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.Polaris-RangeSlider-DualThumb__TrackWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 1.75rem;
  cursor: pointer;
}

.Polaris-RangeSlider-DualThumb__TrackWrapper.Polaris-RangeSlider-DualThumb--disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.Polaris-RangeSlider-DualThumb__Track {
  --pc-dual-thumb-unselected-range: transparent;
  --pc-dual-thumb-selected-range: var(--p-interactive);
  --pc-dual-thumb-gradient-colors: var(--pc-dual-thumb-unselected-range) 0%,
    var(--pc-dual-thumb-unselected-range) var(--pc-range-slider-progress-lower),
    var(--pc-dual-thumb-selected-range) var(--pc-range-slider-progress-lower),
    var(--pc-dual-thumb-selected-range) var(--pc-range-slider-progress-upper),
    var(--pc-dual-thumb-unselected-range) var(--pc-range-slider-progress-upper),
    var(--pc-dual-thumb-unselected-range) 100%;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: var(--pc-range-slider-track-height);
  border-radius: var(--pc-range-slider-thumb-size);
  background-image: linear-gradient(
    to right,
    var(--pc-dual-thumb-gradient-colors)
  );
}

.Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Track {
  --pc-dual-thumb-selected-range: var(--p-action-critical);
  --pc-dual-thumb-gradient-colors: var(--pc-dual-thumb-unselected-range) 0%,
    var(--pc-dual-thumb-unselected-range) var(--pc-range-slider-progress-lower),
    var(--pc-dual-thumb-selected-range) var(--pc-range-slider-progress-lower),
    var(--pc-dual-thumb-selected-range) var(--pc-range-slider-progress-upper),
    var(--pc-dual-thumb-unselected-range) var(--pc-range-slider-progress-upper),
    var(--pc-dual-thumb-unselected-range) 100%;
  background-image: linear-gradient(
    to right,
    var(--pc-dual-thumb-gradient-colors)
  );
}

.Polaris-RangeSlider-DualThumb--disabled .Polaris-RangeSlider-DualThumb__Track {
  background: var(--p-border-disabled) none;
}

.Polaris-RangeSlider-DualThumb--trackDashed {
  content: "";
  position: absolute;
  height: var(--pc-range-slider-track-height);
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--p-border),
    var(--p-border) 50%,
    transparent 50%,
    transparent 100%
  );
  background-size: var(--pc-range-slider-track-height)
    var(--pc-range-slider-track-height);
  border-radius: var(--pc-track-dashed-border-radius);
  border-right: var(--pc-track-dashed-border-radius) var(--p-border) solid;
}

.Polaris-RangeSlider-DualThumb__Thumbs {
  position: relative;
  position: absolute;
  z-index: var(--pc-range-slider-input);
  padding: 0;
  width: var(--pc-range-slider-thumb-size);
  height: var(--pc-range-slider-thumb-size);
  border-radius: var(--p-border-radius-full);
  border: var(--p-border-width-1) solid var(--p-interactive);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  -webkit-tap-highlight-color: transparent;
  cursor: -webkit-grab;
  transition: transform var(--p-duration-150) var(--p-ease);
}

.Polaris-RangeSlider-DualThumb__Thumbs::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-2);
}

.Polaris-RangeSlider-DualThumb__Thumbs.Polaris-RangeSlider-DualThumb--disabled {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-DualThumb__Thumbs:active {
  transform: scale(1.5);
}

.Polaris-RangeSlider-DualThumb__Thumbs:focus-visible {
  outline: 0;
}

.Polaris-RangeSlider-DualThumb__Thumbs:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Thumbs {
  border-color: var(--p-action-critical);
  background: linear-gradient(
    var(--p-action-critical),
    var(--p-action-critical)
  );
}

.Polaris-RangeSlider-DualThumb__Prefix {
  flex: 0 0 auto;
  margin-right: var(--p-space-2);
}

.Polaris-RangeSlider-DualThumb__Suffix {
  flex: 0 0 auto;
  margin-left: var(--p-space-2);
}

.Polaris-RangeSlider-DualThumb__Output {
  --pc-range-slider-output-spacing: var(--p-space-4);
  position: absolute;
  z-index: var(--pc-range-slider-output);
  bottom: 1.5rem;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility, bottom;
  transition-duration: var(--p-duration-150);
  transition-timing-function: var(--p-ease);
  transform: translateX(calc(-50% + var(--pc-range-slider-thumb-size) / 2));
}

.Polaris-RangeSlider-DualThumb__Thumbs:active
  + .Polaris-RangeSlider-DualThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: 2rem;
}

.Polaris-RangeSlider-DualThumb__OutputBubble {
  position: relative;
  display: flex;
  padding: 0 var(--p-space-2);
  min-width: 2rem;
  height: 2rem;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-xl);
  border-radius: var(--p-border-radius-1);
  transition-property: transform;
  transition-duration: var(--p-duration-150);
  transition-timing-function: var(--p-ease);
}

.Polaris-RangeSlider-DualThumb__Thumbs:hover
  + .Polaris-RangeSlider-DualThumb__Output
  .Polaris-RangeSlider-DualThumb__OutputBubble,
.Polaris-RangeSlider-DualThumb__Thumbs:active
  + .Polaris-RangeSlider-DualThumb__Output
  .Polaris-RangeSlider-DualThumb__OutputBubble,
.Polaris-RangeSlider-DualThumb__Thumbs:focus
  + .Polaris-RangeSlider-DualThumb__Output
  .Polaris-RangeSlider-DualThumb__OutputBubble {
  transform: translateY(calc(var(--pc-range-slider-output-spacing) * -1));
}

@media (min-width: 48em) {
  .Polaris-RangeSlider-DualThumb__Thumbs:hover
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble,
  .Polaris-RangeSlider-DualThumb__Thumbs:active
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble,
  .Polaris-RangeSlider-DualThumb__Thumbs:focus
    + .Polaris-RangeSlider-DualThumb__Output
    .Polaris-RangeSlider-DualThumb__OutputBubble {
    transform: translateY(
      calc((var(--pc-range-slider-output-spacing) * 0.5) * -1)
    );
  }
}

.Polaris-RangeSlider-DualThumb__OutputBubble > :first-child {
  display: block;
  flex: 1 1 auto;
  margin: auto;
}

.Polaris-RangeSlider-SingleThumb__RangeSlider {
  --pc-range-slider-input: 10;
  --pc-range-slider-output: 20;
  --pc-range-slider-track-height: 0.25rem;
  --pc-range-slider-thumb-size: 1rem;
  --pc-track-dashed-border-radius: var(--p-border-radius-1);
}

.Polaris-RangeSlider-SingleThumb {
  display: flex;
  align-items: center;
}

.Polaris-RangeSlider-SingleThumb.Polaris-RangeSlider-SingleThumb--disabled {
  opacity: 0.8;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: var(--pc-range-slider-thumb-size);
}

.Polaris-RangeSlider-SingleThumb__InputWrapper input {
  padding: var(--p-space-3) 0;
  background-color: transparent;
  cursor: pointer;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper::after {
  content: "";
  position: absolute;
  height: var(--pc-range-slider-track-height);
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--p-border),
    var(--p-border) 50%,
    transparent 50%,
    transparent 100%
  );
  background-size: var(--pc-range-slider-track-height)
    var(--pc-range-slider-track-height);
  border-radius: var(--pc-track-dashed-border-radius);
  border-right: var(--pc-track-dashed-border-radius) var(--p-border) solid;
}

@media (max-width: 30.6225em) {
  .Polaris-RangeSlider-SingleThumb__InputWrapper {
    height: 2.75rem;
  }
}

.Polaris-RangeSlider-SingleThumb--disabled input {
  cursor: not-allowed;
}

.Polaris-RangeSlider-SingleThumb__Prefix {
  flex: 0 0 auto;
  margin-right: var(--p-space-2);
}

.Polaris-RangeSlider-SingleThumb__Suffix {
  flex: 0 0 auto;
  margin-left: var(--p-space-2);
}

.Polaris-RangeSlider-SingleThumb__Input {
  --pc-single-thumb-progress-lower: var(--p-interactive);
  --pc-single-thumb-progress-upper: transparent;
  --pc-single-thumb-gradient-colors: var(--pc-single-thumb-progress-lower) 0%,
    var(--pc-single-thumb-progress-lower) var(--pc-range-slider-progress),
    var(--pc-single-thumb-progress-upper) var(--pc-range-slider-progress),
    var(--pc-single-thumb-progress-upper) 100%;
  position: relative;
  z-index: var(--pc-range-slider-input);
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-tooltip {
  display: none;
}

.Polaris-RangeSlider-SingleThumb__Input:focus {
  outline: 0;
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-focus-outer {
  border: 0;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-track {
  outline: var(--p-border-width-1) solid transparent;
  cursor: pointer;
  width: 100%;
  height: var(--pc-range-slider-track-height);
  background-image: linear-gradient(
    to right,
    var(--pc-single-thumb-gradient-colors)
  );
  border: none;
  border-radius: var(--pc-range-slider-track-height);
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-track {
  cursor: pointer;
  width: 100%;
  height: var(--pc-range-slider-track-height);
  background-image: linear-gradient(
    to right,
    var(--pc-single-thumb-gradient-colors)
  );
  border: none;
  border-radius: var(--pc-range-slider-track-height);
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track {
  cursor: pointer;
  width: 100%;
  height: var(--pc-range-slider-track-height);
  background-image: linear-gradient(
    to right,
    var(--pc-single-thumb-gradient-colors)
  );
  border: none;
  border-radius: var(--pc-range-slider-track-height);
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  cursor: -webkit-grab;
  width: var(--pc-range-slider-thumb-size);
  height: var(--pc-range-slider-thumb-size);
  border: var(--p-border-transparent);
  border-radius: var(--p-border-radius-full);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  appearance: none;
  -ms-transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  -ms-transition-property: border-color, box-shadow, transform;
  transition-property: border-color, box-shadow, transform;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  margin-top: calc(
    (var(--pc-range-slider-thumb-size) - var(--pc-range-slider-track-height)) *
      -1 / 2
  );
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  cursor: -webkit-grab;
  width: var(--pc-range-slider-thumb-size);
  height: var(--pc-range-slider-thumb-size);
  border: var(--p-border-transparent);
  border-radius: var(--p-border-radius-full);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  appearance: none;
  -moz-transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  -moz-transition-property: border-color, box-shadow, transform;
  transition-property: border-color, box-shadow, transform;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  margin-top: calc(
    (var(--pc-range-slider-thumb-size) - var(--pc-range-slider-track-height)) *
      -1 / 2
  );
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  cursor: -webkit-grab;
  width: var(--pc-range-slider-thumb-size);
  height: var(--pc-range-slider-thumb-size);
  border: var(--p-border-transparent);
  border-radius: var(--p-border-radius-full);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  -webkit-appearance: none;
  appearance: none;
  -webkit-transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  -webkit-transition-property: border-color, box-shadow, transform;
  transition-property: border-color, box-shadow, transform;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  margin-top: calc(
    (var(--pc-range-slider-thumb-size) - var(--pc-range-slider-track-height)) *
      -1 / 2
  );
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  margin-top: 0;
  transform: translateY(calc(var(--pc-range-slider-thumb-size) * 0.2))
    scale(0.4);
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  margin-top: calc(
    (var(--pc-range-slider-thumb-size) - var(--pc-range-slider-track-height)) *
      -0.5
  );
}

.Polaris-RangeSlider-SingleThumb__Input:active::-ms-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-moz-range-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-webkit-slider-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:focus {
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-ms-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 var(--p-border-width-2) var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-moz-range-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 var(--p-border-width-2) var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-webkit-slider-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 var(--p-border-width-2) var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb--error
  .Polaris-RangeSlider-SingleThumb__Input {
  --pc-single-thumb-progress-lower: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error
  .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error
  .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error
  .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--disabled
  .Polaris-RangeSlider-SingleThumb__Input::-ms-track {
  outline: var(--p-border-width-1) solid transparent;
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled
  .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled
  .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled
  .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled
  .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled
  .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb__Output {
  --pc-range-slider-output-spacing: var(--p-space-4);
  position: absolute;
  z-index: var(--pc-range-slider-output);
  bottom: var(--pc-range-slider-thumb-size);
  left: var(--pc-range-slider-progress);
  transform: translateX(
    calc(
      -50% + var(--pc-range-slider-output-factor) * var(--pc-range-slider-thumb-size)
    )
  );
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility, bottom;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
}

.Polaris-RangeSlider-SingleThumb__Input:active
  + .Polaris-RangeSlider-SingleThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: calc(var(--pc-range-slider-thumb-size) + 0.5rem);
}

.Polaris-RangeSlider-SingleThumb__OutputBubble {
  position: relative;
  display: flex;
  box-shadow: var(--p-shadow-xl);
  padding: 0 var(--p-space-2);
  min-width: 2rem;
  height: 2rem;
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-1);
  transition-property: transform;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-RangeSlider-SingleThumb__Input:hover
  + .Polaris-RangeSlider-SingleThumb__Output
  .Polaris-RangeSlider-SingleThumb__OutputBubble,
.Polaris-RangeSlider-SingleThumb__Input:active
  + .Polaris-RangeSlider-SingleThumb__Output
  .Polaris-RangeSlider-SingleThumb__OutputBubble,
.Polaris-RangeSlider-SingleThumb__Input:focus
  + .Polaris-RangeSlider-SingleThumb__Output
  .Polaris-RangeSlider-SingleThumb__OutputBubble {
  transform: translateY(calc(var(--pc-range-slider-output-spacing) * -1));
}

@media (min-width: 48em) {
  .Polaris-RangeSlider-SingleThumb__Input:hover
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble,
  .Polaris-RangeSlider-SingleThumb__Input:active
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble,
  .Polaris-RangeSlider-SingleThumb__Input:focus
    + .Polaris-RangeSlider-SingleThumb__Output
    .Polaris-RangeSlider-SingleThumb__OutputBubble {
    transform: translateY(
      calc((var(--pc-range-slider-output-spacing) * 0.4) * -1)
    );
  }
}

.Polaris-RangeSlider-SingleThumb__OutputBubble > :first-child {
  display: block;
  flex: 1 1 auto;
  margin: auto;
}

.Polaris-ResourceItem {
  --pc-resource-item-min-height: 2.75rem;
  --pc-resource-item-disclosure-width: 3rem;
  --pc-resource-item-offset: 2.375rem;
  --pc-resource-item-clickable-stacking-order: 1;
  --pc-resource-item-content-stacking-order: 2;
  outline: none;
  cursor: pointer;
}

.Polaris-ResourceItem:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-ResourceItem:hover .Polaris-ResourceItem__Actions > * {
  clip: auto;
  overflow: visible;
}

.Polaris-ResourceItem:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-ResourceItem__ItemWrapper {
  overflow: hidden;
  max-width: 100%;
}

.Polaris-ResourceItem--focusedInner,
.Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused,
.Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused.Polaris-ResourceItem--selected {
  box-shadow: none;
}

.Polaris-ResourceItem__Link,
.Polaris-ResourceItem__Button {
  position: absolute;
  z-index: var(--pc-resource-item-clickable-stacking-order);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.Polaris-ResourceItem__Button {
  padding: 0;
  border: none;
}

.Polaris-ResourceItem--selectable {
  width: calc(100% + var(--pc-resource-item-offset));
  transform: translateX(calc(var(--pc-resource-item-offset) * -1));
  transition: transform var(--p-ease) var(--p-duration-200);
  margin-right: calc(var(--pc-resource-item-offset) * -1);
}

.Polaris-ResourceItem--selectable.Polaris-ResourceItem--selectMode {
  transform: translateX(0);
}

@media (min-width: 30.625em) {
  .Polaris-ResourceItem--selectable {
    width: 100%;
    transform: translateX(0);
    margin-right: 0;
  }
}

.Polaris-ResourceItem__Actions > * {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.Polaris-ResourceItem--focused .Polaris-ResourceItem__Actions > * {
  clip: auto;
  overflow: visible;
}

.Polaris-ResourceItem--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-ResourceItem--selected:hover {
  background-color: var(--p-surface-selected-hovered);
}

.Polaris-ResourceItem--selected:active {
  background-color: var(--p-surface-selected-pressed);
}

.Polaris-ResourceItem__ListItem {
  position: relative;
}

.Polaris-ResourceItem__ListItem::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  left: 0rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 0rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-ResourceItem__ListItem + .Polaris-ResourceItem__ListItem {
  border-top: var(--p-border-divider);
}

.Polaris-ResourceItem__ListItem::after {
  border-radius: var(--p-border-radius-05);
}

.Polaris-ResourceItem__ListItem:last-of-type {
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-ResourceItem__ListItem:last-of-type
  .Polaris-ResourceItem__ItemWrapper {
  border-radius: inherit;
}

.Polaris-ResourceItem__ListItem:last-of-type.Polaris-ResourceItem--focused::after {
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-ResourceItem__ListItem.Polaris-ResourceItem--focused {
  z-index: var(--pc-resource-item-clickable-stacking-order);
}

.Polaris-ResourceItem__ListItem.Polaris-ResourceItem--focused::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

*
  + ul
  > .Polaris-ResourceItem__ListItem:first-of-type.Polaris-ResourceItem--focused::after {
  top: 0.0625rem;
}

.Polaris-Select {
  --pc-select-backdrop: 10;
  --pc-select-content: 20;
  --pc-select-input: 30;
  position: relative;
}

.Polaris-Select select::-ms-expand {
  display: none;
}

.Polaris-Select--disabled .Polaris-Select__Content {
  color: var(--p-text-disabled);
}

.Polaris-Select--disabled .Polaris-Select__InlineLabel {
  color: inherit;
}

.Polaris-Select--disabled .Polaris-Select__Icon svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop::before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop:hover {
  cursor: default;
}

.Polaris-Select__Content {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-3);
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  z-index: var(--pc-select-content);
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2.25rem;
  padding: calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2)
    var(--p-space-2)
    calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2)
    var(--p-space-3);
}

@media (min-width: 48em) {
  .Polaris-Select__Content {
    font-size: var(--p-font-size-100);
  }
}

@media (max-width: 47.9975em) {
  .Polaris-Select__Content div > span {
    font-size: var(--p-font-size-200);
    line-height: var(--p-font-line-height-2);
  }
}

.Polaris-Select__SelectedOption {
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Polaris-Select__Prefix {
  padding-right: var(--p-space-2);
}

.Polaris-Select__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Select__Input {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  position: absolute;
  text-rendering: auto;
  top: 0;
  left: 0;
  z-index: var(--pc-select-input);
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

@media (min-width: 48em) {
  .Polaris-Select__Input {
    font-size: var(--p-font-size-100);
    line-height: var(--p-font-line-height-2);
  }
}

.Polaris-Select__Backdrop {
  z-index: var(--pc-select-backdrop);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: var(--p-border-width-1) solid var(--p-border-shadow);
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-sm);
  position: relative;
  position: absolute;
}

.Polaris-Select__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Select--error .Polaris-Select__Backdrop {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-Select--error .Polaris-Select__Backdrop.Polaris-Select--hover,
.Polaris-Select--error .Polaris-Select__Backdrop:hover {
  border-color: var(--p-border-critical);
}

.Polaris-Select--error
  .Polaris-Select__Input:focus-visible
  ~ .Polaris-Select__Backdrop::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Select__Input:focus-visible ~ .Polaris-Select__Backdrop::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

@media (-ms-high-contrast: active) {
  .Polaris-Select__Content {
    color: windowText;
    -ms-high-contrast-adjust: none;
  }
  .Polaris-Select__InlineLabel {
    color: inherit;
  }
  .Polaris-Select__InlineLabel::after {
    content: ":";
  }
  .Polaris-Select__SelectedOption {
    color: inherit;
  }
  .Polaris-Select__Icon svg {
    fill: buttonText;
  }
  .Polaris-Select__Backdrop::after {
    display: none;
  }
  .Polaris-Select__Input:focus ~ .Polaris-Select__Content {
    color: highlightText;
  }
  .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop {
    background-color: highlight;
  }
  .Polaris-Select--disabled .Polaris-Select__Content {
    color: grayText;
  }
  .Polaris-Select--disabled .Polaris-Select__Icon {
    opacity: 1;
  }
  .Polaris-Select--disabled .Polaris-Select__Icon svg {
    fill: grayText;
  }
}

.Polaris-ResourceList__FiltersWrapper {
  padding: var(--p-space-3) var(--p-space-3) var(--p-space-4);
}

@media (min-width: 30.625em) {
  .Polaris-ResourceList__FiltersWrapper {
    padding: var(--p-space-4);
  }
}

.Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList {
  border-top: var(--p-border-divider);
}

.Polaris-ResourceList__HeaderOuterWrapper {
  position: relative;
  background-color: var(--p-surface);
  z-index: 2;
  border-top-left-radius: var(--p-border-radius-2);
  border-top-right-radius: var(--p-border-radius-2);
}

.Polaris-ResourceList__HeaderOuterWrapper + .Polaris-ResourceList {
  border-top: var(--p-border-divider);
}

.Polaris-ResourceList__HeaderWrapper--disabled {
  pointer-events: none;
}

.Polaris-ResourceList__HeaderWrapper--overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: var(--p-overlay);
}

.Polaris-ResourceList__FiltersWrapper
  + .Polaris-ResourceList__HeaderOuterWrapper {
  margin-top: calc(var(--p-space-4) * -1);
}

.Polaris-ResourceList__HeaderWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 3.5rem;
  padding: calc(var(--p-space-3) - var(--p-space-05)) var(--p-space-5);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-2);
}

.Polaris-ResourceList__HeaderWrapper--isSticky {
  box-shadow: var(--p-shadow-md);
}

.Polaris-ResourceList__HeaderContentWrapper {
  position: absolute;
  z-index: 1;
  right: var(--p-space-5);
  left: var(--p-space-5);
  display: flex;
  min-height: 2.25rem;
  opacity: 1;
  transition: opacity var(--p-ease) var(--p-duration-200);
}

.Polaris-ResourceList__HeaderWrapper--inSelectMode
  .Polaris-ResourceList__HeaderContentWrapper {
  opacity: 0;
}

.Polaris-ResourceList__SortWrapper,
.Polaris-ResourceList__AlternateToolWrapper {
  position: relative;
  display: flex;
  flex: 1 1;
  align-items: center;
}

.Polaris-ResourceList__HeaderWrapper--hasSelect
  .Polaris-ResourceList__SortWrapper,
.Polaris-ResourceList__HeaderWrapper--hasSelect
  .Polaris-ResourceList__AlternateToolWrapper {
  padding-right: var(--p-space-2);
}

@media (min-width: 30.625em) {
  .Polaris-ResourceList__SortWrapper,
  .Polaris-ResourceList__AlternateToolWrapper {
    position: relative;
    left: auto;
    flex: 0 1 auto;
    margin-left: var(--p-space-4);
  }
  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__SortWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__SortWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__AlternateToolWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__AlternateToolWrapper {
    padding-right: 0;
  }
}

.Polaris-ResourceList__SortWrapper {
  min-width: 0;
  max-width: 100%;
}

.Polaris-ResourceList__SortWrapper > * {
  max-width: 100%;
}

.Polaris-ResourceList__HeaderTitleWrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1;
  align-self: center;
}

.Polaris-ResourceList__HeaderWrapper--hasAlternateTool
  .Polaris-ResourceList__HeaderTitleWrapper,
.Polaris-ResourceList__HeaderWrapper--hasSort
  .Polaris-ResourceList__HeaderTitleWrapper {
  display: none;
}

@media (min-width: 30.625em) {
  .Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__HeaderTitleWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__HeaderTitleWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__HeaderTitleWrapper {
    display: none;
  }
  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool
    .Polaris-ResourceList__HeaderTitleWrapper,
  .Polaris-ResourceList__HeaderWrapper--hasSort
    .Polaris-ResourceList__HeaderTitleWrapper {
    display: block;
  }
}

.Polaris-ResourceList__BulkActionsWrapper {
  z-index: 2;
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--p-space-4);
  pointer-events: none;
}

@media (min-width: 30.625em) {
  .Polaris-ResourceList__BulkActionsWrapper {
    flex: 0 1 auto;
    align-self: flex-start;
  }
}

.Polaris-ResourceList__BulkActionsWrapperSticky {
  position: fixed;
  top: auto;
  bottom: calc(var(--p-space-10) - var(--p-space-1));
}

.Polaris-ResourceList__SelectAllActionsWrapper {
  position: relative;
  z-index: 2;
  width: 100%;
}

@media (min-width: 30.625em) {
  .Polaris-ResourceList__SelectAllActionsWrapper {
    flex: 0 1 auto;
    align-self: flex-start;
  }
}

.Polaris-ResourceList__CheckableButtonWrapper {
  display: none;
}

@media (min-width: 30.625em) {
  .Polaris-ResourceList__CheckableButtonWrapper {
    flex: 1 1;
    display: block;
  }
}

.Polaris-ResourceList__SelectButtonWrapper {
  position: relative;
  flex: none;
}

@media (min-width: 30.625em) {
  .Polaris-ResourceList__SelectButtonWrapper {
    display: none;
  }
}

.Polaris-ResourceList__EmptySearchResultWrapper {
  padding-top: var(--p-space-8);
  padding-bottom: var(--p-space-8);
}

@media (min-height: 37.5em) {
  .Polaris-ResourceList__EmptySearchResultWrapper {
    padding-top: var(--p-space-16);
    padding-bottom: var(--p-space-16);
  }
}

.Polaris-ResourceList__ResourceListWrapper {
  position: relative;
}

.Polaris-ResourceList__ResourceListWrapperWithBulkActions {
  --pc-resource-list-bulk-actions-offset: 5.75rem;
  padding-bottom: var(--pc-resource-list-bulk-actions-offset);
}

.Polaris-ResourceList {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ResourceList__ItemWrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  max-width: 100%;
}

.Polaris-ResourceList__ItemWrapper + .Polaris-ResourceList__ItemWrapper {
  border-top: var(--p-border-divider);
}

.Polaris-ResourceList__ItemWrapper--isLoading {
  min-height: 4rem;
}

.Polaris-ResourceList__SpinnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
}

.Polaris-ResourceList__LoadingOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  background-color: var(--p-overlay);
}

.Polaris-ResourceList__DisabledPointerEvents {
  pointer-events: none;
}

.Polaris-ResourceList--disableTextSelection {
  -webkit-user-select: none;
  user-select: none;
}

.Polaris-SkeletonBodyText {
  height: var(--p-space-2);
  display: flex;
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-1);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonBodyText {
    background-color: grayText;
  }
}

.Polaris-SkeletonBodyText:last-child:not(:first-child) {
  width: 80%;
}

.Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText {
  margin-top: var(--p-space-3);
}

.Polaris-SkeletonDisplayText__DisplayText {
  --pc-skeleton-display-text-height: var(--p-font-line-height-2);
  --pc-skeleton-display-text-height-not-condensed: var(--p-font-line-height-2);
  max-width: 7.5rem;
  display: flex;
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-1);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonDisplayText__DisplayText {
    background-color: grayText;
  }
}

.Polaris-SkeletonDisplayText--sizeSmall {
  --pc-skeleton-display-text-height: var(--p-font-line-height-3);
  --pc-skeleton-display-text-height-not-condensed: var(--p-font-line-height-4);
  height: var(--pc-skeleton-display-text-height);
}

@media (min-width: 48em) {
  .Polaris-SkeletonDisplayText--sizeSmall {
    height: var(--pc-skeleton-display-text-height-not-condensed);
  }
}

.Polaris-SkeletonDisplayText--sizeMedium {
  --pc-skeleton-display-text-height: var(--p-font-line-height-4);
  --pc-skeleton-display-text-height-not-condensed: var(--p-font-line-height-5);
  height: var(--pc-skeleton-display-text-height);
}

@media (min-width: 48em) {
  .Polaris-SkeletonDisplayText--sizeMedium {
    height: var(--pc-skeleton-display-text-height-not-condensed);
  }
}

.Polaris-SkeletonDisplayText--sizeLarge {
  --pc-skeleton-display-text-height: var(--p-font-line-height-4);
  --pc-skeleton-display-text-height-not-condensed: var(--p-font-line-height-5);
  height: var(--pc-skeleton-display-text-height);
}

@media (min-width: 48em) {
  .Polaris-SkeletonDisplayText--sizeLarge {
    height: var(--pc-skeleton-display-text-height-not-condensed);
  }
}

.Polaris-SkeletonDisplayText--sizeExtraLarge {
  --pc-skeleton-display-text-height: 2.25rem;
  --pc-skeleton-display-text-height-not-condensed: 2.75rem;
  height: var(--pc-skeleton-display-text-height);
}

@media (min-width: 48em) {
  .Polaris-SkeletonDisplayText--sizeExtraLarge {
    height: var(--pc-skeleton-display-text-height-not-condensed);
  }
}

:root {
  --pc-skeleton-page-max-width: 62.375rem;
  --pc-skeleton-page-max-width-narrow: 41.375rem;
}

.Polaris-SkeletonPage__Title {
  font-weight: var(--p-font-weight-semibold);
  font-size: var(--p-font-size-300);
  line-height: var(--p-font-line-height-4);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonPage__SkeletonTitle {
    background-color: grayText;
  }
}

.Polaris-SkeletonTabs__Tabs {
  display: flex;
  width: 100%;
  border-bottom: var(--p-border-divider);
}

.Polaris-SkeletonTabs__Tab {
  position: relative;
  padding: calc(var(--p-space-5) + var(--p-space-05)) var(--p-space-4);
}

.Polaris-SkeletonTabs__Tab:first-child::before {
  background-color: var(--p-border-hovered);
}

.Polaris-SkeletonTabs__Tab::before {
  content: "";
  position: absolute;
  bottom: -0.0625rem;
  left: var(--p-space-3);
  right: var(--p-space-3);
  height: var(--p-border-width-3);
  border-top-left-radius: var(--p-border-radius-1);
  border-top-right-radius: var(--p-border-radius-1);
}

.Polaris-SkeletonTabs__Tab--short {
  width: 5rem;
}

.Polaris-SkeletonTabs__Tab--long {
  width: 6.25rem;
}

.Polaris-SkeletonThumbnail {
  --pc-skeleton-thumbnail-extra-small-size: 1.5rem;
  --pc-skeleton-thumbnail-small-size: 2.5rem;
  --pc-skeleton-thumbnail-medium-size: 3.75rem;
  --pc-skeleton-thumbnail-large-size: 5rem;
  display: flex;
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-1);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonThumbnail {
    background-color: grayText;
  }
}

.Polaris-SkeletonThumbnail--sizeExtraSmall {
  height: var(--pc-skeleton-thumbnail-extra-small-size);
  width: var(--pc-skeleton-thumbnail-extra-small-size);
}

.Polaris-SkeletonThumbnail--sizeSmall {
  height: var(--pc-skeleton-thumbnail-small-size);
  width: var(--pc-skeleton-thumbnail-small-size);
}

.Polaris-SkeletonThumbnail--sizeMedium {
  height: var(--pc-skeleton-thumbnail-medium-size);
  width: var(--pc-skeleton-thumbnail-medium-size);
}

.Polaris-SkeletonThumbnail--sizeLarge {
  height: var(--pc-skeleton-thumbnail-large-size);
  width: var(--pc-skeleton-thumbnail-large-size);
}

.Polaris-Stack {
  --pc-stack-spacing: var(--p-space-4);
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: calc(var(--pc-stack-spacing) * -1);
  margin-left: calc(var(--pc-stack-spacing) * -1);
}

.Polaris-Stack > .Polaris-Stack__Item {
  margin-top: var(--pc-stack-spacing);
  margin-left: var(--pc-stack-spacing);
  max-width: 100%;
}

.Polaris-Stack--noWrap {
  flex-wrap: nowrap;
}

.Polaris-Stack--spacingNone {
  --pc-stack-spacing: 0;
}

.Polaris-Stack--spacingExtraTight {
  --pc-stack-spacing: var(--p-space-1);
}

.Polaris-Stack--spacingTight {
  --pc-stack-spacing: var(--p-space-2);
}

.Polaris-Stack--spacingBaseTight {
  --pc-stack-spacing: var(--p-space-3);
}

.Polaris-Stack--spacingLoose {
  --pc-stack-spacing: var(--p-space-5);
}

.Polaris-Stack--spacingExtraLoose {
  --pc-stack-spacing: var(--p-space-8);
}

.Polaris-Stack--distributionLeading {
  justify-content: flex-start;
}

.Polaris-Stack--distributionTrailing {
  justify-content: flex-end;
}

.Polaris-Stack--distributionCenter {
  justify-content: center;
}

.Polaris-Stack--distributionEqualSpacing {
  justify-content: space-between;
}

.Polaris-Stack--distributionFill > .Polaris-Stack__Item {
  flex: 1 1 auto;
}

.Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
  flex: 1 1 auto;
}

@supports (
  (min-width: -webkit-fit-content) or (min-width: -moz-fit-content) or
    (min-width: fit-content)
) {
  .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
    flex: 1 0;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

.Polaris-Stack--alignmentLeading {
  align-items: flex-start;
}

.Polaris-Stack--alignmentTrailing {
  align-items: flex-end;
}

.Polaris-Stack--alignmentCenter {
  align-items: center;
}

.Polaris-Stack--alignmentFill {
  align-items: stretch;
}

.Polaris-Stack--alignmentBaseline {
  align-items: baseline;
}

.Polaris-Stack--vertical {
  flex-direction: column;
  margin-left: 0;
}

.Polaris-Stack--vertical > .Polaris-Stack__Item {
  margin-left: 0;
}

.Polaris-Stack__Item {
  flex: 0 0 auto;
  min-width: 0;
}

.Polaris-Stack__Item--fill {
  flex: 1 1 auto;
}

.Polaris-Subheading {
  margin: 0;
  font-size: 0.8125rem;
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-font-line-height-1);
  text-transform: uppercase;
}

@media (min-width: 48em) {
  .Polaris-Subheading {
    font-size: var(--p-font-size-75);
  }
}

@media print {
  .Polaris-Subheading {
    font-size: var(--p-font-size-75);
  }
}

.Polaris-Tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Tabs--fitted {
  flex-wrap: nowrap;
}

.Polaris-Tabs--fitted .Polaris-Tabs__TabContainer {
  flex: 1 1 100%;
}

.Polaris-Tabs--fitted .Polaris-Tabs__Title {
  width: 100%;
  padding: var(--p-space-2) var(--p-space-4);
}

.Polaris-Tabs--fillSpace .Polaris-Tabs__TabContainer {
  flex: 1 1 auto;
}

.Polaris-Tabs__TabContainer {
  display: flex;
  margin: 0;
  padding: 0;
}

.Polaris-Tabs__Tab {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: var(--p-text-subdued);
  color: var(--p-text);
  position: relative;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  margin-top: var(--p-space-025);
  margin-bottom: calc(var(--p-space-025) * -1);
  padding: var(--p-space-2) var(--p-space-1);
  outline: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
}

.Polaris-Tabs__Tab:focus {
  outline: none;
}

.Polaris-Tabs__Tab:hover {
  text-decoration: none;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  background-color: transparent;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title::before {
  background-color: var(--p-border-hovered);
}

.Polaris-Tabs__Tab:active .Polaris-Tabs__Title {
  background-color: transparent;
}

.Polaris-Tabs__Tab:active .Polaris-Tabs__Title::before {
  background: var(--p-surface-primary-selected-pressed);
}

.Polaris-Tabs__Tab:focus-visible .Polaris-Tabs__Title {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
}

.Polaris-Tabs__Tab:focus-visible:not(:active) .Polaris-Tabs__Title::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tabs__Tab:visited {
  color: inherit;
}

.Polaris-Tabs__Tab--selected {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
}

.Polaris-Tabs__Tab--selected:focus .Polaris-Tabs__Title {
  outline: var(--p-border-width-3) solid transparent;
}

.Polaris-Tabs__Tab--selected:focus .Polaris-Tabs__Title::before {
  background: var(--p-action-primary);
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
  outline: var(--p-border-width-3) solid transparent;
  color: var(--p-text);
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title::before {
  background: var(--p-action-primary);
}

.Polaris-Tabs__Title {
  position: relative;
  border-radius: var(--p-border-radius-1);
  display: block;
  padding: var(--p-space-2) var(--p-space-4);
  min-width: 3.125rem;
  color: var(--p-text-subdued);
}

.Polaris-Tabs__Title::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Tabs__Title::before {
  content: "";
  position: absolute;
  bottom: calc(var(--p-space-2) * -1);
  left: 0;
  right: 0;
  height: var(--p-border-width-3);
  border-top-left-radius: var(--p-border-radius-1);
  border-top-right-radius: var(--p-border-radius-1);
}

.Polaris-Tabs--titleWithIcon {
  display: flex;
}

.Polaris-Tabs__Panel {
  display: block;
}

.Polaris-Tabs__Panel:focus {
  outline: none;
}

.Polaris-Tabs__Panel--hidden {
  display: none;
}

.Polaris-Tabs__Item {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  min-height: 1rem;
  padding: 0.5rem var(--p-space-4);
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  border-radius: var(--p-border-radius-1);
  color: inherit;
}

.Polaris-Tabs__Item:focus {
  outline: none;
}

.Polaris-Tabs__Item::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Tabs__Item::-moz-focus-inner {
  border: none;
}

.Polaris-Tabs__Item:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Tabs__Item:active {
  background-color: var(--p-surface-primary-selected-pressed);
}

.Polaris-Tabs__Item:focus-visible:not(:active)::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  /* outline: var(--p-border-width-1) solid transparent; */
}

.Polaris-Tabs__Item:visited {
  color: inherit;
}

.Polaris-Tabs__DisclosureTab {
  display: none;
}

.Polaris-Tabs__DisclosureTab--visible {
  display: flex;
}

.Polaris-Tabs__DisclosureActivator {
  position: relative;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  margin: var(--p-space-025) var(--p-space-025) calc(var(--p-space-025) * -1) 0;
}

.Polaris-Tabs__DisclosureActivator::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-Tabs__DisclosureActivator:hover svg,
.Polaris-Tabs__DisclosureActivator:focus svg {
  fill: var(--p-icon);
}

.Polaris-Tabs__DisclosureActivator:focus-visible .Polaris-Tabs__Title::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title::before {
  background-color: var(--p-border-hovered);
}

.Polaris-Tabs__TabMeasurer {
  display: flex;
  visibility: hidden;
  height: 0;
}

.Polaris-TextStyle--variationPositive {
  color: var(--p-text-success);
}

.Polaris-TextStyle--variationNegative {
  color: var(--p-text-critical);
}

.Polaris-TextStyle--variationWarning {
  color: var(--p-text-warning);
}

.Polaris-TextStyle--variationCode {
  position: relative;
  padding: 0 var(--p-space-1);
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-surface-subdued);
  display: inline-block;
  font-size: var(--p-font-size-200);
  box-shadow: inset 0 0 0 0.0625rem var(--p-border-subdued);
}

.Polaris-TextStyle--variationCode::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: var(--p-border-width-1) solid transparent;
  pointer-events: none;
}

.Polaris-TextStyle--variationStrong {
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-TextStyle--variationSubdued {
  color: var(--p-text-subdued);
}

@media print {
  .Polaris-TextStyle--variationSubdued {
    color: var(--p-text-subdued);
  }
}

.Polaris-Thumbnail {
  --pc-thumbnail-extra-small-size: 1.5rem;
  --pc-thumbnail-small-size: 2.5rem;
  --pc-thumbnail-medium-size: 3.75rem;
  --pc-thumbnail-large-size: 5rem;
  position: relative;
  display: block;
  overflow: hidden;
  background: var(--p-surface);
  min-width: var(--pc-thumbnail-extra-small-size);
  max-width: 100%;
  border-radius: var(--p-border-radius-1);
  border: var(--p-border-divider);
}

.Polaris-Thumbnail::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.Polaris-Thumbnail--sizeExtraSmall {
  width: var(--pc-thumbnail-extra-small-size);
}

.Polaris-Thumbnail--sizeSmall {
  width: var(--pc-thumbnail-small-size);
}

.Polaris-Thumbnail--sizeMedium {
  width: var(--pc-thumbnail-medium-size);
}

.Polaris-Thumbnail--sizeLarge {
  width: var(--pc-thumbnail-large-size);
}

.Polaris-Thumbnail--transparent {
  background: transparent;
}

.Polaris-Thumbnail > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  color: var(--p-icon-subdued);
}

.Polaris-Thumbnail > * svg {
  fill: currentColor;
}

.Polaris-TopBar-SearchDismissOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--p-z-index-7);
  height: 100%;
}

.Polaris-TopBar-SearchDismissOverlay--visible {
  background-color: transparent;
  animation: none;
}

.Polaris-TopBar-Search {
  position: fixed;
  visibility: hidden;
  z-index: var(--p-z-index-8);
  pointer-events: none;
  top: 3.5rem;
  left: 0;
  right: 0;
  box-shadow: var(--p-shadow-2xl);
  overflow: hidden;
}

@media (min-width: 30.625em) {
  .Polaris-TopBar-Search {
    position: absolute;
    top: 100%;
    max-width: 36.25rem;
    margin: var(--p-space-1) var(--p-space-5) 0;
    border-radius: var(--p-border-radius-2);
  }
}

@media (min-width: 48em) {
  .Polaris-TopBar-Search {
    margin: var(--p-space-1) var(--p-space-8) 0;
  }
}

.Polaris-TopBar-Search__SearchContent {
  background-color: var(--p-surface);
}

.Polaris-TopBar-Search--visible {
  visibility: initial;
  pointer-events: all;
}

.Polaris-TopBar-Search__Results {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 3.5rem);
  margin: 0;
}

@media (min-width: 30.625em) {
  .Polaris-TopBar-Search__Results {
    max-height: 60vh;
  }
}

.Polaris-TopBar-SearchField {
  --pc-search-field-backdrop: 1;
  --pc-search-field-input: 2;
  --pc-search-field-icon: 3;
  --pc-search-field-action: 3;
  z-index: var(--p-z-index-11);
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  border: var(--p-border-transparent);
  width: 100%;
  max-width: 36.25rem;
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input,
.Polaris-TopBar-SearchField__Input:focus {
  border: none;
  color: var(--p-text);
}

.Polaris-TopBar-SearchField--focused
  .Polaris-TopBar-SearchField__Input::placeholder,
.Polaris-TopBar-SearchField__Input:focus::placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TopBar-SearchField__Input:focus-visible
  ~ .Polaris-TopBar-SearchField__Backdrop::after {
  /* box-shadow: 0 0 0 0.125rem var(--p-focused); */
  /* outline: var(--p-border-width-1) solid transparent; */
}

.Polaris-TopBar-SearchField__Input:focus-visible
  ~ .Polaris-TopBar-SearchField__BackdropShowFocusBorder {
  border: var(--p-border-width-1) solid var(--pc-top-bar-border);
}

.Polaris-TopBar-SearchField__Input:focus-visible
  ~ .Polaris-TopBar-SearchField__Icon
  svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField--focused
  .Polaris-TopBar-SearchField__BackdropShowFocusBorder {
  border: var(--p-border-width-1) solid var(--pc-top-bar-border);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Input {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-3);
  border: none;
  text-transform: initial;
  letter-spacing: initial;
  z-index: var(--pc-search-field-input);
  height: 2.25rem;
  width: 100%;
  padding: 0 0 0 calc(1.25rem + var(--p-space-4));
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--p-text);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: fill, color;
  transition:
    fill var(--p-duration-200) var(--p-ease),
    color var(--p-duration-200) var(--p-ease);
  -webkit-appearance: textfield;
  appearance: textfield;
}

@media (min-width: 48em) {
  .Polaris-TopBar-SearchField__Input {
    font-size: var(--p-font-size-100);
  }
}

.Polaris-TopBar-SearchField__Input::placeholder {
  color: var(--p-text);
}

.Polaris-TopBar-SearchField__Input::-webkit-search-decoration,
.Polaris-TopBar-SearchField__Input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.Polaris-TopBar-SearchField__Icon {
  position: absolute;
  z-index: var(--pc-search-field-icon);
  top: 50%;
  left: var(--p-space-2);
  display: flex;
  height: 1.25rem;
  pointer-events: none;
  transform: translateY(-50%);
}

.Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Clear {
  position: relative;
  position: relative;
  z-index: var(--pc-search-field-action);
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  padding: var(--p-space-2);
}

.Polaris-TopBar-SearchField__Clear::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-2);
}

.Polaris-TopBar-SearchField__Clear svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Clear:focus,
.Polaris-TopBar-SearchField__Clear:hover {
  outline: none;
}

.Polaris-TopBar-SearchField__Clear:hover svg,
.Polaris-TopBar-SearchField__Clear:focus svg {
  fill: var(--p-icon-hovered);
}

.Polaris-TopBar-SearchField__Clear:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  /* outline: var(--p-border-width-1) solid transparent; */
}

.Polaris-TopBar-SearchField__Clear:active svg {
  fill: var(--p-icon-pressed);
}

.Polaris-TopBar-SearchField__Clear:active::after {
  border: none;
}

.Polaris-TopBar-SearchField__Backdrop {
  position: relative;
  position: absolute;
  z-index: var(--pc-search-field-backdrop);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-surface-search-field);
  border-radius: var(--p-border-radius-1);
}

.Polaris-TopBar-SearchField__Backdrop::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-MessageIndicator__MessageIndicatorWrapper {
  position: relative;
}

.Polaris-MessageIndicator {
  position: absolute;
  z-index: 1;
  top: -0.1875rem;
  right: -0.1875rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: var(--p-border-radius-full);
  background-color: var(--p-icon-highlight);
  border: solid var(--p-border-width-2) var(--p-background);
}

.Polaris-Menu-Message__Section {
  max-width: 20.3125rem;
  margin-top: var(--p-space-2);
  padding-top: var(--p-space-2);
  border-top: var(--p-border-divider);
}

.Polaris-TopBar-Menu__ActivatorWrapper {
  height: 3.5rem;
  display: flex;
  align-items: center;
}

.Polaris-TopBar-Menu__Activator {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  color: var(--p-text);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.25rem;
  padding: var(--p-space-1) var(--p-space-2);
  border: 0;
  cursor: pointer;
  transition: background-color var(--p-duration-100);
  margin-right: var(--p-space-2);
  border-radius: var(--p-border-radius-1);
}

.Polaris-TopBar-Menu__Activator:focus {
  outline: none;
}

.Polaris-TopBar-Menu__Activator::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-TopBar-Menu__Activator:focus {
  background-color: var(--pc-top-bar-background-lighter);
  outline: none;
}

.Polaris-TopBar-Menu__Activator:focus-visible::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  /* outline: var(--p-border-width-1) solid transparent; */
}

.Polaris-TopBar-Menu__Activator:hover {
  background-color: var(
    --pc-top-bar-background-lighter,
    var(--p-surface-hovered)
  );
}

.Polaris-TopBar-Menu__Activator:active,
.Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
  background-color: var(
    --pc-top-bar-background-darker,
    var(--p-surface-pressed)
  );
  outline: none;
  transition: none;
}

.Polaris-TopBar-Menu__Activator:active::after,
.Polaris-TopBar-Menu__Activator[aria-expanded="true"]::after {
  border: none;
}

@media (max-width: 47.9975em) {
  .Polaris-TopBar-Menu__Activator {
    margin: 0;
  }
  .Polaris-TopBar-Menu__Activator:focus,
  .Polaris-TopBar-Menu__Activator:hover,
  .Polaris-TopBar-Menu__Activator:active,
  .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
    background-color: transparent;
    opacity: 0.85;
  }
}

.Polaris-TopBar-Menu__Section {
  margin-top: var(--p-space-2);
  padding-top: var(--p-space-2);
  border-top: var(--p-border-divider);
}

.Polaris-TopBar-UserMenu__Details {
  max-width: 10rem;
  margin-left: var(--p-space-2);
}

@media (max-width: 47.9975em) {
  .Polaris-TopBar-UserMenu__Details {
    display: none;
  }
}

.Polaris-TopBar {
  position: relative;
  display: flex;
  height: 3.5rem;
  /* box-shadow: var(--p-shadow-sm);
  background-color: var(--p-surface); */
  transition: var(--p-duration-200) background-color var(--p-ease-in-out);
}

.Polaris-TopBar::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: var(--p-border-width-1) solid transparent;
}

.Polaris-TopBar__LogoDisplayControl {
  display: none;
}

@media (min-width: 48em) {
  .Polaris-TopBar__LogoDisplayControl {
    display: flex;
  }
}

.Polaris-TopBar__LogoDisplayContainer {
  display: flex;
}

.Polaris-TopBar__LogoContainer {
  flex: 0 0 15rem;
  align-items: center;
  height: 100%;
  padding: 0 var(--p-space-2) 0 var(--p-space-4);
  flex-basis: 15rem;
  flex-basis: calc(15rem + constant(safe-area-inset-left));
  flex-basis: calc(15rem + env(safe-area-inset-left));
  padding-left: var(--p-space-4);
  padding-left: calc(var(--p-space-4) + constant(safe-area-inset-left));
  padding-left: calc(var(--p-space-4) + env(safe-area-inset-left));
  border-right: var(--p-border-divider);
  background-color: #ffffff;
}

.Polaris-TopBar__LogoContainer.Polaris-TopBar--hasLogoSuffix {
  gap: var(--p-space-2);
}

.Polaris-TopBar__Logo,
.Polaris-TopBar__LogoLink {
  display: block;
}

.Polaris-TopBar__ContextControl {
  display: none;
}

@media (min-width: 48em) {
  .Polaris-TopBar__ContextControl {
    width: 15rem;
    display: block;
  }
}

@media (min-width: 90em) {
  .Polaris-TopBar__ContextControl {
    width: 15rem;
  }
}

.Polaris-TopBar__NavigationIcon {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  align-self: center;
  margin-left: calc(var(--p-space-2) + var(--p-space-05));
  margin-right: var(--p-space-2);
  padding: var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  fill: var(--p-icon);
  transition: var(--p-duration-150) fill var(--p-ease) var(--p-duration-50);
}

.Polaris-TopBar__NavigationIcon:focus {
  outline: none;
}

.Polaris-TopBar__NavigationIcon.Polaris-TopBar--focused:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-TopBar__NavigationIcon:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-TopBar__NavigationIcon::after {
  content: "";
  position: absolute;
  top: calc(var(--p-space-2) * -1);
  left: calc(var(--p-space-2) * -1);
  width: calc(100% + var(--p-space-5));
  height: calc(100% + var(--p-space-5));
}

@media (min-width: 48em) {
  .Polaris-TopBar__NavigationIcon {
    display: none;
  }
}

.Polaris-TopBar__NavigationIcon .Polaris-TopBar__IconWrapper {
  position: relative;
}

.Polaris-TopBar__NavigationIcon .Polaris-TopBar__IconWrapper::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -0.4375rem;
  right: -0.4375rem;
  bottom: -0.4375rem;
  left: -0.4375rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.4375rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: var(--p-border-radius-1);
}

.Polaris-TopBar__NavigationIcon:focus-visible:not(:active)
  .Polaris-TopBar__IconWrapper::after {
  box-shadow: 0 0 0 0.125rem var(--p-focused);
  /* outline: var(--p-border-width-1) solid transparent; */
}

.Polaris-TopBar__Contents {
  z-index: var(--p-z-index-1);
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  background-color: var(--body-primary-primary-50);
  border-bottom: var(--p-border-divider);
  padding: 0 20px;
  /* margin: 0px 20px; */
}

@media (min-width: 48em) {
  .Polaris-TopBar__Contents {
    position: relative;
  }
}

.Polaris-TopBar__SearchField {
  margin: 0 auto;
  padding: 0;
  max-width: 62.375rem;
  position: relative;
  width: 100%;
  margin: 0;
  max-width: none;
  margin-right: var(--p-space-1);
}

@media (min-width: 30.625em) {
  .Polaris-TopBar__SearchField {
    padding: 0 var(--p-space-6);
  }
}

@media (min-width: 90em) {
  .Polaris-TopBar__SearchField {
    margin-left: calc(50% - 31.1875rem);
  }
}

.Polaris-TopBar__SecondaryMenu svg {
  fill: var(--p-icon);
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.Polaris-VideoThumbnail__Thumbnail {
  position: relative;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.Polaris-VideoThumbnail__ThumbnailContainer {
  position: relative;
  height: 100%;
}

.Polaris-VideoThumbnail__PlayButton {
  --pc-play-button-focused-state-overlay: rgba(223, 227, 232, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: transparent;
  transition: opacity var(--p-duration-200) var(--p-ease-in);
  cursor: pointer;
}

.Polaris-VideoThumbnail__PlayButton:focus {
  outline: none;
  box-shadow: inset 0.125rem 0 0 var(--p-focused);
  background-image: linear-gradient(
    var(--pc-play-button-focused-state-overlay),
    var(--pc-play-button-focused-state-overlay)
  );
}

.Polaris-VideoThumbnail__PlayButton:focus .Polaris-VideoThumbnail__Timestamp {
  background-color: rgba(0, 0, 0, 0.8);
}

.Polaris-VideoThumbnail__PlayButton:hover .Polaris-VideoThumbnail__Timestamp {
  background-color: rgba(0, 0, 0, 0.8);
}

.Polaris-VideoThumbnail__PlayIcon {
  fill: var(--p-icon-on-interactive);
}

.Polaris-VideoThumbnail__Timestamp {
  position: absolute;
  bottom: 0;
  padding: var(--p-space-1) var(--p-space-2) var(--p-space-1) var(--p-space-1);
  margin-bottom: var(--p-space-4);
  margin-left: var(--p-space-4);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text-on-interactive);
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  transition: background-color var(--p-duration-200) var(--p-ease-in);
}

.Polaris-VideoThumbnail__Progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--p-surface);
  height: 0.375rem;
  overflow: hidden;
}

.Polaris-VideoThumbnail__Indicator {
  height: inherit;
  width: 100%;
  transform-origin: left;
  transform: scaleX(0);
  background-color: var(--p-border-highlight);
  transition: transform var(--p-duration-500) var(--p-ease);
}

.Polaris-VideoThumbnail__ProgressBar,
.Polaris-VideoThumbnail__Label {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-VisuallyHidden {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

/*Custom*/
.VD-min-hight {
  min-height: 100vh;
}

/*onboring*/

.pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  border-radius: 26px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(17.5px);

  /* padding: 2rem 4rem; */
}
.pricing .plan {
  padding: 40px 60px;
  width: 33%;
  /* background-color: #fff;
  padding: 2.5rem;
  margin: 12px;
  text-align: left;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.25);
  width: 25%;
  margin: 30px;
  min-height: 600px; */
}
.pricing .plan h2 {
  margin-top: 32px;
  color: #883dcf;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
}
.pricing .plan .price {
  color: #883dcf;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
}
.pricing .plan .price span {
  color: #883dcf;
  font-size: 20px;
  font-weight: 400;
  line-height: 46px;
}
.pricing .pric-view {
  color: #848199;
  margin-top: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}
.pricing .plan .features {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
}
.pricing .plan .features .li {
  display: flex;
  gap: 12px;
  color: #848199;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}

.pricing .plan button {
  width: 100%;
  padding: 12px 35px;
  margin-top: 66px;
  background-color: #883dcf;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  border: none;
}
.pricing .plan button.btn-active {
  background-color: #7441f6;
  color: #fff;
}
.pricing .plan.popular {
  margin-top: -20px;
  position: relative;
  /* background-color: #883dcf; */
  padding: 20px 30px;
  border-radius: 26px;
  background: #883dcf;
  box-shadow: 0px 42px 34px 0px rgba(82, 67, 194, 0.3);
  /* margin-top: 120px; */
}
.pricing .plan.popular .recommended {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  width: 100%;
}
.pricing .plan.popular .recommended span {
  border-radius: 13.5px;
  background: #f8f8fc;
  padding: 8px;
  color: #bb6bd9;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.833px;
  text-transform: uppercase;
  text-align: center;
}
.popular-li {
  color: #fff !important;
}
.popular-btn {
  margin-top: 72px !important;
  border-radius: 8px !important;
  background: #f4ecfb !important;
  padding: 10px 14px !important;
  color: #883dcf !important;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
  font-size: 14px;
}
.popular-price {
  color: #fff !important;
}
.popular-price span {
  color: #fff !important;
}
.popular-title {
  color: #fff !important;
}
.popular-pric-view {
  color: #fff !important;
}
/* .pricing .plan:hover {
  box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
} */

/*Media*/
.video-list-box {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 0px;
  border-bottom: 1px solid #ccc;
  align-items: center;
}
.Video-list {
  height: 68vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
}

/*pages*/

.tabs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.tab-panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

[role="tablist"] {
  grid-template-columns: repeat(auto-fit, minmax(94px, 1fr));
  grid-gap: 0px;
}

[role="tabpanel"] {
  /*display: inline-block;*/
  width: 100%;
}

.Tab_button {
  background: transparent;
  border: 0;
  color: black;
  border-radius: 5px;
  --bs-color: rgba(0, 0, 0, 0.1);
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  /* padding: 10px 30px; */
  font-weight: 500;
}
.video-name {
  font-weight: 600;
  /* color: #000; */
  color: #883dcf;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.06px;
}

.Tab_button[aria-selected="true"] {
  background: var(--p-action-primary);
  box-shadow: none;
  color: #fff;
}

.Tab_button:focus {
  outline: 0;
  /* --bs-color: rgba(0, 0, 0, 0.6); */
}
.Tab-media-all {
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.VD-acrodian {
  border-bottom: 1px solid #ccc;
  padding: 25px 20px;
  width: 100%;
  cursor: pointer;
}
.VD-acrodian > span {
  margin-right: 15px;
}
.VD-acrodian-oprn {
  padding: 30px 50px;
  border-bottom: 1px solid #ccc;
}
.VD-main-block {
  border: 1px solid #ccc;
  border-radius: 8px;
}
.VD-name-story {
  font-weight: bold;
  /* margin-right: 15px; */
  /* margin-top: 6px; */
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
  /* min-width: 50px; */
  font-size: 14px;
  color: #777980;
}
.VD-story-main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
}
.VD-story-add {
  display: flex;
  width: 100%;
}
.VD-story {
  padding: 25px;
  display: flex;
  border-bottom: 1px solid #ccc;
  align-items: baseline;
}
.VD-card {
  padding: 25px;
  display: flex;
}
.VD-add-card-page {
  display: flex;
  align-items: center;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: fit-content;
}
.VD-add-card-page > span {
  padding: 0 18px;
  border-right: 1px solid #ccc;
  height: 38px;
  display: flex;
  align-items: center;
}
.VD-playlist-name {
  font-size: 16px;
  padding: 15px;
}
.VD-close-story {
  padding: 10px;
}
.VD-drag {
  width: 100%;
}
.VD-acrodian.VD-active > span svg {
  transform: rotate(90deg);
}

/*Media popup*/
.Polaris-Modal-CloseButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  position: relative;
  margin-left: var(--p-space-5);
  margin-right: calc(var(--p-space-2) * -1);
  padding: var(--p-space-2);
  border-radius: var(--p-border-radius-2);
}
.Tab-media-all-product {
  height: 36vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

.Tab-media-all-product .Polaris-IndexTable__Table--sticky thead {
  tr {
    /* display: none; */
  }
}

.Tab-media-all-product .Polaris-Text--root .Polaris-Box {
  margin-top: 10px;
  padding: 0px;
  font-size: 14px;
}

.Tab-media-all-product
  .Polaris-IndexTable__TableRow
  .Polaris-IndexTable__TableCell {
  /* padding: 12px 8px; */
  font-size: 14px;
}

.Tab-media-all-product *:hover {
  background: unset;
  color: unset;
}

/* .Tab-media-all-product .Polaris-IndexTable__TableHeading{
  padding: 12px 8px;
  font-size: 14px;
} */
.Polaris-Choice {
  align-items: center;
}
.video-list-box-play {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid #ccc;
  align-items: center;
}

/*Dashbord*/
.VD-imprestion {
  display: flex;
  gap: 40px;
  padding: 24px;
}
.VD-logic {
  /* width: 230px;
  padding: 20px 0; */
}
.VD-name-logic {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--font-grey);
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.VD-top-number {
  font-size: 24px;
  font-weight: 600;
  color: #000;
}
.VD-logic-number {
  display: flex;
  align-items: center;
}
.VD-flex {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.VD-top-number-arrow {
  color: #0a850a;
  font-weight: 700;
  margin-left: 8px;
  font-size: 14px;
}
.Polaris-Frame__Content {
  padding-bottom: 60px;
}

/*SLIDER*/
.swiper-container.swiper-container-coverflow {
  padding: 1% 0;
}

.swiper-container:hover .swiper-button-prev,
.swiper-container:hover .swiper-button-next {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}
.swiper-slide .entity-img {
  display: none;
}
.swiper-slide .content {
  position: absolute;
  top: 40%;
  left: 0;
  width: 66%;
  padding-left: 5%;
  color: #fff;
}
.swiper-slide .content .title {
  font-size: 1.7em;
  font-weight: bold;
  margin-bottom: 30px;
}
.swiper-slide .content .caption {
  display: block;
  font-size: 12px;
  line-height: 1.4;
}

[class^="swiper-button-"] {
  width: 44px;
  opacity: 0;
  visibility: hidden;
}

.swiper-button-prev {
  transform: translateX(50px);
}

.swiper-button-next {
  transform: translateX(-50px);
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 9px;
  position: relative;
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 0.4;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  border: 0px solid #fff;
  border-radius: 50%;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet:hover,
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  border-width: 1px;
}

.active-plan {
  border-radius: 8px;
  background: #f4ecfb;
  color: #883dcf;
  padding: 10px 14px;
  font-weight: 600;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  text-align: center;
  margin-top: 66px;
}

@media (max-width: 1180px) {
  .swiper-container {
    height: 28vw;
  }

  .swiper-slide .content .title {
    font-size: 25px;
  }
  .swiper-slide .content .caption {
    font-size: 12px;
  }
}
@media (max-width: 1023px) {
  .swiper-container {
    height: 40vw;
  }
  .swiper-container.swiper-container-coverflow {
    padding-top: 0;
  }
}

/* Dj css Start */

/* Css For Bottom Save-btn Fix */
.VD-left-bottom-btn::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.9);
  background-color: #f1f1f1;
}

.VD-left-bottom-btn::-webkit-scrollbar {
  width: 3px;
  background-color: #fff;
  border-radius: 10px;
}

.VD-left-bottom-btn::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
}
.VD-left-bottom-btn {
  height: 75vh;
  overflow: auto;
  position: relative;
}
.VD-end-save-btn {
  position: sticky;
  bottom: 0;
  background: #fff;
  width: 100%;
  z-index: 20;
  border-radius: 0 0 0 0.5rem !important;
}
.pop-pro-title {
  line-height: 20px;
}
.tp-stikey {
  position: sticky;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 22;
  border-radius: 0.5rem 0 0 0 !important;
}

/* Customise Page  ************************************/
.dev_text_color {
  width: 44px;
  height: 44px;
  border-color: transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  cursor: pointer;
  padding: 0;
  outline: 0;
  border: 0;
}

.Polaris-flex {
  display: flex;
}

.Polaris-video-inner {
  height: 500px;
  width: 215px;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.Polaris-video-main {
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.Video-cart-content {
  width: 100%;
  position: relative;
  padding: 17px 5px 0px 5px;
}

.Product_thamnail {
  position: absolute;
  left: 50%;
  top: -35px;
  transform: translateX(-50%);
}

.polaris-review {
  display: flex;
  justify-content: center;
}

.polaris-review svg {
  width: 16px;
}

.polaris-Price-main {
  font-size: 15px;
  display: flex;
  margin-top: 8px;
  justify-content: center;
}

.polaris-discount p {
  color: var(--p-icon-on-primary);
  background: #ff0000;
  padding: 0 7px;
  font-weight: 600;
  border-radius: 4px;
}

.polaris-Price-main .polaris-Price {
  padding: 0 7px;
}

.Polaris-video-inner video {
  border-radius: 5px 5px 0 0;
}

.btn_tabs .Polaris-Button--pressed {
  background-color: var(--p-action-primary) !important;
}

.btn_tabs .Polaris-Button {
  background: #f1f1f1;
  border: 0;
  padding: 0 24px;
}

.btn_tabs {
  padding: 0 0 0 15px;
}

.device_tabs svg {
  width: 20px;
}
.device_tabs {
  position: absolute;
  right: 14px;
  top: 14px;
}
.device_tabs .Polaris-Button--pressed {
  background-color: var(--p-action-primary) !important;
}
.device_tabs .Polaris-Button--pressed svg path {
  fill: #fff !important;
}
.device_tabs .Polaris-Button {
  background: #f1f1f1;
  border: 0;
}
/* End Customise Page  ************************************/

/* Customize Story circle css  ************************************/

.btn_tabs .Polaris-Button--pressed,
.btn_tabs_sec .Polaris-Button--pressed {
  background-color: var(--p-action-primary) !important;
}

.btn_tabs .Polaris-Button {
  background: #f1f1f1;
  border: 0;
  padding: 0 24px;
}

.btn_tabs_sec .Polaris-Button {
  background: #f1f1f1;
  border: 0;
}

.btn_tabs {
  padding: 0 0 0 15px;
}

.style1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 44px;
  height: 46px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.style1::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: #cccccc;
  width: 39px;
  height: 39px;
  z-index: -1;
  border-radius: 4px;
}

.style1::-webkit-color-swatch {
  border-radius: 4px;
  border: none;
}

.style1::-moz-color-swatch {
  border-radius: 4px;
  border: none;
}

.device_tabs svg {
  width: 20px;
}

.device_tabs {
  position: absolute;
  right: 14px;
  top: 14px;
}

.device_tabs .Polaris-Button--pressed {
  background-color: var(--p-action-primary) !important;
}

.device_tabs .Polaris-Button--pressed svg path {
  fill: #fff !important;
}

.device_tabs .Polaris-Button {
  background: #f1f1f1;
  border: 0;
}

.btn_tabs_sec .Polaris-ButtonGroup__Item {
  width: 100%;
  max-width: 50%;
}

.btn_tabs_sec .Polaris-ButtonGroup__Item button {
  width: 100%;
}

/* right Story Css */

.VD-home-box-img {
  /* border: 2px solid #d6d6d6; */
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  padding: 2px;
  width: 100px;
}
.VD-home-box-img img {
  width: 100%;
  border-radius: 100%;
}
.VD-home-box-title {
  font-size: 12px;
  text-align: center;
}
.VD-home-box {
  width: 100px;
  margin-right: 15px;
  cursor: pointer;
}
.VD-home-slide {
  width: 100%;
  height: 100%;
  padding-top: 100px;
}
.VD-home-slide-inner {
  overflow-x: auto;
  display: flex;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}
.VD-home-slide-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  height: 0px;
}

.VD-home-slide-inner::-webkit-scrollbar {
  width: 1px;
  height: 0px;
  background-color: #f5f5f5;
}

.VD-home-slide-inner::-webkit-scrollbar-thumb {
  background-color: #000000;
  height: 0px;
}

/* End Customize Story circle css  ************************************/

/* Customise Popup css Start  ************************************/

.btn_tabs .Polaris-Button--pressed {
  background-color: var(--p-action-primary) !important;
}
.VD-product-card-inner {
  height: 510px;
  width: 600px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.VD-product-card {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 56px 0;
}

.VD-product-card-video,
.VD-product-card-content {
  width: 100%;
  max-width: 50%;
  position: relative;
}

.VD-product-video-mute {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  background: #00000021;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  border: 0px SOLID rgba(0, 0, 0, 1);
  height: 44px;
  width: 44px;
  color: white;
}

.product-content-name {
  display: flex;
  padding: 10px 15px 10px 15px;
  border-bottom: var(--p-border-divider);
}

.product-content-name .tamnail_name {
  padding-left: 10px;
}

.product-content-varient {
  padding: 10px 15px 10px 15px;
  border-bottom: var(--p-border-divider);
}

.variend-img {
  position: relative;
  border-radius: 0px;
  padding: 7px 0px 4px 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 7px;
  width: 100%;
  margin-bottom: 10px;
  height: auto;
  display: flex;
  overflow-x: auto;
}

.variend-img-main {
  border-radius: 5px !important;
  height: 85px !important;
  width: 75px !important;
  max-height: 130px !important;
  position: relative;
  padding: 3px 3px 3px 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.variend-img-main img {
  position: relative;
  border-radius: 19px;
  height: 100%;
  width: 100%;
  display: flex;
  object-fit: contain;
}

.variend-img-main-select {
  border: 1px solid rgb(255, 0, 0);
}

.product-content-discription {
  padding: 10px 15px 10px 15px;
  border-bottom: var(--p-border-divider);
}

.discription-inner {
  position: relative;
  background: #f6f6f6;
  border-radius: 4px;
  padding: 10px 15px 10px 15px;
  width: 100%;
  display: block;
  height: auto;
  font-size: 13px;
}

.quantity-main {
  padding: 10px 15px 10px 15px;
  border-bottom: var(--p-border-divider);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quantity-inner {
  position: relative;
  border-radius: 0px;
  padding: 7px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  width: auto;
  height: auto;
}

.quantity-inner button svg {
  width: 15px;
}

.variend-img::-webkit-scrollbar-track {
  -webkit-box-shadow: inherit;
  background-color: transparent;
}

.variend-img::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #eee;
}

.variend-img::-webkit-scrollbar-thumb {
  background-color: #000000;
  width: 3px;
  height: 3px;
}

.Cart-btn {
  position: sticky;
  border-radius: 0px;
  padding: 10px 15px 10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-top: 1px solid #eee;
  height: 53px;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 20;
}

button.Polaris-Button.cart-text {
  position: relative;
  background: #ff0000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
  padding: 0px 10px 0px 10px;
  border: 0px SOLID #000;
  width: auto;
  flex-grow: 1;
  height: 35px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.VD-product-content-inner {
  position: relative;
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
}
.quantity-inner .Polaris-Button {
  width: 36px;
  padding: 0;
}
/* End Customise Popup css ************************************/

/* customise Popup Detail css Start  ************************************/

/* right Story Css */
.VD-mobile-story {
  width: 100%;
  height: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
}

.VD-mobile-story-inner {
  height: 520px;
  width: 280px;
  position: relative;
}

.VD-mobile-story-content {
  position: absolute;
  width: calc(100% - 14px);
  padding-bottom: 7px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.VD-star {
  display: flex;
  align-items: center;
}

.VD-star svg {
  width: 14px;
  display: block;
}

.VD-mobile-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 10px 5px 5px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: saturate(180%) blur(10px);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  border-radius: 8px;
}

.VD-pro-img {
  width: 53px;
  height: 53px;
  background: #fff;
  border-radius: 8px;
}

.VD-pro-content {
  width: calc(100% - 63px);
}

.VD-pro-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.VD-audio-control {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  border-radius: 100%;
  cursor: pointer;
  margin: 0 0 10px auto;
  padding: 8px;
}

.VD-audio-control svg {
  fill: #ececec;
  width: 100%;
  height: 100%;
}

.VD-pro-title {
  margin: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  line-height: 1.4;
  /* color: #fff !important; */
  font-weight: 500;
}

span.VD-review-count {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding: 0 0 0 5px;
}

.VD-pro-price {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 1;
  color: #fff !important;
  font-weight: 600;
  margin-top: 5px;
}

span.VD-camp-price {
  font-weight: 500;
  text-decoration: line-through;
  color: #d3d3d3;
}

.VD-badge-discount {
  top: -15px;
  left: 5px;
  position: absolute;
  width: auto;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  z-index: 99;
  border-radius: 5px;
  overflow: hidden;
  max-width: 200px;
  background: rgb(179, 0, 0);
  line-height: 22px;
  padding: 0 9px;
}

.VD-mobile-card button {
  color: rgb(0, 0, 0);
  background-color: rgb(116, 255, 190);
  width: 100%;
  min-height: 32px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  padding: 0 10px 0 15px;
  margin: 5px 0 0 0 !important;
  height: unset;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1;
}

.VD-story-close-btn {
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.VD-story-close-btn svg {
  width: 100%;
  height: 100%;
}

/* Bottom Popup product content*/
.VD-pop-open {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  max-height: 420px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.168627451);
}

.VD-pop-img {
  text-align: center;
}

.VD-pop-img img {
  width: 125px;
  aspect-ratio: 1/1;
}

.VD-product-title {
  color: rgb(0, 0, 0);
  font-size: 18px;
  line-height: 1.4;
  font-weight: 600;
  margin: 2px 0 0px;
  text-align: left;
}

.VD-product-details {
  padding: 10px 8px 10px;
  background-color: #fff;
  /* box-shadow: 0 0 10px rgba(0,0,0,.168627451); */
}

.VD-product-details .VD-star svg {
  width: 14px;
}

.VD-product-details .VD-review-count {
  color: #121212bf;
  font-size: 14px;
}

.VD-product-details .VD-pro-price {
  margin-top: 7px;
  font-size: 16px;
  gap: 8px;
  color: #0f0f0f !important;
}
.VD-product-details .VD-pro-review {
  margin-top: 3px;
}

.VD-pro-variant label {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.VD-pro-variant select {
  width: 100%;
  background: #fff;
  height: 33px;
  border-radius: 4px;
  border: 1px solid #999b9d;
  appearance: none;
  font-family: var(--p-font-family-sans);
  font-size: 16px;
  padding: 0 12px;
  font-weight: 500;
  margin-top: 5px;
  outline: inherit;
  cursor: pointer;
}

.VD-product-details .VD-camp-price {
  color: rgb(133, 133, 133);
}

.VD-pro-variant {
  position: relative;
}

.VD-pro-variant::after {
  content: "";
  background-image: url("./assets/img/caret-down_minor.png");
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 7px;
  right: 8px;
  z-index: 1;
}

.VD-pro-disc {
  border-left: unset;
  border-right: unset;
  padding: 10px 8px;
  cursor: pointer;
  display: -ms-flexbox !important;
  display: flex !important;
  background: #fff;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  border-bottom: 4px solid #f3f3f3;
  border-top: 4px solid #f3f3f3;
}

.VD-pro-disc svg {
  width: 11px;
  height: 11px;
}

.VD-pro-disc span {
  font-size: 14px;
  line-height: 1.4;
  color: #000;
  font-weight: 500;
}

.VD-pop-open-inner {
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.VD-product-btn {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1;
  left: 0;
  margin: 0;
  padding: 8px;
  background: #fff;
  height: 48px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1607843137);
}

.VD-product-btn button {
  background-color: rgb(0, 0, 0);
  width: 100%;
  min-height: 33px;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VD-product-btn button svg {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.VD-pop-down-aero {
  position: absolute;
  top: -45px;
  left: 50%;
  display: inline-flex;
  width: 35px;
  height: 35px;
  padding: 2px;
  z-index: 9;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.05);
}
/* End customise Popup Detail css   ************************************/

/*  customise popup bottom css Start   ************************************/

/* right Story Css */
.VD-mobile-story {
  width: 100%;
  height: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
}

.VD-mobile-story-inner {
  height: 510px;
  width: 280px;
  position: relative;
}

.VD-mobile-story-content {
  position: absolute;
  width: calc(100% - 14px);
  padding-bottom: 7px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.VD-star {
  display: flex;
  align-items: center;
}

.VD-star svg {
  width: 14px;
  display: block;
}

.VD-mobile-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 10px 5px 5px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: saturate(180%) blur(10px);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  border-radius: 8px;
}

.VD-pro-img {
  width: 53px;
  height: 53px;
  background: #fff;
  border-radius: 8px;
}

.VD-pro-content {
  width: calc(100% - 63px);
}

.VD-pro-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.VD-audio-control {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  border-radius: 100%;
  cursor: pointer;
  margin: 0 0 10px auto;
  padding: 8px;
}

.VD-audio-control svg {
  fill: #ececec;
  width: 100%;
  height: 100%;
}

.VD-pro-title {
  margin: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  line-height: 1.4;
  /* color: #fff !important; */
  font-weight: 500;
}

span.VD-review-count {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding: 0 0 0 5px;
}

.VD-pro-price {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 1;
  color: #fff !important;
  font-weight: 600;
  margin-top: 5px;
}

span.VD-camp-price {
  font-weight: 500;
  text-decoration: line-through;
  color: #d3d3d3;
}

.VD-badge-discount {
  top: -15px;
  left: 5px;
  position: absolute;
  width: auto;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  z-index: 99;
  border-radius: 5px;
  overflow: hidden;
  max-width: 200px;
  background: rgb(179, 0, 0);
  line-height: 22px;
  padding: 0 9px;
}

.VD-mobile-card button {
  color: rgb(0, 0, 0);
  background-color: rgb(116, 255, 190);
  width: 100%;
  min-height: 32px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  padding: 0 10px 0 15px;
  margin: 5px 0 0 0 !important;
  height: unset;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1;
}

.VD-story-close-btn {
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.VD-story-close-btn svg {
  width: 100%;
  height: 100%;
}
.video-list-box:last-child,
.video-list-box-play:last-child {
  border-bottom: 0px;
}
/* End customise popup bottom css   ************************************/

@media (max-width: 1260px) {
  .VD-product-card-inner {
    height: 100%;
    width: calc(100% - 20px);
  }
  .variend-img-main {
    height: 45px !important;
    width: 40px !important;
  }
  .discription-inner {
    padding: 10px 10px 10px 10px;
    font-size: 11px;
    line-height: normal;
  }
  .variend-img {
    gap: 5px;
    margin-bottom: 0px;
  }
  .VD-product-video-mute {
    gap: 8px;
    padding: 0px 5px 0px 5px;
    height: 25px;
    width: 25px;
  }
  .tamnail_img {
    width: 45px;
  }
  .pop-pro-title {
    font-size: 13px;
  }
  .quantity-main {
    flex-wrap: wrap;
  }
  .VD-home-slide,
  .VD-product-card,
  .VD-mobile-story {
    padding: 60px 0;
  }
  .Polaris-video-main {
    padding: 35px 0;
  }
  .VD-left-bottom-btn {
    max-width: 275px !important;
    min-width: 275px !important;
  }
  .VD-home-slide-inner {
    max-width: 350px;
  }
  .VD-home-box-img {
    width: 70px;
  }
  .VD-home-box {
    margin-right: 10px;
  }
  .btn_tabs_sec .Polaris-ButtonGroup__Item button {
    white-space: pre;

    /* Ravisir Html css Responsive */
  }
  .pricing .plan {
    padding: 2.5rem 1rem;
    min-height: 515px;
    margin: 30px 12px;
    width: 30%;
  }
  .pricing .plan ul.features {
    min-height: 240px;
  }
  .pricing .plan h2,
  .pricing .pric-view {
    font-size: 18px;
  }
  .pricing .plan .price {
    color: #883dcf;
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
  }
  .pricing .plan ul.features li {
    font-size: 18px;
    margin-bottom: 14px;
  }
  .pricing .plan button {
    padding: 6px 15px;
    font-size: 18px;
  }
  .VD-story-main {
    width: 100%;
  }
}
@media (max-width: 1150px) {
  .VD-top-number-arrow {
    font-size: 16px;
    padding-right: 7px;
  }
  .VD-top-number-arrow span {
    width: 20px;
    height: 20px;
  }
  .VD-logic {
    /* margin-right: 30px; */
  }

  .VD-story {
    flex-wrap: wrap;
  }
  .VD-story-main {
    margin-top: 12px;
  }
  .VD-story-add {
    width: 100%;
    margin-top: 10px;
  }
}
.VD-top-number-arrow {
  display: flex;
}

/* CODETASKER */
.video-list-box-close {
  display: flex;
  padding: 5px;
  color: #883dcf;
  /* border: 1px solid #883dcf; */
  border-radius: 4px;
  margin-right: 10px;
  align-items: center;
  background: #f4ecfb;
}
.New-video-flx {
  display: flex;
  padding: 15px 0;
  font-size: 14px;
}
.video-list-box-close button {
  border: 0;
  background: transparent;
}

/* loader start  */

/* .profile-main-loader{
  left: 50% !important;
  margin-left:-100px;
  position: fixed !important;
  top: 50% !important;
  margin-top: -100px;
  width: 45px;
  z-index: 9000 !important;
} */

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 100px;
  height: 30px;
  display: flex;
}
.profile-main-loader .loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 400px;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  margin: auto;
  z-index: 10000;
}

.circular-loader-1 {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  z-index: 10000;
}

.loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #7441f6;
  }
  40% {
    stroke: #7441f6;
  }
  66% {
    stroke: #7441f6;
  }
  80%,
  90% {
    stroke: #7441f6;
  }
}
@keyframes color {
  0% {
    stroke: #7441f6;
  }
  40% {
    stroke: #7441f6;
  }
  66% {
    stroke: #7441f6;
  }
  80%,
  90% {
    stroke: #7441f6;
  }
}

/* loader ends  */
/* .select__menu {
  width: 500px;
  z-index: 9999999999 !important;
}
*/
.select__control {
  border: 0 !important;
  padding: 0;
  min-width: 210px !important;
}
.VD-story-add .Polaris-TextField__Input {
  padding: 0;
}
button {
  cursor: pointer;
}
.Polaris-Button-text {
  font-weight: 600;
  font-size: 14px !important;
}
.croper-upload {
  /* padding: 10px 0 10px 0; */
  margin: 0px 0px 40px;
}

.ReactCrop .ReactCrop--fixed-aspect {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Croper-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video_editor {
  text-align: center;
}
[role="tabpanel"] .Polaris-Grid {
  height: 68vh;
}
.noimagefound {
  padding: 100px 0;
}
.card-preview {
  border-radius: 4px;
  width: 50px;
}
.vide-not-found {
  text-align: center;
  padding: 100px 0;
}
.Polaris-DropZone__Container .profile-main-loader {
  width: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  display: flex;
  align-items: center;
}
.VD-error {
  color: red;
  font-size: 14px;
}
.video_editor {
  position: relative;
}
.VD-vid_load_main {
  position: absolute;
  top: 50%;
  left: 50%;
}
.VD-vid_load_main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.VD-vid_load_main {
  font-size: 14px !important;
}
.VD-vid_load_main .circular-loader circle {
  stroke: #fff !important;
}
.VD-polaris-discount p {
  color: #fff;
  background: #ff0000;
  padding: 0 7px;
  font-weight: 600;
  border-radius: 4px;
  margin: 0;
  letter-spacing: 0;
  font-size: 12px;
}
.VD-polaris-discount {
  display: inline-block;
  margin-top: 6px;
}
.select-date {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  color: #883dcf;
}

.analytic-main-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.show-date {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
}

.no-data {
  padding: 20px;
  text-align: center;
}

.analytic-main {
  margin-top: var(--p-space-18);
  position: relative;
}

.analytic-main .daterange-picker {
  position: absolute;
  right: 0px;
  top: 50px;
  z-index: 1;
}

.highcharts-title {
  display: none;
}

.customise-icon {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding: 5px;
  margin-left: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.counter {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 18px;
  height: 18px;
  background-color: #ff0000;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container {
  position: relative;
  display: inline-block;
}

.video-container svg {
  width: 20px;
  height: 20px;
}

.mute-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mute-button i {
  color: #fff;
}

.mute-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.loader-check {
  height: 16px;
  width: 16px;
}

#range-slider-blue .range-slider__range {
  background: rgb(136, 61, 207);
  transition: height 0.3s;
}
#range-slider-blue .range-slider__thumb {
  background: #9db6ff;
  transition: height 0.3s;
}

.action-header {
  width: 200px !important;
}

.video-title {
  display: flex;
  align-items: center;
}

.video-title .title {
  margin-right: 10px;
}

.video-title .badge {
  background: #eae4f8;
  padding: 5px;
  text-transform: lowercase;
  font-size: 12px;
  border-radius: 10px;
  color: #7441f6;
}

.highcharts-container {
  border-radius: 12px;
}

.highcharts-yaxis {
  text {
    display: none;
  }
}
.highcharts-credits {
  display: none;
}

.highcharts-label-box path .highcharts-tooltip-box {
  fill: #1d1f2c;
}

.section-padding {
  margin-top: 60px;
  margin-bottom: 36px;
}

.tab-list {
  background-color: #ffffff;
  width: fit-content;
  border: 1px solid #e0e2e7;
  padding: 4px;
  border-radius: 8px;
}
.tab-list-button {
  background: transparent;
  border: 0;
  color: #667085;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  font-weight: 500;
  padding: 6px 12px;
}
.tab-list-button[aria-selected="true"] {
  background: #f4ecfb;
  padding: 6px 12px;
  color: #883dcf;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
}

.tab-list-button:focus {
  outline: 0;
}
.acrodian-title {
  color: #1d1f2c;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.0001rem;
}
.acrodian-box {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
}
.acrodian-header {
  display: flex;
  padding: 24px 24px;
  justify-content: space-between;
}
.chevron {
  transition: transform 0.3s ease;
}

.chevron.rotated {
  transform: rotate(180deg);
}

.search-bar {
  width: 100%;
}
.story-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.billing-card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
}
.billing-title {
  color: #444;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.08px;
}
.billing-value {
  color: #1d1f2c;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.08px;
}
.upgrade-title {
  color: #0f1016;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 0;
}
.upgrade-description {
  color: #858d9d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin: 0;
}
.cancel-btn {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #f4ecfb;
  border: none;
  color: #883dcf;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
}
.plan-container {
  margin-top: 40px;
  border-radius: 26px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(17.5px);
}

.success-btn {
  background: #883dcf;
  border: none;
  border-radius: 8px;
  padding: 10px 14px;
  color: #fff;
}
.page-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.view-more {
  color: #883dcf;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.005rem;
}
.billing-header {
  margin-top: 36px;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.005rem;
  text-align: center;
  color: #883dcf;
}
.story-circle-header {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #1d1f2c;
}
.story-text {
  border-bottom: 0.0625rem solid #eaeaea;
  padding-bottom: 12px;
}
.checkbox-input {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.border-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #777980;
  /* margin-top: 12px; */
}
.left-card {
  box-shadow: 0px 4px 30px 0px #2e2d740d;
  background-color: #fff;
  border-radius: 12px;
}
.right-card {
  box-shadow: 0px 4px 30px 0px #2e2d740d;
  background-color: #fff;
  border-radius: 12px;
}
.color-selector-container {
  display: flex;
  border: 1px solid #e0e2e7;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #f9f9fc;
}
.input-text {
  border: none;
  background-color: #f9f9fc;
  color: #667085;
}
.right-card-title {
  color: #1d1f2c;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 16px 0 0 30px;
}
.range-input {
  width: 100%;
  cursor: pointer;
}
.device-btn {
  display: flex;
  border-radius: 8px;
  border: 1px solid #e0e2e7;
  background: #fff;
  width: fit-content;
  padding: 4px;
}
.desktop-btn {
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  background: none;
}
.mobile-btn-active {
  padding: 6px 12px;
  border-radius: 8px;
  background: #f4ecfb;
  border: none;
  /* background: none; */
}
.dnd-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #858d9d;
}
.dnd-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
}
.dnd-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  margin-bottom: 10px;
  text-align: left;
  color: #777980;
}
.add-btn {
  border-radius: 8px;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}
.add-btn-div .juBESy:focus-within {
  outline: none;
}

.add-btn-text {
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  background: #883dcf;
  padding: 10px 14px;
  margin-right: 18px;
  svg {
    height: 16px;
  }
}
.add-btn-div {
  display: flex;
  justify-content: flex-end;
}
.Polaris-Text--root .Polaris-Box {
  background: none;
  box-shadow: none;
  border-radius: 0;
}
.stepper-box {
  margin: 5px 0 10px 0;
  /* border-top: 0.5px solid #858d9d; */
  border-bottom: 0.3px solid #858d9d;
}

#RFS-StepperContainer {
  padding: 10px 20px;
}

#RFS-StepButton {
  width: 26px;
  height: 26px;
}

#RFS-Label {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
}
/* .video-box {
  display: flex;
}
.right-video {
  width: 50%;
}
.left-video {
  width: 50%;
} */
.cancel-btn {
  border-radius: 8px;
  background: #f4ecfb;
  padding: 10px 14px;
  border: none;
}
.modal-text-btn {
  cursor: pointer;
  color: #883dcf;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.06px;
  border: none;
  background: none;
}
.modal-text-btn-disable {
  color: #777980;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.06px;
  border: none;
  background: none;
}
.upload-container {
  padding: 100px 12px;
  border-radius: 8px;
  border: 1px dashed #e0e2e7;
  background: #f9f9fc;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.completed + div {
  color: rgb(136, 61, 207);
}

/* HTML: <div class="loader"></div> */
.csloader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0)
      50%/8% 100%,
    linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0)
      50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.csloader::before,
.csloader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.csloader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes l23 {
  100% {
    transform: rotate(1turn);
  }
}

.sc-aXZVg {
  border: none !important;
  outline: none !important;
}
.pages-save {
  display: flex;
  justify-content: flex-end;
}
.pages-save-btn {
  background-color: #883dcf;
  border: none;
  color: #ffffff;
  border-radius: 8px;
  padding: 8px;
}
.add-container {
  border-radius: 12px;
  background: #fff;
  padding: 24px;
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
}
.dotted-section {
  padding: 24px;
  border-radius: 8px;
  border: 1px dashed #e0e2e7;
  background: #f9f9fc;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.details-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  border-radius: 8px;
  background: #f4ecfb;
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #883dcf;
}
.details-btn span {
  /* color: #fff; */
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
}
.video-tab-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 48px;
}
.card-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #eaeaef;
  box-shadow: 0px 1px 4px 0px rgba(26, 26, 67, 0.1);
  margin-top: 40px;
}
.video-img {
  height: 250px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  position: relative;
}
.video-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
}
.video-tag-saved {
  padding: 4px 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #d9d8ff;
  background: #f0f0ff;
  color: #883dcf;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  width: fit-content;
}
.video-tag-draft {
  padding: 4px 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #f4c3a0;
  background: #fae1cf;
  color: #e46a11;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  width: fit-content;
}
.video-options {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.video-title {
  color: #4a4a6a;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* display: -webkit-box; */
  /* -webkit-box-orient: vertical; */
  /* -webkit-line-clamp: 1; */
  /* overflow: "hidden"; */
  /* max-width: 100px; */
  /* width: 100%; */
  /* width: 50px; */
}
.video-description {
  color: #8e8ea9;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.transitionEffect {
  animation: fadeIn 0.4s ease-in-out forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.video-media .profile-main-loader .loader .circular-loader {
  height: 100vh !important;
  left: -50% !important;
  top: -100px !important;
}
.no-data {
  position: absolute;
  top: 40%;
  left: 0;
  margin: 0 auto;
  width: 100%;
}
.ant-upload.ant-upload-select {
  width: 100%;
}
.ant-upload-wrapper {
  width: 100%;
}
.no-analytics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100vh;
}
.dashboard-btn {
  color: #883dcf;
  font-weight: 600;
}
.dashboard-btn:hover {
  text-decoration: underline;
}
.Video-list .Polaris-Text--root .Polaris-Box {
  padding: 0 !important;
}

.Video-list
  .Polaris-IndexTable-ScrollContainer
  .Polaris-IndexTable__Table
  .Polaris-IndexTable__TableHeading {
  background: #ffffff;
  color: #1d1f2c;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

h2,
h3 {
  color: #555;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}

p {
  color: #666;
  font-size: 1em;
  font-weight: normal;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

ul li {
  /* margin: 10px 0; */
  font-size: 1em;
  font-weight: normal;
}

footer {
  text-align: center;
  padding: 10px 0;
  background: #f4f4f4;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 0.875em;
  font-weight: normal;
  margin-top: 30px;
}

.billing-btn,
.billing-popular-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.spinner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #fff 94%, #0000) top/3px 3px no-repeat,
    conic-gradient(#0000 30%, #fff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: spinner-c7wet2 1.2s infinite linear;
}
.billing-popular-btn .spinner {
  background:
    radial-gradient(farthest-side, #883dcf 94%, #0000) top/3.8px 3.8px no-repeat,
    conic-gradient(#0000 30%, #883dcf);
}
@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
